import { useSelector, useDispatch } from 'react-redux'
import { actionCreators } from '../../../../state';
import { bindActionCreators } from 'redux';
import eye from '../../../../assets/eye.png';
import lucide_eye from '../../../../assets/lucide_eye.png';
import edit_icon from '../../../../assets/edit_icon.png';
import filled_edit_icon from '../../../../assets/filled_edit_icon.png';
import Skeleton from 'react-loading-skeleton';
import useToggle from '../../../../hooks/useToggle';
import Confirmation from '../../../ConfirmationModal/Confirmation.js';
import { useState } from 'react';

export default function CategoriesList({items, selectCategory, selected, isParent, setEditing, editing, isfee}) {
  const categories = useSelector(state => isParent ? 
    state.categories:state.subcategories);
  const skeletons = [1, 2, 3, 4];
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const isSubcategoriesFee = useSelector(state => state.subcategories.isfee);
  const [visibilityCandidate, setVisibilityCandidate] = useState(null);

  const [showVisibilityConfirmation, toggleShowVisibilityConfirmation] = useToggle(false);

  const { toggleCategoryVisibility } = bindActionCreators(actionCreators, dispatch);

  const onEdit = (e, c) => {
    if (e.target.src===filled_edit_icon) {
      setEditing(null);
      return;
    }
    setEditing(c);
  }

  const onEyeClick = (c) => {
    setVisibilityCandidate(c);
    toggleShowVisibilityConfirmation();
  }

  const onVisibilityConfirm = () => {
    toggleCategoryVisibility(user, visibilityCandidate.id);
    setVisibilityCandidate(null);
    toggleShowVisibilityConfirmation();
  }

  return (
      <div className='categories-list'>
        <Confirmation 
          content={visibilityCandidate?.is_visible ? "ნამდვილად გსურთ კატეგორიის დამალვა?" : "ნამდვილად გსურთ კატეგორიის გამოჩენა?"}
          showConfirm={showVisibilityConfirmation}
          onConfirm={onVisibilityConfirm}
          toggleShowConfirm={toggleShowVisibilityConfirmation}
        />
        {categories.isLoading && 
        skeletons.map((i) => <Skeleton className='category-skeleton' height={50} key={i}/>)}
        {items.map((c, i) => 
        <div key={i} className='category-list-item'>
          <div className='category-list-item-left'>
            <div onClick={() => selectCategory(c.id)} 
            className={`${c.id === selected && 'check-selected'} checkbox`}>{c.id===selected && <p>✓</p>}</div>
            {(isParent || !isSubcategoriesFee) && <div className='constructor-icon'>
              <img src={c.icon} alt='icon' className='constructor-list-icon' />
            </div>}
            <p>{c.name.ge}</p>
          </div>
          <div className='category-list-item-right'>
            {(!isfee && !isSubcategoriesFee) && <img alt='edit' src={editing?.id===c.id ? filled_edit_icon:edit_icon} className="service-edit-icon" onClick={(e) => onEdit(e, c)}/>}
            {(!isfee && !isSubcategoriesFee) && <img src={!c.is_visible ? eye:lucide_eye} alt='eye' className='service-visibility-icon'
            onClick={() => onEyeClick(c)}/>}
          </div>
        </div>)}
      </div>
    );
}