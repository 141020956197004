import './RatingsCard.css'
import filled from '../../assets/bi_star-fill.svg'
import empty from '../../assets/bi_star.svg'
import arrow from '../../assets/arrow_back.svg'
import white_filled from '../../assets/white_star.svg'
import white_empty from '../../assets/white_star_empty.svg'
import { getSubRatings} from '../../state/action-creators/ratingsActions'
import { useDispatch,useSelector } from 'react-redux';

const RatingsCard = ({id, name,setName,isFirstCard, isParentPage, setIsParentPage, five,
   four, three, two, one, zero,getRatings}) => {
    const arr=[5,4,3,2,1,0];
    const amountMap={5:five, 4:four, 3:three, 2:two, 1:one, 0:zero}
    const dispatch=useDispatch();
    const user=useSelector(state=>state.user.user);
    // const ratings=useSelector(state=>state.ratings.ratings.statistics);
    
    const subratings=()=>{
      if(isParentPage && !isFirstCard){
        dispatch(getSubRatings(user, id))
        setIsParentPage(false)
        setName(name)
      }
      
    }
    const goBack=()=>{
      setName("მოთხოვნების ჯამური მაჩვენებელი")
      dispatch(getRatings(user))
      setIsParentPage(true)
      
    }
    return ( 
        <div className={isFirstCard? "ratings_card sumCard": isParentPage? "ratings_card clickable": "ratings_card"} onClick={subratings}>
            <div className="rating-card-head">
                {
                 (!isParentPage && isFirstCard)&& ( <img src={arrow} className="right-arrow" onClick={goBack} />)
                
                }
               <p className="_card_name">{name}</p> 
            </div>
            <div className="lower-ratings-container">
               <div className="lil-header">
               <div className="rating_">
                    შეფასება
                </div>
                <div className="_amount">
                    რაოდენობა
                </div>
               </div>
            {
             arr.map((a, i)=> <div key={i} className="rating-line">
             {
               new Array(a).fill(0).map((b, i)=>
                     <img key={i} src={isFirstCard?white_filled: filled} alt="" />
               )
              }
              {
                new Array(5-a).fill(0).map((b, i)=>
                <img key={i} src={isFirstCard?white_empty: empty} alt=""/>
                )
              }
              <div className="amount">
                <p>- &nbsp;&nbsp;&nbsp;{amountMap[a]}</p> 
              </div>
              </div>)
         }
            </div>
        </div>
     );
}
 
export default RatingsCard;