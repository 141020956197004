import React, { useState } from "react";
import Dropdown from "../../../Dropdown/Dropdown";
import CountryDropdown from "../../../CountryDropdown/CountryDropdown";
import Input from "../../../InputField/InputField";
import { legalStatuses } from "../../../../constants";
import { DateInput } from "../../services-tables/filters/LeasesFilter";
import { countrys, callingCodes } from "../../../../constants";
import CheckBox from "../../../Checkbox/Checkbox";
import useToggle from "../../../../hooks/useToggle";
import VerificationModal from "../../../VerificationModal/VerificationModal";
import API from "../../../../libs/API";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from "../../../../state/action-creators";

const indexes = Object.keys(countrys).map((e) => ({
  name: countrys[e],
  id: e,
}));

export default function RentalForm({ apartment, setCard }) {
  const [legalStatus, setLegalStatus] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [communalFee, toggleCommunalFee] = useToggle();
  const [apartmentFee, toggleApartmentFee] = useToggle();
  const [showVerification, toggleShowVerification] = useToggle();

  const [verificationCode, setVerificationCode] = useState("");

  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const isReadyForVerify =
    (phone && selectedIndex && fromDate && toDate) ||
    (code && fromDate && toDate);

  const onVerifyTextClick = () => {
    if (!isReadyForVerify) {
      return;
    }
    let body = {
      user: {
        country_code: selectedIndex.id,
        country_phone_prefix: callingCodes[selectedIndex?.id],
        phone: phone,
      },
      from_date: fromDate,
      to_date: toDate,
      generate_apartment_fee_invoice: apartmentFee,
      generate_communal_fee_invoice: communalFee,
    };

    if (legalStatus.name === "იურიდიული პირი") {
      delete body.user;
      body.organization = {
        identity_num: code,
      };
    }

    API.post(
      user,
      `/apartment/${apartment.id}/rental`,
      body,
      (res) => {
        toggleShowVerification(true);
      },
      (err) => {
        const message = err.response.data?.message.ge;
        dispatch(setMessage(message ?? err.message));
      }
    );
  };

  const onSave = () => {
    let body = {
      verification_code: verificationCode,
      user: {
        country_code: selectedIndex.id,
        country_phone_prefix: callingCodes[selectedIndex?.id],
        phone: phone,
      },
      from_date: fromDate + " 00:00:00",
      to_date: toDate + " 00:00:00",
      generate_apartment_fee_invoice: apartmentFee,
      generate_communal_fee_invoice: communalFee,
    };

    if (legalStatus.name === "იურიდიული პირი") {
      delete body.user;
      body.organization = {
        identity_num: code,
      };
    }

    API.put(
      user,
      `/apartment/${apartment.id}/rental`,
      body,
      (res) => {
        toggleShowVerification(false);
        //toggleShowLeaseForm(false);
        dispatch(setMessage("ფართი  გაქირავდა"));
        setCard(res.ownership_card);
      },
      (err) => {
        const message = err.response.data?.message.ge;
        toggleShowVerification(false);
        dispatch(setMessage(message ?? err.message));
      }
    );
  };

  return (
    <div className="rental-form-container">
      <VerificationModal
        isOpen={showVerification}
        toggleIsOpen={toggleShowVerification}
        value={verificationCode}
        onChange={(e) => setVerificationCode(e)}
        onSave={onSave}
      />
      <label>ფართის დამქირავებელი</label>
      <div className="rental-form">
        <Dropdown
          title={"იურიდიული სტატუსი"}
          data={legalStatuses}
          selected={legalStatus}
          setSelected={setLegalStatus}
        />
        {legalStatus?.name === "ფიზიკური პირი" && (
          <div className="rental-form-row">
            <CountryDropdown
              placeholder={"ტელეფონის ქვეყნის კოდი"}
              data={indexes}
              selected={selectedIndex}
              setSelected={setSelectedIndex}
            />
            <Input
              placeholder={"ტელეფონის ნომერი"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type={"text"}
              name={"phone"}
            />
          </div>
        )}
        {legalStatus?.name === "იურიდიული პირი" && (
          <Input
            placeholder={"ორგანიზაციის საიდენტიფიკაციო კოდი"}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            name={"code"}
            type={"text"}
          />
        )}
      </div>
      <label>ქირავნობის პერიოდი</label>
      <div className="rental-form">
        <div className="rental-form-row">
          <DateInput
            notPast={true}
            placeholder={"თარიღიდან"}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            name={"fromDate"}
          />
          <DateInput
            notPast={true}
            placeholder={"თარიღამდე"}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            name={"toDate"}
          />
        </div>
        <div className="rental-form-row">
          <div>
            <CheckBox
              label={"ფართის მომსახურების გადასახადი"}
              isChecked={apartmentFee}
              onClick={toggleApartmentFee}
            />
          </div>
          <div>
            <CheckBox
              label={"კომუნალური გადასახადი"}
              isChecked={communalFee}
              onClick={toggleCommunalFee}
            />
          </div>
        </div>
        <div
          className={`put-button ${!isReadyForVerify ? "button-disabled" : ""}`}
          style={{ margin: "auto", marginBottom: "50px", marginTop: "30px" }}
          onClick={onVerifyTextClick}
        >
          ჩასმა
        </div>
      </div>
    </div>
  );
}
