import React from 'react';
import star from '../../../../assets/bi_star.png';
import star_filled from '../../../../assets/bi_star-fill.png';

export default function RateStars({ rate }) {
  const temp = [1, 2, 3, 4, 5];
  return (
    <div className='rate-stars'>
        {temp.map((n) => 
            <img key={n} src={n > rate ? star:star_filled} alt='star' className='star'/>
        )}
    </div>
  )
}
