import React from "react";
import { dateToString, localDateString } from "../../../../libs/Functions";

export default function NotificationData({ self }) {
  const { id, sending_datetime, planned_sending_datetime } = self;
  return (
    <div>
      <div className="card-main-category">
        <p>გზავნილის ნომერი: {id}</p>
      </div>
      <div className="card-main-category">
        <p>
          გზავნილის თარიღი:{" "}
          {localDateString(
            self.status.en === "delayed"
              ? planned_sending_datetime
              : sending_datetime
          )}
        </p>
      </div>
    </div>
  );
}
