import React, { useRef } from "react";
import useToggle from "../../../../hooks/useToggle";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import arrow from "../../../../assets/arrow.png";
import "./filter.css";

export default function Dropdown(props) {
  const { title, data, selected, setSelected, isError, fontSize, width } =
    props;
  const [showList, toggleShowList] = useToggle(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => toggleShowList(false));

  const onSelect = (e) => {
    setSelected(e);
    toggleShowList();
  };

  const onReset = () => {
    setSelected(null);
    toggleShowList();
  };

  const onSelectorClick = () => {
    if (!data) {
      return;
    }
    toggleShowList();
  };

  const style = {
    border: isError && "1px solid var(--errorColor)",
    width: width ? width : "auto",
  };

  return (
    <div className="filter-dropdown-container" ref={wrapperRef}>
      <div
        className="filter-dropdown-selector"
        style={style}
        onClick={onSelectorClick}
      >
        <p
          className={selected ? "" : "dropdown-notselected"}
          style={fontSize && { fontSize: `${fontSize}px` }}
        >
          {!selected ? title : selected.name}
        </p>
        {selected ? (
          <div className="filter-x" onClick={onReset}>
            ✕
          </div>
        ) : (
          <img src={arrow} alt="arrow" className="filter-arrow" />
        )}
      </div>
      {showList && (
        <div className="filter-dropdown-list">
          {data.map((e, i) => (
            <div
              key={e.id}
              className="filter-dropdown-element"
              onClick={() => onSelect(e)}
            >
              {e.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
