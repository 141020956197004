import React, { useEffect } from "react";
import search from "../../../../assets/search.png";
import filled_search from "../../../../assets/filled_search.png";
import { useNavigate } from "react-router-dom";
import Dropdown from "../filters/FilterDropdown";
import { getCategories } from "../../../../state/action-creators";
import { useDispatch, useSelector } from "react-redux";
import Unapproved from "../../../Icons/Unapproved";
import Approved from "../../../Icons/Approved";
import Active from "../../../Icons/Active";
import Rejected from "../../../Icons/Rejected";
import Star from "../../../Icons/Star";
import Done from "../../../Icons/Done";
import Processing from "../../../Icons/Processing";

const baseUrl = "/dashboard/services/requests";
const orange = "#DAA161";
const black = "#333333";

export default function ControlbarForRequests(props) {
  const {
    showFilter,
    toggleShowFilter,
    selectedTable,
    selectedCategory,
    setSelectedCategory,
    tab,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const categories = useSelector((state) =>
    state.categories.items.map((c) => ({ name: c.name.ge, id: c.id }))
  );

  //Must be one of: incomplete, pending_approval, pending_payment, rejected, canceled, unpaid, active, processing, done, closed

  const onActivesClick = () => navigate(baseUrl + "/active");
  const onProcessClick = () => navigate(baseUrl + "/processing");
  const onCompletedsClick = () => navigate(baseUrl + "/done,closed");
  const onRatesClick = () => navigate(baseUrl + "/rates");
  const onPendingApprovalClick = () => navigate(baseUrl + "/pending_approval");
  const onApprovedClick = () => navigate(baseUrl + "/pending_payment");
  const onRejectedClick = () => navigate(baseUrl + "/rejected");

  useEffect(() => {
    dispatch(getCategories(user));
  }, []);

  return (
    <>
      <div className="controlbar-left">
        {selectedTable !== -1 && selectedTable !== 3 && tab !== "rates" && (
          <img
            src={showFilter ? filled_search : search}
            alt="search"
            onClick={toggleShowFilter}
          ></img>
        )}
        {tab !== "rates" && (
          <div className="controlbar-category-filter">
            <Dropdown
              title="სერვისის კატეგორია"
              width={200}
              data={categories}
              selected={selectedCategory}
              setSelected={setSelectedCategory}
            />
          </div>
        )}
      </div>
      <div className="controlbar-right">
        <div
          className={
            tab === "pending_approval"
              ? "selector selector-selected"
              : "selector"
          }
          onClick={onPendingApprovalClick}
        >
          <Unapproved fill={tab === "pending_approval" ? orange : black} />
          <p>დასადასტურებელი</p>
        </div>

        <div
          className={
            tab === "pending_payment" || tab === "unpaid"
              ? "selector selector-selected"
              : "selector"
          }
          onClick={onApprovedClick}
        >
          <Approved fill={tab === "pending_payment" ? orange : black} />
          <p>დადასტურებული</p>
        </div>

        <div
          className={
            tab === "rejected" ? "selector selector-selected" : "selector"
          }
          onClick={onRejectedClick}
        >
          <Rejected fill={tab === "rejected" ? orange : black} />
          <p>უარყოფილი</p>
        </div>

        <div
          className={
            tab === "active" ? "selector selector-selected" : "selector"
          }
          onClick={onActivesClick}
        >
          <Active fill={tab === "active" ? orange : black} />
          <p>აქტიური</p>
        </div>
        <div
          className={
            tab === "processing" ? "selector selector-selected" : "selector"
          }
          onClick={onProcessClick}
        >
          <Processing fill={tab === "processing" ? orange : black} />
          <p>პროცესში</p>
        </div>
        <div
          className={
            tab === "done,closed" ? "selector selector-selected" : "selector"
          }
          onClick={onCompletedsClick}
        >
          <Done fill={tab === "done,closed" ? orange : black} />
          <p>შესრულებული</p>
        </div>
        <div
          className={
            tab === "rates" ? "selector selector-selected" : "selector"
          }
          onClick={onRatesClick}
        >
          <Star fill={tab === "rates" ? orange : black} />
          <p>შეფასებები</p>
        </div>
      </div>
    </>
  );
}
