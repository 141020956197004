import React from "react";

export default function CancelledIcon({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4999 10L17.4999 14M17.4999 10L13.4999 14M19.5949 18.5H9.2979C8.7763 18.4999 8.27536 18.2961 7.9019 17.932L2.5519 12.716C2.45635 12.6228 2.38041 12.5115 2.32856 12.3885C2.27671 12.2656 2.25 12.1335 2.25 12C2.25 11.8665 2.27671 11.7344 2.32856 11.6115C2.38041 11.4885 2.45635 11.3772 2.5519 11.284L7.9019 6.068C8.27536 5.70387 8.7763 5.50005 9.2979 5.5H19.5949C20.5449 5.5 21.8179 6.041 21.8179 7.125V16.875C21.8179 17.959 20.5449 18.5 19.5949 18.5Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
