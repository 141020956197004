import { ADD_EXECUTOR, GET_EXECUTORS, REMOVE_EXECUTOR } from "../action-creators/types";

const initialState = {
    items: [],
    isLoading: false,
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_EXECUTORS:
            return {...state, items: action.payload, isLoading: false}
        case ADD_EXECUTOR:
            return {
                ...state,
                items: [...state.items, action.payload]
            }
        case REMOVE_EXECUTOR:
            return {
                ...state,
                items: state.items.filter((i) => i.id !== action.payload)
            }
        default:
            return state;
    }
}

export default reducer;