import React from "react";
import Checkbox from "../../../../Checkbox/Checkbox";

export default function Subcategory({
  self,
  selectedSubcategory,
  waivedFeesQuery,
  onClick,
}) {
  const handleClick = (e) => {
    e.preventDefault();
    if (selectedSubcategory !== null) {
      onClick(null);
      return;
    }
    onClick(self.id);
  };

  const ids = waivedFeesQuery?.data?.waived_fees?.map(
    (fee) => fee.fee_category_id
  );

  return (
    <div
      className={`checkbox-row subcategory-row ${
        selectedSubcategory === self.id ? "checkbox-row-selected" : ""
      }`}
      onClick={handleClick}
    >
      <Checkbox
        isChecked={ids ? ids.includes(self.id) : false}
        onClick={() => {}}
        style={{ marginBottom: "8px" }}
      />
      <p style={{ marginTop: "0px" }}>{self.name.ge}</p>
    </div>
  );
}
