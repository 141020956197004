import React, { useEffect, useRef, useState } from "react";
import "./CountryDropdown.css";
import useToggle from "../../hooks/useToggle";
import useOutsideClick from "../../hooks/useOutsideClick";
import arrow from "../../assets/arrow.png";
import PropTypes from "prop-types";

function CountryDropdown({ placeholder, data, selected, setSelected }) {
  const [showList, toggleShowList] = useToggle();
  const [items, setItems] = useState(data);
  const [focused, setFocused] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [cordinate, setCordinate] = useState(44);
  const [top, setTop] = useState(0);
  const wrapperRef = useRef(null);
  const listRef = useRef(null);

  const handleKeydown = (e) => {
    if (e.key === "ArrowDown") {
      setFocusedIndex((prevIndex) => {
        if (prevIndex + 1 > 4) {
          setTop((prevTop) => {
            const bottom = prevTop + 4;
            if (prevIndex === bottom) {
              setCordinate((prevCordinate) => {
                listRef.current.scrollTo(0, prevCordinate);
                return prevCordinate + 44;
              });
              return prevTop + 1;
            }
            return prevTop;
          });
        }
        // console.log("items length: " + items.length);
        // console.log("prevIndex: " + prevIndex);
        return prevIndex < items.length - 1 ? prevIndex + 1 : prevIndex;
      });
    }
    if (e.key === "ArrowUp") {
      setFocusedIndex((prevIndex) => {
        setTop((prevTop) => {
          if (prevIndex === prevTop + 1) {
            setCordinate((prevCordinate) => {
              listRef.current.scrollTo(0, prevCordinate - 44);
              return prevCordinate - 44;
            });
            return prevTop === 0 ? 0 : prevTop - 1;
          }
          return prevTop;
        });
        return prevIndex === 0 ? prevIndex : prevIndex - 1;
      });
    }

    if (e.key === "Enter") {
      onSelect(items[focusedIndex]);
    }
  };

  const onOutside = () => {
    toggleShowList(false);
    setItems(data);
    setTop(0);
    setFocusedIndex(-1);
    setFocused(null);
    setCordinate(44);
  };

  useOutsideClick(wrapperRef, onOutside);

  const onSelect = (e) => {
    setSelected(e);
    toggleShowList(false);
    setFocusedIndex(-1);
  };

  const onClick = () => {
    toggleShowList();
    setFocusedIndex(-1);
  };

  const onChange = (e) => {
    const value = e.target.value;

    if (value.lenght === 0) {
      setItems(data);
      return;
    }
    setFocusedIndex(-1);
    setFocused(null);
    setSelected({ id: "", name: value });
    setItems(
      data.filter((item) =>
        item.name.toUpperCase().includes(value.toUpperCase())
      )
    );
  };

  useEffect(() => {
    return () => {
      setSelected("");
    };
  }, []);

  return (
    <div className="country-dropdown" ref={wrapperRef}>
      <div className="country-input-container" onKeyDown={handleKeydown}>
        <input
          type="text"
          className="input"
          placeholder={placeholder}
          onClick={onClick}
          value={selected ? selected.name : ""}
          onChange={onChange}
        />
        <img src={arrow} alt="arrow" className="country-dropdown-arrow" />
      </div>
      {showList && (
        <div className="dropdown-list" ref={listRef} onKeyDown={handleKeydown}>
          {items.map((e, i) => (
            <div
              key={e.id}
              className={`dropdown-element ${
                focusedIndex === i && "dropdown-element-selected"
              }`}
              onClick={() => onSelect(e)}
            >
              {e.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

CountryDropdown.propTypes = {
  placeholder: PropTypes.string,
  data: PropTypes.array,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
};

export default CountryDropdown;
