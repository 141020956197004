// User
export const RESET_USER = "RESET_USER";
export const GET_USER = "GET_USER";
export const SET_LOADING = "SET_LOADING";
export const LOGIN_FINISH = "LOGIN_FINISH";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const SET_ERROR = "SET_ERROR";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const SET_LOGOUT_LOADING = "SET_LOGOUT_LOADING";

// Properties
export const GET_PROPERTIES = "GET_PROPERTIES";
export const ADD_PROPERTY = "ADD_PROPERTY";
export const EDIT_PROPERTY = "EDIT_PROPERTY";
export const ADD_OWNER = "ADD_OWNER";
export const ADD_COMPLEX = "ADD_COMPLEX";
export const ADD_PARKING = "ADD_PARKING";
export const PROPERTY_ERROR = "PROPERTY_ERROR";
export const ADD_ORGANIZATION = "ADD_ORGANIZATION";
export const GET_PROPERTY = "GET_PROPERTY";
export const SET_PROPERTY_LOADING = "SET_PROPERTY_LOADING";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const SAVE_PROPERTY = "SAVE_PROPERTY";
export const SET_OWNERSHIP_ATTRIBUTE_LOADING =
  "SET_OWNERSHIP_ATTRIBUTE_LOADING";
export const DELETE_COMPLEX = "DELETE_COMPLEX";
export const DELETE_OWNER = "DELETE_OWNER";
export const DELETE_PARKING = "DELETE_PARKING";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const RESET_CURRENT_PROPERTY = "RESET_CURRENT_PROPERTY";
export const SET_PROPERTIES_LOADING = "SET_PROPERTIES_LOADING";
export const SET_PROPERTIES_STATUS = "SET_PROPERTIES_STATUS";
export const UPDATE_PROPERTIES = "UPDATE_PROPERTIES";
export const UPDATE_OWNER = "UPDATE_OWNER";
export const UPDATE_COMPLEX = "UPDATE_COMPLEX";
export const UPDATE_PARKING = "UPDATE_PARKING";
export const SET_OWNERSHIP_TYPE = "SET_OWNERSHIP_TYPE";
export const SET_PARAMS = "SET_PARAMS";
export const CLEAR_PROPERTIES = "CLEAR_PROPERTIES";
export const SET_PROPERTY_ERROR = "SET_PROPERTY_ERROR";
export const SET_OWNER_ADDING_LOADING = "SET_OWNER_ADDING_LOADING";
export const SET_COMPLEX_ADDING_LOADING = "SET_COMPLEX_ADDING_LOADING";
export const SET_PARKING_ADDING_LOADING = "SET_PARKING_ADDING_LOADING";
export const TOGGLE_RESPONSIBLE_PERSON = "TOGGLE_RESPONSIBLE_PERSON";

// Popups
export const SET_POPUP_MESSAGE = "SET_POPUP_MESSAGE";
export const REMOVE_POPUP_MESSAGE = "REMOVE_POPUP_MESSAGE";

// Tutorials
export const GET_VIDEOS = "GET_VIDEOS";
export const ADD_VIDEO = "ADD_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const TUTORIAL_ERROR = "TUTORIAL_ERROR";
export const UPDATE_VIDEO = "UPDATE_VIDEO";

// Categories
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const SET_CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES";
export const SUBCATEGORIES_ERROR = "SUBCATEGORIES_ERROR";
export const SET_SUBCATEGORIES_LOADING = "SET_SUBCATEGORIES_LOADING";
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES";
export const CLEAR_SUBCATEGORIES = "CLEAR_SUBCATEGORIES";
export const TOGGLE_CATEGORY_VISIBILITY = "TOGGLE_CATEGORY_VISIBILITY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_SUBCATEGORY = "ADD_SUBCATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const SET_CATEGORY_UPDATE_LOADING = "SET_CATEGORY_UPDATE_LOADING";
export const SET_SUBCATEGORY_UPDATE_LOADING = "SET_SUBCATEGORY_UPDATE_LOADING";
export const SUBCATEGORIES_SERVICE_ERROR = "SUBCATEGORIES_SERVICE_ERROR";
export const SET_FEE_SAVING_LOADING = "SET_FEE_SAVING_LOADING";
export const SAVE_FEE = "SAVE_FEE";

// Requests
export const GET_REQUESTS = "GET_REQUESTS";
export const SET_REQUESTS_LOADING = "SET_REQUESTS_LOADING";
export const UPDATE_REQUESTS = "UPDATE_REQUESTS";
export const SET_REQUEST_PARAMS = "SET_REQUEST_PARAMS";
export const COMPLETE_REQUEST = "COMPLETE_REQUEST";
export const CLEAR_REQUESTS = "CLEAR_REQUESTS";
export const SET_ASSIGN_LOADING = "SET_ASSIGN_LOADING";
export const RESET_REQUEST_STATE = "RESET_REQUEST_STATE";
export const SET_INVOICE_REQUESTS_UPDATING = "SET_INVOICE_REQUESTS_UPDATING";
export const APPROVE_REQUEST = "APPROVE_REQUEST";
export const REJECT_REQUEST = "REJECT_REQUEST";

// Executors
export const ADD_EXECUTOR = "ADD_EXECUTOR";
export const REMOVE_EXECUTOR = "REMOVE_EXECUTOR";
export const GET_EXECUTORS = "GET_EXECUTORS";
export const ASSIGN_EXECUTOR = "ASSIGNE_EXECUTOR";

// Ratings statistics
export const GET_RATINGS = "GET_RATINGS";

// Invoices
export const GET_INVOICES = "GET INVOICES";
export const SET_INVOICES_LOADING = "SET_INVOICES_LOADING";
export const UPDATE_INVOICES = "UPDATE_INVOICES";
export const SET_INVOICES_PARAMS = "SET_INVOICES_PARAMS";
export const SET_INVOICES_ERROR = "SET_INVOICES_ERROR";
export const CLEAR_INVOICES = "CLEAR_INVOICES";
export const CLOSE_INVOICES = "CLOSE_INVOICES";
export const RESET_INVOICE_STATE = "RESET_INVOICE_STATE";
export const SET_INVOICES_UPDATING = "SET_INVOICES_UPDATING";
export const SAVE_INVOICE = "UPDATE_INVOICE";
export const APPROVE_INVOICES = "APPROVE_INVOICE";
export const SET_INVOICE_SAVING = "SET_INVOICE_SAVING";
export const CANCEL_INVOICE = "CANCEL_INVOICE";
export const TOGGLE_EVERY_CLOSING_CANDIDATE = "TOGGLE_EVERY_CLOSING_CANDIDATE";
export const TOGGLE_CLOSING_CANDIDATE = "TOGGLE_CLOSING_CANDIDATE";

// Addresates
export const GET_ADDRESATES = "GET_ADDRESATES";
export const SET_ADDRESATES_LOADING = "SET_ADDRESATES_LOADING";
export const SET_ADDRESATES_PARAMS = "SET_ADDRESATES_PARAMS";
export const SELECT_ALL_ADDRESATES = "SELECT_ALL_ADDRESATES";
export const TOGGLE_ADDRESATE = "TOGGLE_ADDRESATE";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const UNSELECT_ADDRESATES = "UNSELECT_ADDRESATES";

// Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_LOADING = "SET_NOTIFICATIONS_LOADING";
export const SET_NOTIFICATIONS_PARAMS = "SET_NOTIFICATIONS_PARAMS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const PUT_NOTIFICATION = "PUT_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const RESET_SEENS = "RESET_SEENS";
export const ATTACH_FILE = "ATTACH_FILE";
export const REMOVE_FILE = "REMOVE_FILE";
