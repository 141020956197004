import React, { useState } from "react";
import FilterInput from "../services-tables/filters/FilterInput";
import { DateInput } from "../services-tables/filters/LeasesFilter";
import { dateJSON } from "../../../libs/Functions";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  setParams,
} from "../../../state/action-creators/notificationActions.js";

function NotificationFilter({ tab }) {
  const [formData, setFormData] = useState({
    post_id: "",
    from_sending_datetime: "",
    to_sending_datetime: "",
  });

  const dispatch = useDispatch();
  const { params } = useSelector((state) => state.notifications);

  const { post_id, from_sending_datetime, to_sending_datetime } = formData;

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSearch = () => {
    const defaultFilterParams = {
      direction: tab === "received" ? "received" : "sent",
      status: tab === "delayed" ? "delayed" : "sent",
    };
    const sendData = {
      ...params,
      ...defaultFilterParams,
      post_id: Number(post_id),
      from_sending_datetime: dateJSON(from_sending_datetime),
      to_sending_datetime: dateJSON(to_sending_datetime),
    };
    setParams({ ...params, sendData });
    dispatch(getNotifications(sendData));
  };

  const resetFilter = () => {
    setFormData({
      post_id: "",
      from_sending_datetime: "",
      to_sending_datetime: "",
    });
  };

  return (
    <div className="filter-container">
      <div className="filter-row">
        <FilterInput
          placeholder={"გზავნილის ნომერი"}
          value={post_id}
          name="post_id"
          onChange={onChange}
        />
        <DateInput
          placeholder={"გზავნილის თარიღიდან"}
          name={"from_sending_datetime"}
          value={from_sending_datetime}
          onChange={onChange}
        />
        <DateInput
          placeholder={"გზავნილის თარიღამდე"}
          name={"to_sending_datetime"}
          value={to_sending_datetime}
          onChange={onChange}
        />
        <div className="filter-buttons" style={{ marginTop: "-15px" }}>
          <div className="filter-clear-button" onClick={resetFilter}>
            გასუფთავება
          </div>
          <div className="put-button" onClick={onSearch}>
            ძებნა
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationFilter;
