const countryCodes = require("country-codes-list");

export const servicesAttributes = [
  {
    name: {
      ge: "მოთხოვნები",
      en: "requests",
    },
    tabs: [{ en: "pending_approval", ge: "დასადასტურებელი" }],
  },
  {
    name: {
      ge: "მომხმარებლები",
      en: "users",
    },
    tabs: [{ en: "private", ge: "ფართის მეპატრონე" }],
  },
  {
    name: {
      ge: "კონსტრუქტორი",
      en: "constructor",
    },
    tabs: [{ en: "relief-fees", ge: "გადასახადიდან გათავისუფლება" }],
  },
];

export const paymentsAttributes = [
  {
    name: {
      en: "service",
      ge: "მომსახურების გადასახადის გადახდები",
    },
    tabs: [{ en: "unpaid", ge: "გადასახდელი" }],
  },
  {
    name: {
      en: "apartment",
      ge: "ფართის მომსახურების გადასახადის გადახდები",
    },
    tabs: [{ en: "unpaid", ge: "გადასახდელი" }],
  },
  {
    name: {
      en: "parking",
      ge: "პარკინგის მომსახურების გადასახადის გადახდები",
    },
    tabs: [{ en: "unpaid", ge: "გადასახდელი" }],
  },
  {
    name: {
      en: "communal",
      ge: "კომუნალური გადასახადის გადახდები",
    },
    tabs: [{ en: "unapproved", ge: "დასადასტურებელი" }],
  },
  {
    name: {
      en: "fees",
      ge: "სერვისით სარგებლობის გადახდები",
    },
    tabs: [{ en: "paid", ge: "გადახდილი" }],
  },
  {
    name: {
      en: "additional",
      ge: "დამატებითი მომსახურების გადასახადის  გადახდები",
    },
    tabs: [{ en: "paid", ge: "გადახდილი" }],
  },
];
export const legalStatuses = [
  { name: "იურიდიული პირი", id: 1 },
  { name: "ფიზიკური პირი", id: 2 },
];
export const indexes = ["GE (+995)", "US (+111)"];
export const countrys = countryCodes.customList(
  "countryCode",
  "{countryNameEn} +({countryCallingCode})"
);
export const countryNames = countryCodes.customList(
  "countryCode",
  "{countryNameEn}"
);
export const uses = [
  { name: "დიახ", id: true },
  { name: "არა", id: false },
];

export const callingCodes = countryCodes.customList(
  "countryCode",
  "+{countryCallingCode}"
);
export const apartmentTypes = ["საცხოვრებელი ბლოკი", "ბიზნეს ბლოკი"];
export const parkingTypes = [
  "სტაციონალური პარკინგი",
  "მექანიზირებული პარკინგი",
];
export const propertyTypes = [
  { name: "საკუთრება", id: 1 },
  { name: "იჯარა", id: 2 },
];
export const userTypes = [
  { name: "მეპატრონე", name_en: "private", id: 1 },
  { name: "დამქირავებელი", name_en: "lease", id: 1 },
  { name: "პასუხისმგებელი", name_en: "responsible", id: 1 },
];
