import React from "react";

export default function ReceivedIcon({ fill }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3337 10.8333H16.667V5.83333H9.65533L7.98866 4.16667H3.33366V15.8333H10.8337V17.5H2.50033C2.27931 17.5 2.06735 17.4122 1.91107 17.2559C1.75479 17.0996 1.66699 16.8877 1.66699 16.6667V3.33333C1.66699 3.11232 1.75479 2.90036 1.91107 2.74408C2.06735 2.5878 2.27931 2.5 2.50033 2.5H8.67866L10.3453 4.16667H17.5003C17.7213 4.16667 17.9333 4.25446 18.0896 4.41074C18.2459 4.56702 18.3337 4.77899 18.3337 5V10.8333ZM16.667 14.1667H19.167V15.8333H16.667V18.75L12.5003 15L16.667 11.25V14.1667Z"
        fill={fill}
      />
    </svg>
  );
}
