import React from 'react';
import phoneIcon from '../../../assets/phoneIcon.png';
import bin from '../../../assets/bin.png';
import edit_icon from '../../../assets/edit_icon.png';
import { dateToString } from '../../../libs/Functions';

export default function LeaseOrganizationList({
    property,
    organizationEdit,
    setDeletingRecord,
    isLease,
    userEditingRecord
}) {
  return (
    <>
    {property.owners?.organizations.map((organization, i) => 
    <div key={i} className='card-item owner-card-item'>
        {organization.representative.is_verified && <img src={phoneIcon} 
        onClick={(event)=>{event.stopPropagation(); dateToString(organization.representative.verification_date)}}  
        className="phoneIcon phone-in-edit" alt="phone icon" style={{left: '0', top: '5px'}}/>}

        <p className={userEditingRecord?.id===organization.id ? 'editing':''}>
            {`იურიდიული პირი | ${organization.name} | ${organization.identity_num} 
        | ${organization.representative.name+" "+organization.representative.surname} | ${organization.representative.pers_id} | 
            ${organization.representative.country.code} (${organization.representative.country.phone_prefix}) 
            ${organization.representative.phone}`}</p>
        <div className='edit-icons'>
            {!isLease && <img src={edit_icon} alt='edit' className='edit-icon' onClick={() => organizationEdit(organization)}/>}
            {!isLease && <img src={bin} alt='bin' className='bin' onClick={() => setDeletingRecord({name: "organization", obj: organization})}/>}
        </div>
    </div>)}
    </>
  )
}
