import React, { useState } from "react";
import { DateInput } from "../../services-tables/filters/LeasesFilter";
import useToggle from "../../../../hooks/useToggle";
import Checkbox from "../../../Checkbox/Checkbox";
import API from "../../../../libs/API";
import { useDispatch, useSelector } from "react-redux";
import { dateFormatted } from "../../../../libs/Functions";
import { setMessage } from "../../../../state/action-creators";

export default function RentalEditForm({
  editingRecord,
  setEditingRecord,
  setCard,
}) {
  const [fromDate, setFromDate] = useState(
    dateFormatted(editingRecord.from_date)
  );
  const [toDate, setToDate] = useState(dateFormatted(editingRecord.to_date));
  const [apartmentFee, toggleApartmentFee] = useToggle(
    editingRecord.generate_apartment_fee_invoice
  );
  const [communalFee, toggleCommunalFee] = useToggle(
    editingRecord.generate_communal_fee_invoice
  );

  const isReadyForVerify = fromDate && toDate;
  const apartment = editingRecord.apartments[0];
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (!isReadyForVerify) {
      return;
    }
    const data = {
      from_date: fromDate + " 00:00:00",
      to_date: toDate + " 00:00:00",
      generate_communal_fee_invoice: communalFee,
      generate_apartment_fee_invoice: apartmentFee,
    };
    API.put(user, `/apartment/${apartment.id}/rental/update`, data, (res) => {
      setEditingRecord(null);
      setCard(res.ownership_card);
      dispatch(setMessage("ცვლილება წარმატებით განხორციელდა"));
    });
  };

  return (
    <div className="rental-form-container" style={{ width: "90%" }}>
      <label>ქირავნობის პერიოდი</label>
      <div className="rental-form">
        <div className="rental-form-row">
          <DateInput
            notPast={true}
            placeholder={"თარიღიდან"}
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            name={"fromDate"}
          />
          <DateInput
            notPast={true}
            placeholder={"თარიღამდე"}
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            name={"toDate"}
          />
        </div>
        <div className="rental-form-row">
          <div>
            <Checkbox
              label={"ფართის მომსახურების გადასახადი"}
              isChecked={apartmentFee}
              onClick={toggleApartmentFee}
            />
          </div>
          <div>
            <Checkbox
              label={"კომუნალური გადასახადი"}
              isChecked={communalFee}
              onClick={toggleCommunalFee}
            />
          </div>
        </div>
        <div
          className={`put-button ${!isReadyForVerify ? "button-disabled" : ""}`}
          style={{ margin: "auto", marginBottom: "50px", marginTop: "30px" }}
          onClick={onSubmit}
        >
          ჩასმა
        </div>
      </div>
    </div>
  );
}
