import React, { useEffect, useState } from "react";
import "./card.css";
import NotificationData from "./NotificationData";
import AddresateData from "./AddresateData";
import Confirmation from "../../../ConfirmationModal/Confirmation";
import FilesData from "./FilesData";
import bin_icon from "../../../../assets/bin.png";
import edit_icon from "../../../../assets/edit_icon.png";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useToggle from "../../../../hooks/useToggle";
import { deleteNotification } from "../../../../state/action-creators/notificationActions";

const initialUserNumber = 3;

function NotificationsCard({ self, toggleShowForm, setEditingData }) {
  const users = self.target_users;
  const { tab } = useParams();
  const [usersToDisplay, setUsersToDisplay] = useState(
    users ? users.slice(0, initialUserNumber) : []
  );
  const [showConfirm, toggleShowConfirm] = useToggle();

  const dispatch = useDispatch();

  const onMoreClick = () => {
    setUsersToDisplay(users);
  };

  const onEditClick = () => {
    if (tab !== "delayed") {
      return;
    }
    const date = new Date(self.planned_sending_datetime);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    setEditingData({
      title: self.title,
      text: self.text,
      files: self.files,
      date: date,
      time: `${hours}:${minutes}`,
      id: self.id,
      users: self.target_users,
    });

    toggleShowForm(true);
  };

  const handleNotificationDelete = () => {
    dispatch(deleteNotification(self.id));
    toggleShowConfirm(false);
  };

  useEffect(() => {
    setUsersToDisplay(users ? users.slice(0, initialUserNumber) : []);
  }, [users]);

  return (
    <div
      className={
        tab === "received"
          ? `notifications-card-received`
          : `notifications-card`
      }
    >
      <Confirmation
        content={"ნამდვილად გსურთ წაშლა?"}
        showConfirm={showConfirm}
        toggleShowConfirm={toggleShowConfirm}
        onConfirm={handleNotificationDelete}
      />
      <div className="notification-column">
        <NotificationData self={self} />
      </div>
      <div className="notification-column addresates-column">
        <AddresateData
          self={self}
          users={usersToDisplay.length > 0 ? usersToDisplay : [self.author]}
          setUsers={setUsersToDisplay}
        />
        {users.length > usersToDisplay.length && (
          <p className="more-addresate" onClick={onMoreClick}>
            მეტი ...
          </p>
        )}
      </div>
      <div
        className="notification-column"
        style={tab === "received" ? { border: "none" } : {}}
      >
        <p style={{ marginBottom: "15px" }}>{self.title}</p>
        <p>{self.text}</p>
      </div>
      <div className="notification-column" style={{ border: "none" }}>
        <FilesData self={self} />
      </div>
      {tab === "delayed" && (
        <div className="notification-card-actions">
          <img src={edit_icon} alt="edit-icon" onClick={onEditClick} />
          <img src={bin_icon} alt="bin-icon" onClick={toggleShowConfirm} />
        </div>
      )}
    </div>
  );
}

export default NotificationsCard;
