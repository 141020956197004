import React from "react";
import InputField from "../../../InputField/InputField";
import calendar_icon from "../../../../assets/calendar-icon.png";
import "./additional-fees.css";
import TextArea from "../../../Textarea/TextArea";
import useToggle from "../../../../hooks/useToggle";
import DatetimeInputModal from "../../notifications/form/DatetimeInputModal";
import { dateToString } from "../../../../libs/Functions";
import CurrencyToggler from "./CurrencyToggler";

function InvoiceDataForm({
  description,
  setDescription,
  cost,
  setCost,
  date,
  setDate,
  time,
  setTime,
  currency,
  setCurrency,
}) {
  const [showCalendar, toggleShowCalendar] = useToggle();
  const [dateReady, toggleDateReady] = useToggle(date !== "" ? true : false);

  return (
    <div className="message-form">
      <DatetimeInputModal
        isOpen={showCalendar}
        toggleIsOpen={toggleShowCalendar}
        value={date}
        onChange={setDate}
        setMessageTime={() => {}}
        toggleDateReady={toggleDateReady}
        hasTime={false}
      />
      <label>ინვოისის მონაცემი</label>
      <div
        className="invoice-data-form-inputs"
        style={{ marginTop: "20px", paddingBottom: "40px" }}
      >
        <div className="invoice-data-form-inputs-row">
          <div className="invoice-form-cost">
            <CurrencyToggler current={currency} setCurrent={setCurrency} />
            <InputField
              placeholder={`გადასახდელი თანხა ${currency}`}
              type={"number"}
              value={cost}
              onChange={(e) => setCost(e.target.value)}
            />
          </div>
          <div
            className="input message-date-input"
            style={{ width: "100%" }}
            onClick={toggleShowCalendar}
          >
            <p>
              {dateReady && date !== ""
                ? dateToString(date)
                : "გადახდის ბოლო ვადა"}
            </p>
            <img src={calendar_icon} alt="upload" />
          </div>
        </div>
        <TextArea
          placeholder={"დანიშნულება"}
          height={300}
          symbolsLimit={700}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
    </div>
  );
}

export default InvoiceDataForm;
