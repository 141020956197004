import { ADD_SUBCATEGORY, CLEAR_SUBCATEGORIES, GET_SUBCATEGORIES, SAVE_FEE, SET_FEE_SAVING_LOADING, SET_SUBCATEGORIES_LOADING, SET_SUBCATEGORY_UPDATE_LOADING, TOGGLE_CATEGORY_VISIBILITY, UPDATE_SUBCATEGORY } from "../action-creators/types";

const initialState = {
    items: [],
    parent_id: null,
    isLoading: false,
    isfee: false,
    isUpdating: false,
    isError: false,
    message: '',
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_SUBCATEGORIES:
            return {
                ...state,
                items: action.payload,
                isLoading: false,
                isfee: action.isfee,
            }
        case SET_SUBCATEGORIES_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case CLEAR_SUBCATEGORIES:
            return {
                ...state,
                items: [],
            }
        case TOGGLE_CATEGORY_VISIBILITY:
            const id = action.payload;
            return {
                ...state,
                items: state.items.map((item) => 
                item.id===id?{...item, is_visible: !item.is_visible}:item)
            }
        case ADD_SUBCATEGORY:
            return {
                ...state,
                items: [...state.items, action.payload], 
            }
        case UPDATE_SUBCATEGORY:
            const cat = action.payload;
            return {
                ...state,
                items: state.items.map((item) => item.id===cat.id ? cat:item),
                isUpdating: false,
            }
        case SET_SUBCATEGORY_UPDATE_LOADING:
            return {
                ...state,
                isUpdating: action.payload,
            }
        case SET_FEE_SAVING_LOADING:
            return {
                ...state,
                isUpdating: action.payload,
            }
        case SAVE_FEE:
            const [item_id, newFee] = action.payload;
            return {
                ...state,
                items: state.items.map((item) => item.id === item_id ? 
                ({...item, fees: item.fees.map((fee) => fee.id===newFee.id ? newFee:fee)}) : item
                )
            }
        default:
            return state;
    }
}

export default reducer;