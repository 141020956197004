import React, { useEffect, useState } from "react";
import AddresateFIlter from "./AddresateFIlter";
import "../notifications.css";
import MessageForm from "./MessageForm";
import AddresateList from "./AddresateList";
import close from "../../../../assets/close.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  createNotification,
  putNotification,
  toggleAddresate,
  unselectAddresates,
} from "../../../../state/action-creators/addresateActions";
import {
  attachFile,
  clearNotifications,
} from "../../../../state/action-creators/notificationActions";
import { generateHours, localDateISO } from "../../../../libs/Functions";

const hours = generateHours().slice(1);

function addHours(inputTime, hoursToAdd) {
  // Parse input time
  const [hours, minutes] = inputTime.split(":").map(Number);

  // Add hours
  let newHours = (hours + hoursToAdd) % 24;

  // If newHours is negative, adjust it
  if (newHours < 0) {
    newHours += 24;
  }

  // Format result
  const formattedHours = String(newHours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

function NotificationForm({ editingData, setEditingData, toggleShowForm }) {
  const [formData, setFormData] = useState({
    message_title: editingData ? editingData.title : "",
    message_text: editingData ? editingData.text : "",
  });
  const [messageDate, setMessageDate] = useState(
    editingData ? localDateISO(editingData.date) : ""
  );
  const [messageTime, setMessageTime] = useState(
    editingData
      ? {
          name: addHours(editingData.time, 4),
          id: hours.find((item) => (item.name = addHours(editingData.time, 4)))
            .id,
        }
      : null
  );
  const { items, isLoading } = useSelector((state) => state.addresates);

  const dispatch = useDispatch();

  const [files, setFiles] = useState(editingData ? editingData.files : []);

  const canSend = formData.message_text !== "" && formData.message_text !== "";

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (editingData) {
      const formData = new FormData();
      formData.append("file", file);
      dispatch(attachFile(editingData.id, formData));
    }
    setFiles([...files, file]);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearData = () => {
    setFormData({
      message_title: "",
      message_text: "",
    });
    setFiles([]);
    setMessageDate("");
    setMessageTime(null);
  };

  const onSend = () => {
    if (!canSend) {
      return;
    }
    const [hours, minutes] = messageTime ? messageTime.name.split(":") : [0, 0];

    const date = messageDate
      ? moment(messageDate)
          .set("h", Number(hours))
          .set("m", Number(minutes))
          .set("s", 0)
          .set("millisecond", 0)
          .toDate()
      : null;

    const { message_title, message_text } = formData;
    const post_data = date
      ? {
          target_user_ids: items.filter((a) => a.isSelected).map((a) => a.id),
          title: message_title,
          text: message_text,
          planned_sending_datetime: date
            .toISOString()
            .substring(0, date?.toISOString().length - 1),
        }
      : {
          target_user_ids: items.filter((a) => a.isSelected).map((a) => a.id),
          title: message_title,
          text: message_text,
        };

    const sendData = new FormData();
    sendData.append("post_data", JSON.stringify(post_data));
    for (let i = 0; i < files.length; i++) {
      sendData.append("files", files[i]);
    }

    if (editingData) {
      dispatch(putNotification(editingData.id, post_data)); // if user is editing
    } else {
      dispatch(createNotification(sendData)); // if user is creating
    }

    // actions after send
    clearData();
    setEditingData(null);
    dispatch(unselectAddresates());
    toggleShowForm(false);
  };

  const onClose = () => {
    setEditingData(null);
    clearData();
    dispatch(clearNotifications());
    toggleShowForm();
  };

  useEffect(() => {
    return () => {
      setEditingData(null);
      clearData();
      dispatch(clearNotifications());
    };
  }, []);

  useEffect(() => {
    if (editingData && !isLoading) {
      const users = editingData.users;
      users.forEach((user) => dispatch(toggleAddresate(user.id)));
    }
  }, [isLoading]);

  return (
    <div className="table form-table">
      <div className="notifications-form">
        <div className="notifications-form-left">
          <AddresateFIlter />
          <AddresateList />
        </div>
        <div className="notifications-form-right">
          <MessageForm
            onChange={onChange}
            formData={formData}
            messageDate={messageDate}
            setMessageDate={setMessageDate}
            messageTime={messageTime}
            setMessageTime={setMessageTime}
            handleUpload={handleUpload}
            files={files}
            setFiles={setFiles}
            editingData={editingData}
          />
        </div>
      </div>
      <div className="form-actions">
        {
          <div
            className={`form-btn-primary ${!canSend && "add-button-disabled"}`}
            onClick={onSend}
          >
            გაგზავნა
          </div>
        }
      </div>
      <img src={close} alt="close" className="form-close" onClick={onClose} />
    </div>
  );
}

export default NotificationForm;
