import { useQuery } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";

const user = store.getState().user.user;

export default function useCategories(parentId) {
  return useQuery({
    queryKey: ["categories"],
    queryFn: () =>
      API.get(
        user,
        parentId
          ? "/admin/fee/categories?parent_id" + parentId
          : "/admin/fee/categories"
      ).then((res) => res),
  });
}
