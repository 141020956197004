import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import InvoiceFilter from "../../components/tables/payments-tables/InvoiceFilter";
import RequestFeesFilter from "../../components/tables/payments-tables/RequestFeesFilter";
import InvoiceTable from "../../components/tables/payments-tables/InvoiceTable";
import PaymentsControlbar from "../../components/tables/payments-tables/PaymentsControlbar";
import { paymentsAttributes } from "../../constants";
import useToggle from "../../hooks/useToggle";
import "./Payments.css";
import FeeForm from "../../components/tables/payments-tables/additional-fees/FeeForm";

export default function Payments({ attribute, tab }) {
  const [selectedAttribute, setSelectedAttribute] = useState(0);
  const [selectedServicePaymentsTable, setSelectedServicePaymentsTable] =
    useState(0);
  const [selectedComunalsTable, setSelectedComunalsTable] = useState(0);
  const [showFilter, toggleShowFilter] = useToggle(false);
  const [showFeeForm, toggleShowFeeForm] = useToggle(false);

  const onShowFilter = (flag) => {
    toggleShowFilter(flag);
  };

  const resetTables = () => {
    setSelectedServicePaymentsTable(0);
    setSelectedComunalsTable(0);
  };

  return (
    <div className="payments-container">
      <Navbar
        title="გადახდების მართვის მოდული"
        attributes={paymentsAttributes}
        selected={selectedAttribute}
        setSelected={setSelectedAttribute}
        resetTables={resetTables}
        toggleShowFilter={toggleShowFilter}
        module={"payments"}
        attribute={attribute}
        tab={tab}
      />
      <div className="table-container">
        <PaymentsControlbar
          selected={selectedAttribute}
          selectedServicePaymentsTable={selectedServicePaymentsTable}
          setSelectedServicePaymentsTable={setSelectedServicePaymentsTable}
          setSelectedComunalsTable={setSelectedComunalsTable}
          selectedComunalsTable={selectedComunalsTable}
          showFilter={showFilter}
          toggleShowFilter={onShowFilter}
          showFeeForm={showFeeForm}
          toggleShowFeeForm={toggleShowFeeForm}
          attribute={attribute}
          tab={tab}
        />
        {showFilter && attribute !== "fees" && (
          <InvoiceFilter tab={tab} attribute={attribute} />
        )}
        {showFilter && attribute === "fees" && <RequestFeesFilter />}

        {!showFeeForm && (
          <InvoiceTable
            selectedAttribute={selectedAttribute}
            selectedComunalsTable={selectedComunalsTable}
            attribute={attribute}
            tab={tab}
          />
        )}
        {showFeeForm && <FeeForm toggleShowFeeForm={toggleShowFeeForm} />}
      </div>
    </div>
  );
}
