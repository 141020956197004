import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Addresate from "./Addresate";
import Checkbox from "../../../Checkbox/Checkbox";
import { selectAllAddresates } from "../../../../state/action-creators/addresateActions";

function AddresateList() {
  const { items, isLoading } = useSelector((state) => state.addresates);
  const isAllChecked = useMemo(
    () => !items.filter((addresate) => !addresate.isSelected).length > 0,
    [items]
  );
  const dispatch = useDispatch();

  const allCheck = () => {
    dispatch(selectAllAddresates());
  };

  return (
    <div className="addresate-list">
      <div className="check-every-addresate">
        <Checkbox
          style={{ flexGrow: "0", marginBottom: "8px" }}
          isChecked={isAllChecked}
          onClick={allCheck}
        />
        <p>ყველა</p>
      </div>
      {isLoading ? (
        <div className="loader-black addresates-loader "></div>
      ) : null}
      {items.map((addresate, i) => (
        <Addresate self={addresate} key={i} />
      ))}
    </div>
  );
}

export default AddresateList;
