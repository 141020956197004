import React from 'react';
import phoneIcon from '../../../assets/phoneIcon.png';
import bin from '../../../assets/bin.png';
import edit_icon from '../../../assets/edit_icon.png';
import filled_edit_icon from '../../../assets/filled_edit_icon.png';
import { dateToString } from '../../../libs/Functions';

export default function OrganizationsList({
    organizations,
    userEditingRecord,
    organizationEdit,
    setDeletingRecord,
    selectedResponsible,
    setSelectedResponsible,
    isLease,

}) {

    const onSelect = (org) => {
        if (!org.is_responsible) {
            return;
        }
        if (selectedResponsible !== null) {
            setSelectedResponsible(null);
            return;
        }
        setSelectedResponsible(org);
      }

    const myEdit = (e, organization) => {
        e.stopPropagation();
        organizationEdit(organization);
    }

    const onDeleteClick = (e, organization) => {
        e.stopPropagation();
        setDeletingRecord({name: "organization", obj: organization});
      }

  return (
    <>
    {organizations.map((organization, i) => 
        <div 
            key={i} 
            className='card-item owner-card-item' 
            onClick={() => onSelect(organization)}
            style={{backgroundColor: organization.id === selectedResponsible?.id ? '#F0F0F7' : '', cursor: 'pointer'}} 
        >

        {organization.representative.is_verified && <img src={phoneIcon} 
        onClick={(event)=>{event.stopPropagation(); dateToString(organization.representative.verification_date)}}  
        className="phoneIcon phone-in-edit" alt="phone icon" style={{left: '0', top: '5px'}}/>}

        <p className={userEditingRecord?.id===organization.representative.id ? 'editing':''}>
            {`იურიდიული პირი | ${organization.name} | ${organization.identity_num} 
        | ${organization.representative.name+" "+organization.representative.surname} | ${organization.representative.pers_id} | 
            ${organization.representative.country.code} (${organization.representative.country.phone_prefix}) 
            ${organization.representative.phone}`}</p>
        <div className='edit-icons'>
            {!isLease && <img src={userEditingRecord?.id===organization.representative.id ? filled_edit_icon:edit_icon} alt='edit' className='edit-icon' onClick={(e) => myEdit(e, organization)}/>}
            {!isLease && <img src={bin} alt='bin' className='bin' onClick={(e) => onDeleteClick(e, organization)}/>}
        </div>
    </div>)}
    </>
  )
}
