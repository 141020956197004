import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";
import { setMessage } from "../state/action-creators";

const user = store.getState().user.user;

export default function useRemoveWaiveMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ apartment_id, category_id }) =>
      API.delete(
        user,
        `/admin/apartment/${apartment_id}/waive_fee/${category_id}`
      ),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["subcategory"]);
      const message = "წარმატებით ჩაერთო გადასახადი";
      setMessage(message);
    },
  });
}
