import { RESET_USER, LOGIN_FINISH, LOGIN_ERROR, SET_LOADING, SET_ERROR, LOGOUT_ERROR, UPDATE_USER, SET_LOGOUT_LOADING } from "../action-creators/types";

const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user: user ? user : null,
    logout_loading: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_USER:
            localStorage.removeItem('user')
            return {
                user: null,
                isError: false,
                isSuccess: false,
                isLoading: false,
                logout_loading: false,
                message: '',
            }
        case LOGIN_FINISH:
            const res = action.payload
            const userData = {
                ...res.user,
                access_token: res.access_token,
                refresh_token: res.refresh_token,
            }
            localStorage.setItem("user", JSON.stringify(userData))
            return {
                ...state,
                user: userData,
                isLoading: false,
                isSuccess: true,
                message: 'Logged in',
                isError: false,
            }
        case LOGIN_ERROR:
            const err = action.payload
            return {
                ...state,
                isError: true,
                isLoading: false,
                message: err.response.data.message.ge,
            }
        case UPDATE_USER:
            const [key, value] = action.payload;
            const result = {...state.user, [key]: value}
            localStorage.setItem('user', JSON.stringify(result));
            return {
                ...state,
                user: result,
            }
        case LOGOUT_ERROR:
            return {
                ...state,
                isError: false,
                isLoading: false,
                logout_loading: false,
                message: err.message,
            }
        case SET_LOADING:
            const flag = action.payload
            return {
                ...state,
                isLoading: flag
            }
        case SET_LOGOUT_LOADING:
            return {
                ...state,
                logout_loading: true,
            }
        case SET_ERROR:
            const msg = action.payload
            return {
                ...state,
                isError: true,
                message: msg,
            }
        default:
            return state
    }
}

export default reducer;