import React from "react";

export default function DraftIcon({ fill }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3982 2.35494L17.5257 2.47494L17.6465 2.60244C18.1517 3.17104 18.4205 3.91116 18.3981 4.67143C18.3756 5.43169 18.0635 6.15465 17.5257 6.69244L7.55234 16.6658C7.32139 16.8967 7.03414 17.0633 6.719 17.1491L2.4565 18.3116C2.35025 18.3404 2.23826 18.3407 2.13187 18.3123C2.02549 18.284 1.92848 18.228 1.85067 18.1501C1.77286 18.0722 1.71701 17.9752 1.68877 17.8687C1.66052 17.7623 1.66089 17.6503 1.68984 17.5441L2.8515 13.2808C2.93742 12.9659 3.10402 12.679 3.33484 12.4483L13.3082 2.47494C13.8461 1.9372 14.5691 1.62527 15.3293 1.60297C16.0896 1.58066 16.8297 1.84966 17.3982 2.35494ZM12.5007 5.04994L4.219 13.3316C4.14217 13.4085 4.08673 13.5042 4.05817 13.6091L3.18317 16.8174L6.3915 15.9424C6.49621 15.9135 6.59156 15.8578 6.66817 15.7808L14.9498 7.49994L12.5007 5.04994ZM5.43817 9.16661L4.18817 10.4166H2.2915C2.12574 10.4166 1.96677 10.3508 1.84956 10.2335C1.73235 10.1163 1.6665 9.95737 1.6665 9.79161C1.6665 9.62585 1.73235 9.46687 1.84956 9.34966C1.96677 9.23245 2.12574 9.16661 2.2915 9.16661H5.43734H5.43817ZM8.7715 5.83327L7.5215 7.08327H2.2915C2.12574 7.08327 1.96677 7.01742 1.84956 6.90021C1.73235 6.783 1.6665 6.62403 1.6665 6.45827C1.6665 6.29251 1.73235 6.13354 1.84956 6.01633C1.96677 5.89912 2.12574 5.83327 2.2915 5.83327H8.77067H8.7715ZM14.1923 3.35827L13.384 4.16661L15.8332 6.61661L16.6415 5.80827C16.8024 5.64746 16.93 5.45653 17.0171 5.2464C17.1042 5.03626 17.149 4.81103 17.149 4.58357C17.1491 4.3561 17.1043 4.13086 17.0173 3.92069C16.9303 3.71053 16.8027 3.51956 16.6419 3.35869C16.4811 3.19782 16.2902 3.0702 16.08 2.98312C15.8699 2.89604 15.6447 2.85119 15.4172 2.85116C15.1898 2.85112 14.9645 2.89588 14.7543 2.98289C14.5442 3.0699 14.3532 3.19746 14.1923 3.35827ZM12.1048 2.49994L10.8548 3.74994H2.2915C2.12574 3.74994 1.96677 3.68409 1.84956 3.56688C1.73235 3.44967 1.6665 3.2907 1.6665 3.12494C1.6665 2.95918 1.73235 2.80021 1.84956 2.683C1.96677 2.56579 2.12574 2.49994 2.2915 2.49994H12.104H12.1048Z"
        fill={fill}
      />
    </svg>
  );
}
