import { useQuery } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";

const user = store.getState().user.user;

export default function useSubcategories(parentId) {
  return useQuery({
    queryKey: ["subcategory", parentId],
    queryFn: () =>
      API.get(user, "/admin/fee/categories?parent_id=" + parentId).then(
        (res) => res
      ),
  });
}
