import "../index.css";
import add from "../../../../assets/add.png";
import filled_add from "../../../../assets/filled_add.png";
import search from "../../../../assets/search.png";
import filled_search from "../../../../assets/filled_search.png";
import { useNavigate } from "react-router-dom";
import OwnerIcon from "../../../Icons/OwnerIcon";
import LeaserIcon from "../../../Icons/LeaserIcon";
import VisitorIcon from "../../../Icons/VisitorIcon";
import DraftIcon from "../../../Icons/DraftIcon";

const orange = "#DAA161";
const black = "#333333";

export default function ControlbarForUsers(props) {
  const {
    toggleShowForm,
    showForm,
    toggleEditing,
    editing,
    showFilter,
    toggleShowFilter,
    tab,
  } = props;
  const navigate = useNavigate();

  const onBarClick = (link) => {
    toggleEditing(false);
    toggleShowForm(false);
    toggleShowFilter(false);
    navigate(link);
  };

  const onDraftClick = () => onBarClick("/dashboard/services/users/draft");
  const onOwnershipsClick = () =>
    onBarClick("/dashboard/services/users/private");
  const onLeasesClick = () => onBarClick("/dashboard/services/users/lease");
  const onVisitorsClick = () => onBarClick("/dashboard/services/users/visitor");

  return (
    <>
      <div className="controlbar-left">
        {tab !== "draft" && (
          <img
            src={showFilter ? filled_search : search}
            alt="search"
            onClick={toggleShowFilter}
          ></img>
        )}
        {tab === "private" && !editing && (
          <img
            src={showForm ? filled_add : add}
            alt="add"
            onClick={showForm ? () => {} : toggleShowForm}
          ></img>
        )}
      </div>
      <div className="controlbar-right">
        <div
          className={
            tab === "private" ? "selector selector-selected" : "selector"
          }
          onClick={onOwnershipsClick}
        >
          <OwnerIcon fill={tab === "private" ? orange : black} />
          <p>ფართის მეპატრონე</p>
        </div>
        <div
          className={
            tab === "lease" ? "selector selector-selected" : "selector"
          }
          onClick={onLeasesClick}
        >
          <LeaserIcon fill={tab === "lease" ? orange : black} />
          <p>ფართის დამქირავებელი</p>
        </div>
        <div
          className={
            tab === "visitor" ? "selector selector-selected" : "selector"
          }
          onClick={onVisitorsClick}
        >
          <VisitorIcon fill={tab === "visitor" ? orange : black} />
          <p>ვიზიტორი</p>
        </div>
        <div
          className={
            tab === "draft" ? "selector selector-selected" : "selector"
          }
          onClick={onDraftClick}
        >
          <DraftIcon fill={tab === "draft" ? orange : black} />
          <p>მონახაზები</p>
        </div>
      </div>
    </>
  );
}
