import "./OwnersCard.css";
import phoneIcon from "../../assets/phoneIcon.png";
import { countryNames } from "../../constants/index";
import { useState } from "react";
import RegistrationModal from "./RegistrationModal";

const OwnersCard = ({ ownership_card, onCardClick, tab }) => {
  const users = ownership_card.users;
  const organizations = ownership_card.organizations;
  const apartments = ownership_card.apartments;
  const parking_spaces = ownership_card.parking_spaces;

  const regular_users = users.filter((user) => !user.is_responsible);
  const responsible_users = users.filter((user) => user.is_responsible);

  const regular_organizations = organizations.filter(
    (org) => !org.is_responsible
  );
  const responsible_organizations = organizations.filter(
    (org) => org.is_responsible
  );

  const isVisitor = tab === "visitor";
  const isLease = tab === "lease";
  let className = "";

  if (isLease) {
    className = "lease_card";
  } else if (isVisitor) {
    className = "visitor_card";
  } else {
    className = "owners_card";
  }

  const [status, setStatus] = useState("box-off");
  const [verificationDate, setVerificationDate] = useState("");

  const showDate = (verification_date) => {
    setVerificationDate(verification_date);
    if (status === "box-off") {
      setStatus("box-on");
    }
    if (status === "box-on") {
      setStatus("box-off");
    }
  };

  const isFirstElement = (a, arr) => {
    if (arr[0].id === a.id) {
      return true;
    } else {
      return false;
    }
  };
  const noLineApartment = (a, arr) => {
    if (
      arr[arr.length - 1].id === a.id &&
      apartments.length >= parking_spaces.length &&
      apartments.length >= users.length + organizations.length
    )
      return true;
    else return false;
  };
  const noLineParking = (a, arr) => {
    if (
      arr[arr.length - 1].id === a.id &&
      apartments.length <= parking_spaces.length &&
      parking_spaces.length >= users.length + organizations.length
    )
      return true;
    else return false;
  };

  const formatDate = (utc) => {
    const d = new Date(utc);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    return `${day}.${month}.${d.getFullYear()}`;
  };

  return (
    <div className={className} onClick={() => onCardClick(ownership_card)}>
      <div className="owners-column">
        <div className="card-rows">
          {!isLease && !isVisitor && <label>ფართის მეპატრონე</label>}
          {regular_users.map((a, i) => (
            <div
              key={a.id}
              className={`table-rows ${
                i === regular_users.length - 1 &&
                organizations.length === 0 &&
                responsible_users.length === 0 &&
                responsible_organizations.length === 0
                  ? ""
                  : "lower-line"
              }`}
            >
              <div className="owners-info">
                {a.is_verified && (
                  <img
                    src={phoneIcon}
                    onClick={(event) => {
                      event.stopPropagation();
                      showDate(a.verification_date);
                    }}
                    className="phoneIcon"
                    alt="phone icon"
                  />
                )}

                <div className="owners-text">
                  {`ფიზიკური პირი | ${a.name} ${a.surname} | ${a.pers_id} | ${
                    countryNames[a.country.code]
                  } (${a.country.phone_prefix}) ${a.phone} ${
                    a.responsible_person ? "(პასუხისმგებელი პირი)" : ""
                  }`}
                </div>
              </div>
            </div>
          ))}

          {regular_organizations.map((a, i) => (
            <div
              key={a.id}
              className={`table-rows ${
                i === regular_organizations.length - 1 &&
                responsible_users.length === 0 &&
                responsible_organizations.length === 0
                  ? ""
                  : "lower-line"
              }`}
            >
              <div className="owners-info">
                {a.representative.is_verified && (
                  <img
                    src={phoneIcon}
                    onClick={(event) => {
                      event.stopPropagation();
                      showDate(a.representative.verification_date);
                    }}
                    className="phoneIcon"
                    alt="phone icon"
                  />
                )}
                <div className="owners-text">
                  {`იურიდიული პირი | ${a.name} | ${a.identity_num} | ${
                    a.representative.name
                  } ${a.representative.surname} | ${
                    a.representative.pers_id
                  } | ${countryNames[a.representative.country.code]} (${
                    a.representative.country.phone_prefix
                  }) ${a.representative.phone}`}
                </div>
              </div>
            </div>
          ))}

          {responsible_users.length !== 0 && (
            <label style={{ marginTop: "40px" }}>პასუხისმგებელი პირი</label>
          )}
          {responsible_users.map((a, i) => (
            <div
              key={a.id}
              className={
                i === responsible_users.length - 1 &&
                responsible_organizations.length === 0
                  ? "table-rows"
                  : "table-rows lower-line"
              }
            >
              <div className="owners-info">
                {a.is_verified && (
                  <img
                    src={phoneIcon}
                    onClick={(event) => {
                      event.stopPropagation();
                      showDate(a.verification_date);
                    }}
                    className="phoneIcon"
                    alt="phone icon"
                  />
                )}

                <div className="owners-text">
                  {`ფიზიკური პირი | ${a.name} ${a.surname} | ${a.pers_id} | ${
                    countryNames[a.country.code]
                  } (${a.country.phone_prefix}) ${a.phone} ${
                    a.responsible_person ? "(პასუხისმგებელი პირი)" : ""
                  }`}
                </div>
              </div>
            </div>
          ))}
          {responsible_organizations.map((a, i) => (
            <div
              key={a.id}
              className={
                i === responsible_organizations.length - 1
                  ? "table-rows"
                  : "table-rows lower-line"
              }
            >
              <div className="owners-info">
                {a.representative.is_verified && (
                  <img
                    src={phoneIcon}
                    onClick={(event) => {
                      event.stopPropagation();
                      showDate(a.representative.verification_date);
                    }}
                    className="phoneIcon"
                    alt="phone icon"
                  />
                )}
                <div className="owners-text">
                  {`იურიდიული პირი | ${a.name} | ${a.identity_num} | ${
                    a.representative.name
                  } ${a.representative.surname} | ${
                    a.representative.pers_id
                  } | ${countryNames[a.representative.country.code]} (${
                    a.representative.country.phone_prefix
                  }) ${a.representative.phone}`}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {!isVisitor && (
        <div className="apartments-column">
          <div className="apartment-rows">
            {apartments.map((a) => (
              <div
                key={a.id}
                className={
                  noLineApartment(a, apartments)
                    ? "table-rows"
                    : "table-rows lower-line"
                }
              >
                {`${a.complex_type.ge} | სართ. ${a.floor} | № ${a.number}  | ${a.area}㎡`}
              </div>
            ))}
          </div>
        </div>
      )}

      {isLease && (
        <div className="lease-column">
          <div className="lease-rows">
            <p>{`${formatDate(ownership_card.from_date)} - ${formatDate(
              ownership_card.to_date
            )}`}</p>
          </div>
        </div>
      )}

      <div className="parking-column">
        <div className="parking-rows">
          {parking_spaces.map((a) => (
            <div
              key={a.id}
              className={
                noLineParking(a, parking_spaces)
                  ? "table-rows"
                  : "table-rows lower-line"
              }
            >
              {`${a.parking_type.ge} | ${a.quantity} | ${a.ownership_type.ge} ${
                a.ownership_type.ge === "იჯარა"
                  ? `| ${formatDate(a.from_date)} - ${formatDate(a.to_date)}`
                  : ""
              }`}
            </div>
          ))}
        </div>
      </div>

      {status === "box-on" && (
        <RegistrationModal
          settingDate={formatDate(verificationDate)}
          showDate={showDate}
          status={status}
          setStatus={setStatus}
        />
      )}
    </div>
  );
};

export default OwnersCard;
