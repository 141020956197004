import { useMutation } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";
import { setMessage } from "../state/action-creators";

const user = store.getState().user.user;

export default function useInvoiceMutation() {
  return useMutation({
    mutationFn: ({ body, onFinished }) => {
      return API.post(
        user,
        `/admin/fee/additional/invoices`,
        body,
        typeof onFinished === "function" ? onFinished : () => {}
      );
    },
    onSuccess: (data) => {
      const message = data.user_ids
        ? "აუცილებელია ადრესატების არჩევა"
        : "წარმატებით გაიგზავნა";
      store.dispatch(setMessage(message));
    },
  });
}
