import React, { useEffect } from "react";
import useCategories from "../../../../../hooks/useCategories";
import Category from "./Category";
import SubcategoryList from "./SubcategoryList";

export default function CategoryList({
  categories,
  setCategories,
  selectedSubcategory,
  setSelectedSubcategory,
  onSubcategoryClick,
  selectedApartment,
}) {
  const { data, isFetching } = useCategories();

  useEffect(() => {
    if (isFetching) {
      setCategories([]);
    } else {
      setCategories(data.categories);
    }
  }, [isFetching]);

  return (
    <div className="category-list">
      {categories?.map(
        (category, i) =>
          (category.id === 2 || category.id === 14) && (
            <div key={i} className="tax-category-container">
              <Category self={category} />
              <SubcategoryList
                parentId={category.id}
                selectedSubcategory={selectedSubcategory}
                setSelectedSubcategory={setSelectedSubcategory}
                onClick={onSubcategoryClick}
                selectedApartment={selectedApartment}
              />
            </div>
          )
      )}
    </div>
  );
}
