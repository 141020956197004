import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import { useDispatch, useSelector } from "react-redux";
import { getTypes } from "../../../libs/API";
import close from "../../../assets/close.png";
import useToggle from "../../../hooks/useToggle";
import Skeleton from "react-loading-skeleton";
import DownloadButton from "../../DownloadButton/DownloadButton";
import InvoiceHeader from "./InvoiceHeader";
import InvoiceCard from "./InvoiceCard";
import Modal from "react-modal";
import "./payments.css";
import { dateJSON, filteredObject } from "../../../libs/Functions";
import {
  closeInvoice,
  toggleEveryClosingCandidate,
} from "../../../state/action-creators";
import CloseForm from "./CloseForm";

function InvoiceTable({ selectedComunalsTable, tab, attribute }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const requests = useSelector((state) => state.requests.items);
  const requestsLoading = useSelector((state) => state.requests.isLoading);
  const requestsLastFetch = useSelector((state) => state.requests.lastFetch);
  const requestParams = useSelector((state) => state.requests.params);
  const requestsUpdating = useSelector((state) => state.requests.updating);
  const { params, items, isLoading, lastFetch, updating } = useSelector(
    (state) => state.invoices
  );
  const {
    getInvoices,
    updateInvoices,
    approveInvoices,
    getRequests,
    updateRequests,
  } = bindActionCreators(actionCreators, dispatch);
  const [checked, toggleChecked] = useToggle();
  const [pageSize, setPageSize] = useState(0);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [showConfirm, toggleShowConfirm] = useToggle();
  const [checkFlag, toggleCheckFlag] = useToggle();
  const skeletons = [1, 2, 3, 4];

  // states for closing functionality
  const forClose = tab === "unpaid" || tab === "overdue";
  const [closingChecked, setClosingChecked] = useState(false);
  const [showCloseForm, toggleShowCloseForm] = useToggle();
  const [paymentDate, setPaymentDate] = useState("");
  const [comment, setComment] = useState("");

  //functions for closing
  const onCheckEveryClosingCandidate = () => {
    setClosingChecked((prev) => {
      dispatch(toggleEveryClosingCandidate(!prev));
      return !prev;
    });
  };

  const onCloseEvery = () => {
    const closingCandidates = items
      .filter((item) => item.isSelectedForClosing)
      .map((item) => ({
        invoice_id: item.id,
        comment,
        payment_datetime: dateJSON(paymentDate),
      }));

    dispatch(
      closeInvoice({
        invoices_closing_data: closingCandidates,
      })
    );

    setComment("");
    setPaymentDate("");
    toggleShowCloseForm(false);
    setClosingChecked(false);
  };

  const onShowMore = () => {
    updateInvoices({ ...params, load_from: items.length });
  };

  const onShowMoreRequests = () => {
    updateRequests(user, { ...requestParams, load_from: requests.length });
  };

  const onCheck = (item) => {
    setSelectedInvoices([...selectedInvoices, item]);
  };

  const onUncheck = (id) => {
    setSelectedInvoices(selectedInvoices.filter((i) => i.id !== id));
  };

  const onCheckEvery = () => {
    toggleChecked();
    toggleCheckFlag();
    if (!checked) {
      setSelectedInvoices(items.map((e) => ({ id: e.id, comment: e.comment })));
    } else {
      setSelectedInvoices([]);
    }
  };

  const confirm = () => {
    if (!checked) {
      return;
    }

    toggleShowConfirm();
  };

  const onApproveEvery = () => {
    if (!checked) {
      return;
    }
    toggleShowConfirm();
    approveInvoices(selectedInvoices);
    setSelectedInvoices([]);
    toggleCheckFlag(false);
    toggleChecked(false);
  };

  useEffect(() => {
    toggleCheckFlag(false);
    toggleChecked(false);
  }, [selectedComunalsTable]);

  useEffect(() => {
    if (attribute === "fees") {
      getRequests(user, { load_from: 0, status: "active,processing,done" });
    } else {
      getInvoices({
        load_from: 0,
        invoice_type: attribute,
        status: tab,
        sorting: "payment_datetime",
      });
    }
    toggleChecked(false);
    toggleCheckFlag(false);
    setSelectedInvoices([]);
    setClosingChecked(false);
    dispatch(toggleEveryClosingCandidate(false));
  }, [tab, attribute]);

  useEffect(() => {
    getTypes((data) => setPageSize(data.page_size));
  }, []);

  return (
    <div className="invoice-table-container">
      <CloseForm
        isOpen={showCloseForm}
        toggleIsOpen={toggleShowCloseForm}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        dateValue={paymentDate}
        onDateChange={(e) => setPaymentDate(e.target.value)}
        onConfirm={onCloseEvery}
        className={"close-form"}
      />
      {forClose && (
        <div className="approve-checkbox">
          <div
            className={`checkbox ${closingChecked && "check-selected"}`}
            onClick={onCheckEveryClosingCandidate}
          >
            {closingChecked && <p>✓</p>}
          </div>
          <div
            className={`close-button ${!closingChecked && "button-disabled"}`}
            onClick={toggleShowCloseForm}
          >
            ყველას დახურვა
          </div>
        </div>
      )}
      {tab === "unapproved" && attribute === "communal" && (
        <div className="approve-checkbox">
          <div
            className={`checkbox ${checked && "check-selected"}`}
            onClick={onCheckEvery}
          >
            {checked && <p>✓</p>}
          </div>
          <div
            className={`close-button ${!checked && "button-disabled"}`}
            onClick={confirm}
          >
            ყველას დადასტურება
          </div>
        </div>
      )}
      <div className="owners-list-table">
        <InvoiceHeader tab={tab} attribute={attribute} />
        <div className="request-list" id="invoice-list">
          {(isLoading || requestsLoading) &&
            skeletons.map((_, i) => (
              <Skeleton key={i} height={100} className="card-skeleton" />
            ))}
          {!isLoading &&
            attribute !== "fees" &&
            items.map((invoice, i) => (
              <InvoiceCard
                key={i}
                invoice={invoice}
                onCheck={onCheck}
                attribute={attribute}
                tab={tab}
                onUncheck={onUncheck}
                checkFlag={checkFlag}
                checked={checked}
                closingChecked={closingChecked}
              />
            ))}
          {!isLoading &&
            attribute === "fees" &&
            requests.map((invoice, i) => (
              <InvoiceCard
                key={i}
                invoice={invoice}
                onCheck={onCheck}
                attribute={attribute}
                onUncheck={onUncheck}
                checkFlag={checkFlag}
                checked={checked}
              />
            ))}

          {(updating || requestsUpdating) && (
            <Skeleton height={100} className="card-skeleton" />
          )}
          {lastFetch.length === pageSize &&
            !updating &&
            !isLoading &&
            attribute !== "fees" && (
              <h2 className="show-more" onClick={onShowMore}>
                მაჩვენე მეტი ...
              </h2>
            )}
          {requestsLastFetch.length === pageSize &&
            !requestsUpdating &&
            !requestsLoading &&
            attribute === "fees" && (
              <h2 className="show-more" onClick={onShowMoreRequests}>
                მაჩვენე მეტი ...
              </h2>
            )}
        </div>
      </div>
      {(items.length !== 0 || requests.length !== 0) && (
        <DownloadButton
          link={
            attribute === "fees"
              ? "/admin/service/requests/filter/download"
              : "/admin/invoices/filter/download"
          }
          params={
            attribute === "fees"
              ? { load_from: 0, status: "active,processing,done" }
              : filteredObject(params)
          }
          only_payments={attribute === "fees" ? true : undefined}
        />
      )}
      <Modal
        isOpen={showConfirm}
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        className={"modal-content"}
        shouldCloseOnOverlayClick={true}
      >
        <div className="confirm-modal">
          <p>ნამდვილად გსურთ ყველა ინვოისის</p>
          <p style={{ marginTop: "-30px" }}>ერთიანი დადასტურება?</p>
          <div className="confirm-button" onClick={onApproveEvery}>
            დიახ
          </div>
          <img
            src={close}
            alt="close"
            className="close-icon"
            width={15}
            onClick={toggleShowConfirm}
          />
        </div>
      </Modal>
    </div>
  );
}

export default InvoiceTable;
