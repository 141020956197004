import React, { useState } from "react";
import "./Services.css";
import Navbar from "../../components/Navbar/Navbar.js";
import {
  ServicesControlbar,
  OwnersTable,
  OwnerForm,
  OwnershipFilter,
  ConstructorTable,
} from "../../components/tables/";
import useToggle from "../../hooks/useToggle";
import { servicesAttributes } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../state";
import { bindActionCreators } from "redux";
import LeasesFilter from "../../components/tables/services-tables/filters/LeasesFilter";
import VisitorsFilter from "../../components/tables/services-tables/filters/VisitorsFilter";
import RequestsTable from "../../components/tables/services-tables/requests/RequestsTable";
import RequestFilter from "../../components/tables/services-tables/filters/RequestFilter";
import Ratings from "../../components/tables/services-tables/controlbars/Requests/Ratings.js";
import TaxReliefTable from "../../components/tables/services-tables/constructor/tax-relief/TaxReliefTable.js";
import LeaseForm from "../../components/tables/services-forms/Leasing/LeaseForm.js";
import { useRental } from "../../hooks/useRental.js";
import API from "../../libs/API.js";

export default function Services({ attribute, tab }) {
  const [selectedAttribute, setSelectedAttribute] = useState(0);
  const [selectedUsersTable, setSelectedUsersTable] = useState(0);
  const [selectedRequestsTable, setSelectedRequestsTable] = useState(0);
  const [selectedConstructorTable, setSelectedConstructorTable] = useState(
    tab === "fees" ? 0 : 1
  );
  const [showForm, toggleShowForm] = useToggle(false);
  const [editing, toggleEditing] = useToggle(false);
  const [showFilter, toggleShowFilter] = useToggle(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [apartmentForRental, setApartmentForRental] = useState(null);
  const [ownersForRental, setOwnersForRental] = useState(null);

  const [cardForRental, setCardForRental] = useState(null);

  // rental
  const [showLeaseForm, toggleShowLeaseForm] = useToggle();

  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const { getProperty } = bindActionCreators(actionCreators, dispatch);

  const rentalQuery = useRental(
    apartmentForRental ? apartmentForRental.id : null
  );

  const resetTables = () => {
    setSelectedConstructorTable(0);
    setSelectedRequestsTable(0);
    setSelectedUsersTable(0);
  };

  const selectAttribute = (index) => {
    setSelectedAttribute(index);

    setSelectedUsersTable(0);
    setSelectedConstructorTable(0);
    setSelectedRequestsTable(0);
  };

  const onEdit = (flag) => {
    toggleEditing(flag);
    toggleShowFilter(false);
    toggleShowForm(false);
  };

  const onShowFilter = (flag) => {
    toggleShowFilter(flag);
    toggleShowForm(false);
    toggleEditing(false);
  };

  const onShowForm = (flag) => {
    toggleShowForm(flag);
    toggleShowFilter(false);
    toggleEditing(false);
  };

  const onCardClick = (card) => {
    if (tab === "lease") {
      API.get(user, `/admin/ownership_card/${card.id}`, null, (res) => {
        setCardForRental(res.ownership_card);
        toggleShowLeaseForm();
      });
      return;
    }
    getProperty(user, card.id);
    toggleEditing(true);
  };

  return (
    <div className="services-container">
      <Navbar
        title="სერვისების მართვის მოდული"
        attributes={servicesAttributes}
        setSelected={selectAttribute}
        selected={selectedAttribute}
        resetTables={resetTables}
        module={"services"}
        attribute={attribute}
        tab={tab}
        toggleShowFilter={toggleShowFilter}
      />
      <div className="table-container">
        <ServicesControlbar
          selected={selectedAttribute}
          selectedUsersTable={selectedUsersTable}
          setSelectedUsersTable={setSelectedUsersTable}
          selectedRequestsTable={selectedRequestsTable}
          setSelectedRequestsTable={setSelectedRequestsTable}
          selectedConstructorTable={selectedConstructorTable}
          setSelectedConstructorTable={setSelectedConstructorTable}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          toggleShowForm={onShowForm}
          toggleEditing={onEdit}
          attribute={attribute}
          tab={tab}
          editing={editing}
          showForm={showForm}
          showFilter={showFilter}
          toggleShowFilter={onShowFilter}
        />
        {attribute === "users" && (
          <>
            {showFilter && tab === "private" && !editing && <OwnershipFilter />}
            {showFilter && tab === "lease" && !editing && <LeasesFilter />}
            {showFilter && tab === "visitor" && !editing && <VisitorsFilter />}
          </>
        )}
        {attribute === "requests" && (
          <>
            {showFilter &&
              (selectedRequestsTable === 0 ||
                selectedRequestsTable === 1 ||
                selectedRequestsTable === 2) &&
              !editing &&
              tab !== "rates" && (
                <RequestFilter
                  selectedTable={selectedRequestsTable}
                  tab={tab}
                  attribute={attribute}
                  globalCategory={selectedCategory}
                  setGlobalCategory={setSelectedCategory}
                />
              )}
          </>
        )}

        {!showLeaseForm &&
          !showForm &&
          attribute === "users" &&
          !editing &&
          !editing && (
            <OwnersTable
              editing={editing}
              toggleEditing={onEdit}
              onCardClick={onCardClick}
              tab={tab}
              toggleShowForm={onShowForm}
            />
          )}

        {((showForm &&
          attribute === "users" &&
          (selectedUsersTable === 0 || selectedUsersTable === 3)) ||
          (editing && attribute === "users")) && (
          <OwnerForm
            toggleShowForm={onShowForm}
            setSelectedTable={setSelectedUsersTable}
            toggleEditing={onEdit}
            toggleShowLeaseForm={toggleShowLeaseForm}
            showLeaseForm={showLeaseForm}
            setApartmentForRental={setApartmentForRental}
            setOwnersForRental={setOwnersForRental}
            editing={editing}
            tab={tab}
          />
        )}

        {!rentalQuery.isFetching && showLeaseForm && tab === "lease" && (
          <LeaseForm
            toggleShowLeaseForm={toggleShowLeaseForm}
            rentalQuery={rentalQuery}
            apartmentForRental={apartmentForRental}
            setApartmentForRental={setApartmentForRental}
            card={cardForRental ? cardForRental : rentalQuery.data}
            ownersForRental={ownersForRental}
            setCard={setCardForRental}
          />
        )}

        {attribute === "constructor" &&
          selectedConstructorTable !== -1 &&
          tab !== "relief-fees" && (
            <ConstructorTable
              selectedTable={selectedConstructorTable}
              setSelectedTable={setSelectedConstructorTable}
              tab={tab}
            />
          )}
        {tab === "relief-fees" && attribute === "constructor" && (
          <TaxReliefTable tab={tab} />
        )}
        {attribute === "requests" && tab !== "rates" && (
          <RequestsTable selectedCategory={selectedCategory} tab={tab} />
        )}
      </div>
      {tab === "rates" && attribute === "requests" && <Ratings />}
    </div>
  );
}
