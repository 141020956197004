import React from "react";
import { useSelector } from "react-redux";
import search from "../../../../assets/search.png";
import filled_search from "../../../../assets/filled_search.png";
import Done from "../../../Icons/Done";

function ControlbarForServiceFees({ showFilter, toggleShowFilter }) {
  const { total_cost_sum } = useSelector((state) => state.requests);

  return (
    <>
      <div className="controblar-left payments-controlbar">
        <img
          src={!showFilter ? search : filled_search}
          alt="search"
          style={{ cursor: "pointer" }}
          onClick={toggleShowFilter}
        ></img>
        <div className="total_cost_text">
          <span style={{ fontSize: "14px" }}>სულ</span>{" "}
          {total_cost_sum?.GEL.toFixed(2)} GEL |{" "}
          {total_cost_sum?.USD.toFixed(2)} USD |{" "}
          {total_cost_sum?.EUR.toFixed(2)} EUR
        </div>
      </div>
      <div className="controlbar-right">
        <div className={"selector selector-selected"}>
          <Done fill={"#DAA161"} />
          <p>გადახდილი</p>
        </div>
      </div>
    </>
  );
}

export default ControlbarForServiceFees;
