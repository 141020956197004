import React from "react";
import add from "../../../assets/add.png";
import filled_add from "../../../assets/filled_add.png";
import search from "../../../assets/search.png";
import filled_search from "../../../assets/filled_search.png";
import "../services-tables/index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearNotifications } from "../../../state/action-creators/notificationActions";
import ReceivedIcon from "../../Icons/ReceivedIcon";
import SentIcon from "../../Icons/SentIcon";
import Processing from "../../Icons/Processing";
import { useQueryClient } from "@tanstack/react-query";
import { useUnseenCounts } from "../../../hooks/useUnseenCounts";

const NOTIFICATIONS_URL = "/dashboard/notifications/messages";

const orange = "#DAA161";
const black = "#333333";

function NofiticationsControlbar({
  tab,
  toggleShowForm,
  showForm,
  toggleShowFilter,
  showFilter,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data, isFetching, isLoading } = useUnseenCounts();

  const onBarClick = (base, to) => {
    if (tab === to) {
      toggleShowForm(false);
      navigate(`${base}/${to}`);
      toggleShowFilter(false);
      return;
    }
    dispatch(clearNotifications());
    toggleShowFilter(false);
    toggleShowForm(false);
    queryClient.invalidateQueries(["unseen_comments_count"]);
    navigate(`${base}/${to}`);
  };

  const onSearchClick = () => {
    toggleShowFilter();
  };

  const recievedClick = () => onBarClick(NOTIFICATIONS_URL, "received");
  const sentClick = () => onBarClick(NOTIFICATIONS_URL, "sent");
  const delayedClick = () => onBarClick(NOTIFICATIONS_URL, "delayed");

  const sentCount = data?.unseen_comments_count?.sent;
  const receivedCount = data?.unseen_comments_count?.received;
  const postsCount = data?.unseen_posts_count;

  return (
    <div className="controlbar-container">
      <div className="controlbar-left">
        {tab !== "draft" && (
          <img
            src={showFilter ? filled_search : search}
            alt="search"
            onClick={onSearchClick}
          ></img>
        )}
        {tab === "sent" && (
          <img
            src={showForm ? filled_add : add}
            alt="add"
            onClick={toggleShowForm}
          ></img>
        )}
      </div>
      <div className="controlbar-right">
        <div
          className={
            tab === "received" ? "selector selector-selected" : "selector"
          }
          onClick={recievedClick}
        >
          <ReceivedIcon fill={tab === "received" ? orange : black} />
          <p>მიღებული</p>
          {data?.unseen_comments_count !== undefined &&
            receivedCount + postsCount !== 0 && (
              <div className="controlbar-notification-pointer">
                {receivedCount + postsCount}
              </div>
            )}
        </div>
        <div
          className={tab === "sent" ? "selector selector-selected" : "selector"}
          onClick={sentClick}
        >
          <SentIcon fill={tab === "sent" ? orange : black} />
          {data?.unseen_comments_count !== undefined && sentCount !== 0 && (
            <div className="controlbar-notification-pointer">{sentCount}</div>
          )}
          <p>გაგზავნილი</p>
        </div>
        <div
          className={
            tab === "delayed" ? "selector selector-selected" : "selector"
          }
          onClick={delayedClick}
        >
          <Processing fill={tab === "delayed" ? orange : black} />
          <p>დაყოვნებული</p>
        </div>
      </div>
    </div>
  );
}

export default NofiticationsControlbar;
