import axios from "axios";
import { store } from "../state/store";
import { resetUser, updateUser } from "../state/action-creators/userActions";
import { setMessage } from "../state/action-creators";

//export const server = 'https://api.kdr.ge';
//export const server = 'http://10.99.99.2:5001';
export const server = process.env.REACT_APP_SERVER_URL;

const onFinished = (response, on_finished) => {
  if (!response.data) {
    return;
  }

  if (typeof on_finished === "function") {
    on_finished(response.data);
  }
};

const onBlobFinished = (response, on_finished) => {
  if (typeof on_finished === "function") {
    on_finished(response);
  }
};

const onError = (user, url, data, on_finished, error, on_error, callAfter) => {
  const msg = error.response?.data?.message?.ge || error.message;

  if (
    (error.response?.status === 400 || error.response?.status === 500) &&
    msg !== "პაროლი არასწორია"
  ) {
    store.dispatch(setMessage(msg));
    console.log(error);
  }

  // token refresh
  if (user && error.response?.status === 401) {
    if (error.response.data.msg === "Token has expired") {
      axios
        .post(`${server}/token_refresh`, null, {
          headers: { Authorization: "Bearer " + user.refresh_token },
        })
        .then((res) => {
          store.dispatch(updateUser("access_token", res.data.access_token));
          callAfter(
            { ...user, access_token: res.data.access_token },
            url,
            data,
            on_finished,
            on_error
          );
        })
        .catch((err) => store.dispatch(resetUser()));
      return;
    }
  }

  if (error.response?.status === 422) {
    store.dispatch(resetUser());
  }

  if (on_error) {
    on_error(error);
  }
};

export const getTypes = (onfinish) => {
  fetch(`${server}/info/types`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((res) => res.json())
    .then((data) => onfinish(data))
    .catch((err) => console.log(err));
};

const Get = async (user, url, data, on_finished, on_error) => {
  return await axios
    .get(`${server}${url}`, {
      headers: user ? { Authorization: "Bearer " + user.access_token } : {},
      params: data,
    })
    .then((response) => {
      onFinished(response, on_finished);
      return response.data;
    })
    .catch((error) => {
      onError(user, url, data, on_finished, error, on_error, Get);
      console.log(error);
      return error.response.data;
    });
};

const GetBlob = (user, url, data, on_finished, on_error, only_payments) => {
  const params = new URLSearchParams(
    only_payments !== undefined
      ? { ...data, only_payments: only_payments }
      : data
  );

  fetch(`${server}${url}?${params}`, {
    method: "GET",
    headers: user
      ? {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.access_token,
        }
      : {},
  })
    .then((response) => onBlobFinished(response, on_finished))
    .catch((error) =>
      onError(user, url, data, on_finished, error, on_error, GetBlob)
    );
};

const Post = async (user, url, data, on_finished, on_error) => {
  return await axios
    .post(
      `${server}${url}`,
      data,
      user ? { headers: { Authorization: "Bearer " + user.access_token } } : {}
    )
    .then((response) => {
      onFinished(response, on_finished);
      return response.data;
    })
    .catch((error) => {
      onError(user, url, data, on_finished, error, on_error, Post);
      return error.response.data;
    });
};

const Put = async (user, url, data, on_finished, on_error) => {
  return await axios
    .put(
      `${server}${url}`,
      data,
      user ? { headers: { Authorization: "Bearer " + user.access_token } } : {}
    )
    .then((response) => {
      onFinished(response, on_finished);
      return response.data;
    })
    .catch((error) => {
      onError(user, url, data, on_finished, error, on_error, Put);
      return error.response.data;
    });
};

const Delete = async (user, url, data, on_finished, on_error) => {
  return await axios
    .delete(`${server}${url}`, {
      headers: user ? { Authorization: "Bearer " + user.access_token } : {},
      data: data,
    })
    .then((response) => {
      onFinished(response, on_finished);
      return response.data;
    })
    .catch((error) =>
      onError(user, url, data, on_finished, error, on_error, Delete)
    );
};

class API {
  static async get(user, url, data, on_finished, on_error) {
    return await Get(user, url, data, on_finished, on_error);
  }

  static getBlob(user, url, data, on_finished, on_error, only_payments) {
    GetBlob(user, url, data, on_finished, on_error, only_payments);
  }

  static async post(user, url, data, on_finished, on_error) {
    return await Post(user, url, data, on_finished, on_error);
  }

  static async delete(user, url, data, on_finished, on_error) {
    return await Delete(user, url, data, on_finished, on_error);
  }

  static async put(user, url, data, on_finished, on_error) {
    return await Put(user, url, data, on_finished, on_error);
  }
}

export default API;
