import React, { useState } from "react";
import AddresateFIlter from "../../notifications/form/AddresateFIlter";
import AddresateList from "../../notifications/form/AddresateList";
import InvoiceDataForm from "./InvoiceDataForm";
import { useSelector } from "react-redux";
import useInvoiceMutation from "../../../../hooks/useInvoiceMutation";
import moment from "moment";

function FeeForm({ toggleShowFeeForm }) {
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");

  const [date, setDate] = useState("");

  const [currency, setCurrency] = useState("GEL");

  const { items: addresates } = useSelector((state) => state.addresates);

  const canSend = description !== "" && cost !== "" && date !== "";

  const mutation = useInvoiceMutation();

  const onSend = () => {
    if (!canSend) {
      return;
    }

    const now = new Date();
    const offset = now.getTimezoneOffset() / 60;

    const dateUtc = date
      ? new Date(
          moment(date)
            .set("s", 0)
            .set("millisecond", 0)
            .add("h", Number(offset))
            .toISOString()
        )
      : null;

    let year = dateUtc.getFullYear();
    let month = String(dateUtc.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
    let day = String(dateUtc.getDate()).padStart(2, "0");
    // let hour = String(dateUtc.getHours()).padStart(2, "0");
    // let minute = String(dateUtc.getMinutes()).padStart(2, "0");

    // Combine the parts into the desired format
    let formattedDate = `${year}-${month}-${day}`;

    const body = {
      cost: Number(cost),
      currency: currency,
      deadline_datetime: formattedDate,
      description: description,
      user_ids: addresates.filter((a) => a.isSelected).map((a) => a.id),
    };

    mutation.mutate({ body: body, onFinished: () => toggleShowFeeForm() });
  };

  return (
    <div className="table form-table">
      <div className="notifications-form">
        <div className="notifications-form-left">
          <AddresateFIlter />
          <AddresateList />
        </div>
        <div className="notifications-form-right">
          <InvoiceDataForm
            description={description}
            setDescription={setDescription}
            cost={cost}
            setCost={setCost}
            date={date}
            setDate={setDate}
            currency={currency}
            setCurrency={setCurrency}
          />
        </div>
      </div>
      <div className="form-actions">
        {
          <div
            className={`form-btn-primary ${!canSend && "add-button-disabled"}`}
            onClick={onSend}
          >
            {mutation.isPending ? <div className="loader"></div> : "გაგზავნა"}
          </div>
        }
      </div>
    </div>
  );
}

export default FeeForm;
