import React from "react";
import User from "./User";
import { phoneToString } from "../../../../libs/Functions";

export default function Users({ users, organizations }) {
  return (
    <div className="rent-apartment-container">
      <label>მეპატრონე</label>
      <div className="rent-users">
        {users?.map((user) => (
          <User key={user.pers_id} self={user} />
        ))}
      </div>
      <div className="rent-users">
        {organizations?.map((org, i) => (
          <span key={i} className="rent-user">{`${org.name} | ${
            org.identity_num
          } | ${org.representative.name} ${org.representative.surname} | ${
            org.representative.pers_id
          } | ${phoneToString(
            org.representative.country,
            org.representative.phone
          )}`}</span>
        ))}
      </div>
    </div>
  );
}
