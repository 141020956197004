import API from "../../libs/API.js";
import { store } from "../../state/store.js";
import {
  CREATE_NOTIFICATION,
  GET_ADDRESATES,
  PUT_NOTIFICATION,
  REMOVE_POPUP_MESSAGE,
  SELECT_ALL_ADDRESATES,
  SET_ADDRESATES_LOADING,
  SET_ADDRESATES_PARAMS,
  SET_POPUP_MESSAGE,
  TOGGLE_ADDRESATE,
  UNSELECT_ADDRESATES,
} from "./types";

const user = store.getState().user.user;

export const getAddresates = (params) => {
  return (dispatch) => {
    dispatch({ type: SET_ADDRESATES_LOADING, payload: true });
    dispatch({ type: SET_ADDRESATES_PARAMS, payload: params });
    API.get(user, "/admin/users/filter", params, (res) =>
      dispatch({ type: GET_ADDRESATES, payload: res })
    );
  };
};

export const selectAllAddresates = () => {
  return (dispatch) => {
    dispatch({ type: SELECT_ALL_ADDRESATES });
  };
};

export const unselectAddresates = () => {
  return (dispatch) => {
    dispatch({ type: UNSELECT_ADDRESATES });
  };
};

export const toggleAddresate = (addresate_id) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_ADDRESATE, payload: addresate_id });
  };
};

export const createNotification = (data) => {
  return (dispatch) => {
    API.post(
      user,
      "/admin/posts",
      data,
      (res) => {
        dispatch({ type: CREATE_NOTIFICATION, payload: data });
        dispatch({
          type: SET_POPUP_MESSAGE,
          payload: res.message ? res.message.ge : "წარმატებით გაიგზავნა",
        });
        setTimeout(() => dispatch({ type: REMOVE_POPUP_MESSAGE }), 2000);
      },
      (err) => {}
    );
  };
};

export const putNotification = (id, data) => {
  return (dispatch) => {
    API.put(
      user,
      "/admin/posts/" + String(id),
      data,
      (res) => {
        dispatch({ type: PUT_NOTIFICATION, payload: data });
        dispatch({
          type: SET_POPUP_MESSAGE,
          payload: res.message ? res.message.ge : "წარმატებით შეინახა",
        });
        setTimeout(() => dispatch({ type: REMOVE_POPUP_MESSAGE }), 2000);
      },
      (err) => {}
    );
  };
};
