import { ADD_CATEGORY, CATEGORIES_ERROR, CLEAR_CATEGORIES, GET_CATEGORIES, SET_CATEGORIES_LOADING, SET_CATEGORY_UPDATE_LOADING, TOGGLE_CATEGORY_VISIBILITY, UPDATE_CATEGORY } from "../action-creators/types";

const initialState = {
    items: [],
    isLoading: false,
    isUpdating: false,
    isfree: false,
    isError: false,
    message: '',
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                items: action.payload,
                isLoading: false,
                isfee: action.isfee,
            }
        case SET_CATEGORIES_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case CATEGORIES_ERROR:
            const err = action.payload;
            return {
                ...state,
                isError: true,
                message: err.message,
            }
        case CLEAR_CATEGORIES:
            return {
                ...state,
                items: [],
            }
        case TOGGLE_CATEGORY_VISIBILITY:
            const id = action.payload;
            return {
                ...state,
                items: state.items.map((item) => 
                item.id===id?{...item, is_visible: !item.is_visible}:item)
            }
        case ADD_CATEGORY:
            return {
                ...state,
                items: [...state.items, action.payload], 
            }
        case UPDATE_CATEGORY:
            const cat = action.payload;
            return {
                ...state,
                items: state.items.map((item) => item.id===cat.id ? cat:item),
                isUpdating: false,
            }
        case SET_CATEGORY_UPDATE_LOADING:
            return {
                ...state, 
                isUpdating: action.payload,
            }
        default:
            return state;
    }
}

export default reducer;