import { useQuery } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";

const user = store.getState().user.user;

export default function useWaivedFees(apartment_id) {
  return useQuery({
    queryKey: ["waived_fees", apartment_id],
    queryFn: () =>
      API.get(user, `/admin/apartment/${apartment_id}/waived_fees`),
    enabled: !!apartment_id,
  });
}
