import React from "react";
import Modal from "react-modal";
import VerificationInput from "react-verification-input";
import "./verification.css";

function VerificationModal({ isOpen, toggleIsOpen, onSave, onChange }) {
  const save = () => {
    onSave();
    toggleIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"modal-overlay"}
      ariaHideApp={false}
      className={"modal-content"}
      shouldCloseOnOverlayClick={true}
      onRequestClose={toggleIsOpen}
    >
      <div className="sms-verification">
        <label>SMS ვერიფიკაცია</label>
        <VerificationInput
          classNames={{
            character: "verification-input-character",
            characterSelected: "verification-input-character-selected",
            characterInactive: "verification-input-character-inactive",
            container: "verification-input-container",
          }}
          length={4}
          onChange={onChange}
        />
        <p>
          ვერიფიკაციის კოდი გაგზავნილია თქვენს მიერ მითითებულ დამქირავებლის
          ტელეფონის ნომერზე, მოიპოვეთ კოდი და შეიყვანეთ შესაბამის გრაფებში!{" "}
        </p>
        <div
          className="confirm-button"
          style={{ marginBottom: "20px" }}
          onClick={save}
        >
          შენახვა
        </div>
      </div>
    </Modal>
  );
}

export default VerificationModal;
