import { useState } from "react";
import { useDispatch } from "react-redux";
import { DateInput } from "../services-tables/filters/LeasesFilter";
import { dateJSON } from "../../../libs/Functions";
import useToggle from "../../../hooks/useToggle";
import close from "../../../assets/close.png";
import pdf_icon from "../../../assets/pdf_icon.png";
import Modal from "react-modal";
import {
  approveInvoices,
  closeInvoice,
} from "../../../state/action-creators/invoiceActions";
import moment from "moment";
import DownloadButton from "../../DownloadButton/DownloadButton";

const date = (utc) => {
  const d = new Date(moment(new Date(utc)).add(4, "h"));
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const hours = d.getHours().toString().padStart(2, "0");
  const minutes = d.getMinutes().toString().padStart(2, "0");
  return `${day}.${month}.${d.getFullYear()}  ${hours}:${minutes}`;
};

export function FeeType({ invoice }) {
  const { service_fees } = invoice;
  return (
    <div>
      {service_fees?.map((fee, i) => (
        <div key={i} className="invoice-card-text">
          <p>
            <b>{fee.fee.category.name.ge}</b>
          </p>
          <p>
            {fee.fee.category.name.ge === "ბიზნეს ცენტრის მომსახურება" ||
            fee.fee.category.name.ge === "აპარტამენტის მომსახურება" ? (
              <i>ფართი კვ.მ:</i>
            ) : (
              <i>რაოდენობა:</i>
            )}{" "}
            {fee.quantity} | <i>ტარიფი {fee.currency.en}</i>: {fee.tariff} |{" "}
            <i>გადასახადი {fee.currency.en}</i>: {fee?.cost.toFixed(2)}
          </p>
        </div>
      ))}
    </div>
  );
}

export function CommunalFeeType({ invoice, editing, data, setData }) {
  const { communal_fees } = invoice;

  const onPrevChange = (fee, value) => {
    setData(
      data.map((e) =>
        e.id === fee.id
          ? {
              ...e,
              previous_value: value.toString(),
            }
          : e
      )
    );
  };

  const onPresentChange = (fee, value) => {
    setData(
      data.map((e) =>
        e.id === fee.id
          ? {
              ...e,
              present_value: value.toString(),
            }
          : e
      )
    );
  };

  return (
    <div>
      {communal_fees?.map((fee, i) => (
        <div key={i} className="invoice-card-text">
          <p>
            <b>{fee.fee.category.name.ge}</b>
          </p>
          <p>
            {fee.fee.category.id !== 13 && <i>წინა ჩვენება: </i>}
            {fee.fee.category.id !== 13 && (
              <input
                type="number"
                value={
                  data?.filter((e) => e.id === fee.id)[0]?.previous_value ||
                  "0.00"
                }
                className="card-input"
                disabled={!editing}
                onChange={(e) => onPrevChange(fee, e.target.value.toString())}
              />
            )}
            {fee.fee.category.id !== 13 && <i>ბოლო ჩვენება: </i>}
            {fee.fee.category.id !== 13 && (
              <input
                type="number"
                value={
                  data?.filter((e) => e.id === fee.id)[0]?.present_value ||
                  "0.00"
                }
                className="card-input"
                disabled={!editing}
                onChange={(e) => onPresentChange(fee, e.target.value)}
              />
            )}
            {fee.fee.category.id !== 13 ? (
              <>
                <i>ხარჯი: ${fee.usage}</i>
              </>
            ) : (
              <>
                <i>ფართი კვ.მ. :</i> {fee.usage}
              </>
            )}{" "}
            | <i>ტარიფი: </i> {fee.currency.en}: {fee.tariff} |{" "}
            <i>გადასახადი {fee.currency.en}</i>: {fee.cost.toFixed(2)}
          </p>
        </div>
      ))}
    </div>
  );
}

export function RequestData({ request }) {
  return (
    <div>
      <div className="invoice-card-text">
        <p>მოთხოვნის ნომერი: {request.id}</p>
      </div>
      <div className="invoice-card-text">
        <p>მოთხოვნის თარიღი: {date(request.requesting_datetime)}</p>
      </div>
    </div>
  );
}

export function TransactionData({ invoice, attribute, tab }) {
  const { payment } = invoice;
  const [comment, setComment] = useState(
    invoice.comment ? invoice.comment : ""
  );
  const [payment_datetime, set_payment_datetime] = useState("");
  const [showConfirm, toggleShowConfirm] = useToggle();
  const dispatch = useDispatch();

  const onClose = () => {
    if (comment === "" || payment_datetime === "") return;
    setComment("");
    set_payment_datetime("");
    dispatch(
      closeInvoice({
        invoices_closing_data: [
          {
            invoice_id: invoice.id,
            comment,
            payment_datetime: dateJSON(payment_datetime).substring(
              0,
              dateJSON(payment_datetime).length - 1
            ),
          },
        ],
      })
    );
  };

  const onApprove = () => {
    dispatch(approveInvoices([{ id: invoice.id, comment }]));
    setComment(invoice.comment ? invoice.comment : "");
    set_payment_datetime(
      invoice.payment_datetime ? invoice.payment_datetime : ""
    );
    toggleShowConfirm(false);
  };

  return (
    <div>
      <div className="invoice-card-text">
        {attribute !== "fees" && (
          <p>
            <b>ჯამური თანხა</b> USD: {invoice?.total_cost_usd?.toFixed(2)} |
            GEL: {invoice?.total_cost_gel?.toFixed(2)} | EUR:{" "}
            {invoice?.total_cost_eur?.toFixed(2)}
          </p>
        )}
        {attribute === "fees" && (
          <p>
            <b>ჯამური თანხა</b> {invoice.currency.en}: {invoice.total_cost}
          </p>
        )}
      </div>
      {payment && (
        <div>
          <div className="invoice-card-text">
            <p>
              <b>ტრანზაქციის ნომერი</b>: {payment.transaction_id}
            </p>
          </div>
          <div className="invoice-card-text">
            <p>
              <b>ტრანზაქციის თარიღი</b>: {date(payment.payment_datetime)}
            </p>
          </div>
          <div className="invoice-card-text">
            <p>
              <b>გადამხდელი</b>: {payment.payer_fullname}
            </p>
          </div>
        </div>
      )}
      {invoice.status.en !== "paid" &&
        attribute !== "fees" &&
        invoice.status.en !== "cancelled" && (
          <div className="comment-form">
            <div
              className="close-transaction-form"
              style={{ marginTop: "30px" }}
            >
              {(tab === "unpaid" || tab === "overdue") && (
                <DateInput
                  placeholder="გადახდის თარიღი"
                  name="close_datetime"
                  value={payment_datetime}
                  onChange={(e) => set_payment_datetime(e.target.value)}
                />
              )}
              <input
                className="transaction-comment-input"
                placeholder="კომენტარი"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </div>
            {invoice.status.ge !== "დასადასტურებელი" && (
              <div className="pdf-and-close">
                <div className="pdf-download">
                  <DownloadButton
                    link={`/admin/invoice/${invoice.id}/pdf`}
                    icon={pdf_icon}
                    shouldDecode={false}
                  />
                </div>
                <div
                  className={`close-button ${
                    (comment === "" || payment_datetime === "") &&
                    "button-disabled"
                  }`}
                  onClick={onClose}
                >
                  დახურვა
                </div>
              </div>
            )}
            {invoice.status.ge === "დასადასტურებელი" && (
              <div className={`close-button`} onClick={toggleShowConfirm}>
                დადასტურება
              </div>
            )}
          </div>
        )}
      {invoice.status.en === "paid" && (
        <div style={{ marginTop: "30px" }}>
          <div className="invoice-card-text">
            <p>{invoice.comment}</p>
          </div>
          <div className="invoice-card-text">
            <p>გადახდის თარიღი: {date(invoice?.payment_datetime)}</p>
          </div>
          <div className="pdf-download">
            <DownloadButton
              link={`/admin/invoice/${invoice.id}/pdf`}
              icon={pdf_icon}
              shouldDecode={false}
            />
          </div>
        </div>
      )}
      {invoice.status.en === "cancelled" && (
        <div style={{ marginTop: "30px" }}>
          <div className="invoice-card-text">
            <p>{invoice.cancellation_reason}</p>
          </div>
          <div className="invoice-card-text">
            <p>გაუქმების თარიღი: {date(invoice?.cancellation_datetime)}</p>
          </div>
        </div>
      )}
      <Modal
        isOpen={showConfirm}
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        className={"modal-content"}
        shouldCloseOnOverlayClick={true}
      >
        <div className="confirm-modal">
          <p>ნამდვილად გსურთ ინვოისის</p>
          <p style={{ marginTop: "-30px" }}>დადასტურება?</p>
          <div className="confirm-button" onClick={onApprove}>
            დიახ
          </div>
          <img
            src={close}
            alt="close"
            className="close-icon"
            width={15}
            onClick={toggleShowConfirm}
          />
        </div>
      </Modal>
    </div>
  );
}

export function UserData({ invoice }) {
  //const { users, organizations } = invoice.ownership_card;
  const users =
    invoice.invoice_type === "additional"
      ? invoice.addressees
      : invoice.ownership_card?.users;
  const organizations = invoice.ownership_card?.organizations ?? [];
  const apartments = invoice.ownership_card?.apartments;
  const { apartment } = invoice;
  return (
    <div>
      {users.map((user, i) => (
        <div key={i}>
          <div className="invoice-card-text">
            <p>
              <b>სახელი და გვარი</b>: {user.name + " " + user.surname}
            </p>

            <p>
              <b>პირადი ნომერი</b>: {user.pers_id}
            </p>
          </div>
        </div>
      ))}
      {organizations.map((org, i) => (
        <div key={i}>
          <div className="invoice-card-text">
            <p>
              <b>ორგანიზაციის სახელი</b>: {org.name}
            </p>
            <p>
              <b>ორგანიზაციის ნომერი</b>: {org.identity_num}
            </p>
            <p>
              <b>წარმომადგენელი</b>:{" "}
              {org.representative.name + " " + org.representative.surname}
            </p>
            <p>
              <b>წარმომედგენლის პირადი ნომერი</b>: {org.representative.pers_id}
            </p>
          </div>
        </div>
      ))}
      {apartment && invoice.invoice_type !== "parking" && (
        <>
          <div className="invoice-card-text">
            <p>
              <b>კომპლექსის ტიპი</b>: {apartment.complex_type.ge}
            </p>
            <p>
              <b>სართული</b>: {apartment.floor}
            </p>
            <p>
              <b>ნომერი</b>: {apartment.number}
            </p>
          </div>
        </>
      )}
      {apartments && !apartment && invoice.invoice_type !== "parking" && (
        <>
          {apartments.map((a, i) => (
            <div className="invoice-card-text" key={i}>
              <p>
                <b>კომპლექსის ტიპი</b>: {a.complex_type.ge}
              </p>
              <p>
                <b>სართული</b>: {a.floor}
              </p>
              <p>
                <b>ნომერი</b>: {a.number}
              </p>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export function InvoiceData({ invoice }) {
  let categoryName = "მომსახურების გადასახადი";

  switch (invoice.invoice_type) {
    case "parking":
      categoryName = "პარკინგის მომსახურების გადასახადი";
      break;
    case "communal":
      categoryName = "კომუნალური გადასახადი";
      break;
    case "apartment":
      categoryName = "ფართის მომსახურების გადასახადი";
      break;
    default:
      categoryName = "მომსახურების გადასახადი";
      break;
  }
  return (
    <div>
      <div className="invoice-card-text">
        <p>
          <b>კატეგორია</b>: {categoryName}
        </p>
      </div>
      <div className="invoice-card-text">
        <p>
          <b>ინვოისის ნომერი</b>: {invoice.id}
        </p>
      </div>
      <div className="invoice-card-text">
        <p>
          <b>ინვოისის თარიღი</b>: {date(invoice.generation_datetime)}
        </p>
      </div>
      <div className="invoice-card-text">
        <p>
          <b>გადახდის ბოლო ვადა</b>: {date(invoice.deadline_datetime)}
        </p>
      </div>
      <div className="invoice-card-text">
        <p style={{ color: "var(--errorColor)" }}>
          სტატუსი: {invoice.status.ge}
        </p>
      </div>
    </div>
  );
}
