import { countrys } from "../constants";
import moment from "moment";

export function getTimezoneOffsetString() {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const sign = offset > 0 ? "-" : "+";
  const hours = Math.abs(Math.floor(offset / 60))
    .toString()
    .padStart(2, "0");
  const minutes = Math.abs(offset % 60)
    .toString()
    .padStart(2, "0");
  return `${sign}${hours}:${minutes}`;
}

export function dateJSON(date) {
  return new Date(date + "T00:00:00.000" + getTimezoneOffsetString()).toJSON();
}

export function filteredObject(object) {
  const keys = Object.keys(object);
  let result = object;
  keys.forEach((key) => {
    if (
      object[key] === null ||
      object[key] === undefined ||
      object[key] === ""
    ) {
      delete result[key];
    }
  });
  return result;
}

export function dateToString(utc) {
  const d = new Date(utc);
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  return `${day}.${month}.${d.getFullYear()}`;
}

export function dateFormatted(utc) {
  const d = new Date(utc);
  const day = d.getDate().toString().padStart(2, "0");
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  return `${d.getFullYear()}-${month}-${day}`;
}

export function datetimeToString(utc) {
  const date = new Date(utc);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const time = `${hours}:${minutes}`;

  return dateToString(utc) + " " + time;
}

export function localDateString(utc) {
  const local = new Date();
  const offset = local.getTimezoneOffset();
  const date = moment(utc).add(-offset, "m");
  return datetimeToString(date);
}

export function localDatetimeString(utc) {
  const local = new Date();
  const offset = local.getTimezoneOffset();
  const date = moment(utc).add(-offset, "m");
  return dateToString(date);
}

export function localDateISO(utc) {
  const local = new Date();
  const offset = local.getTimezoneOffset();
  const date = moment(utc).add(-offset, "m");
  return date.toISOString();
}

export function phoneToString(country, phone) {
  const countryName = countrys[country.code];
  return `${countryName} ${phone}`;
}

export function generateHours(from = 0) {
  const hours = ["არასამუშაო დღე"];
  for (let i = from; i < 24; i++) {
    const hourPart = i.toString().padStart(2, "0");
    const result1 = `${hourPart}:00`;
    const result2 = `${hourPart}:30`;
    hours.push(result1);
    hours.push(result2);
  }
  return hours.map((e, i) => ({ name: e, id: i }));
}

export function workingHoursToJSON(hours) {
  // hours is object { mondayFrom: '', mondayTo: '', tuesdayFrom: '', tuesdayTo: '' }
  // we need result like [{"day": 0, "start_time": "12:00", "end_time": "17:00"},
  //                      {"day": 1, "start_time": "11:00", "end_time": "21:00"}]
  const {
    mondayFrom,
    mondayTo,
    tuesdayFrom,
    tuesdayTo,
    wednesdayFrom,
    wednesdayTo,
    thursdayFrom,
    thursdayTo,
    fridayFrom,
    fridayTo,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
  } = hours;

  const result = [
    { day: 0, start_time: mondayFrom?.name, end_time: mondayTo?.name },
    { day: 1, start_time: tuesdayFrom?.name, end_time: tuesdayTo?.name },
    { day: 2, start_time: wednesdayFrom?.name, end_time: wednesdayTo?.name },
    { day: 3, start_time: thursdayFrom?.name, end_time: thursdayTo?.name },
    { day: 4, start_time: fridayFrom?.name, end_time: fridayTo?.name },
    { day: 5, start_time: saturdayFrom?.name, end_time: saturdayTo?.name },
    { day: 6, start_time: sundayFrom?.name, end_time: sundayTo?.name },
  ];
  const filteredResult = result.filter(
    (e) =>
      e.start_time !== undefined &&
      e.start_time !== null &&
      e.start_time !== "არასამუშაო დღე"
  );

  return filteredResult;
}

export function workingHoursToObject(hours) {
  function getHourId(hour) {
    if (!hour) {
      return 0;
    }
    const generated = generateHours();
    const element = generated.filter((e) => e.name === hour)[0];
    return element.id;
  }

  const result = {
    mondayFrom: hours[0]?.start_time,
    mondayTo: hours[0]?.end_time,
    tuesdayFrom: hours[1]?.start_time,
    tuesdayTo: hours[1]?.end_time,
    wednesdayFrom: hours[2]?.start_time,
    wednesdayTo: hours[2]?.end_time,
    thursdayFrom: hours[3]?.start_time,
    thursdayTo: hours[3]?.end_time,
    fridayFrom: hours[4]?.start_time,
    fridayTo: hours[4]?.end_time,
    saturdayFrom: hours[5]?.start_time,
    saturdayTo: hours[5]?.end_time,
    sundayFrom: hours[6]?.start_time,
    sundayTo: hours[6]?.end_time,
  };
  Object.keys(result).forEach(
    (e) =>
      (result[e] = {
        name: result[e] ? result[e] : "არასამუშაო დღე",
        id: getHourId(result[e]),
      })
  );
  return result;
}
