import React, { useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Controlbar from "../../components/tables/notifications/NofiticationsControlbar.js";
import useToggle from "../../hooks/useToggle.js";
import NotificationForm from "../../components/tables/notifications/form/NotificationForm.js";
import NotificationsTable from "../../components/tables/notifications/NotificationsTable.js";
import NotificationFilter from "../../components/tables/notifications/NotificationFilter.js";

function Notifications({ tab, attribute }) {
  const [showFilter, toggleShowFilter] = useToggle(false);
  const [showForm, toggleShowForm] = useToggle(false);
  const [editingData, setEditingData] = useState(null);

  return (
    <div className="payments-container">
      <Navbar
        title="შეტყობინებების და შეთავაზებების მართვის მოდული"
        attributes={[]}
        module={"notifications"}
        attribute={attribute}
        tab={tab}
      />
      <div className="table-container">
        <Controlbar
          tab={tab}
          showFilter={showFilter}
          toggleShowFilter={toggleShowFilter}
          showForm={showForm}
          toggleShowForm={toggleShowForm}
        />
        {showFilter && <NotificationFilter tab={tab} />}
        {showForm && (
          <NotificationForm
            toggleShowForm={toggleShowForm}
            editingData={editingData}
            setEditingData={setEditingData}
          />
        )}
        {!showForm && (
          <NotificationsTable
            tab={tab}
            toggleShowForm={toggleShowForm}
            setEditingData={setEditingData}
            editingData={editingData}
          />
        )}
      </div>
    </div>
  );
}

export default Notifications;
