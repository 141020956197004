import React, { useState, useEffect } from "react";
import "./filter.css";
import FilterDropdown from "./FilterDropdown.js";
import { callingCodes } from "../../../../constants";
import { dateJSON } from "../../../../libs/Functions";
import { getTypes } from "../../../../libs/API";
import { PhoneInput } from "./OwnershipFilter";
import { legalStatuses, countrys, propertyTypes } from "../../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "../../../../state";
import { bindActionCreators } from "redux";

function getCurrentDateFormatted() {
  // Create a new Date object representing the current date and time
  const today = new Date();

  // Extract the year, month, and day from the Date object
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
  const day = String(today.getDate()).padStart(2, "0");

  // Combine the parts into the desired format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export const DateInput = ({
  placeholder,
  value,
  onChange,
  name,
  disabled,
  notPast = false,
}) => {
  return (
    <div className="date-input-container">
      <input
        type="date"
        className="filter-date-input"
        disabled={disabled}
        value={value}
        onChange={onChange}
        min={notPast ? getCurrentDateFormatted() : ""}
        name={name}
      />
      {value === "" && <div className="date-placeholder">{placeholder}</div>}
    </div>
  );
};

export default function LeasesFilter() {
  const [apartmentType, setApartmentType] = useState(null);
  const indexes = Object.keys(countrys).map((e) => ({
    name: countrys[e],
    id: e,
  }));
  const [index, setIndex] = useState(null);
  const [legalStatus, setLegalStatus] = useState(null);
  const [parkingType, setParkingType] = useState(null);
  const [parkingPropertyType, setParkingPropertyType] = useState(null);
  const [apartmentTypes, setApartmentTypes] = useState([]);
  const [parkingTypes, setParkingTypes] = useState([]);

  const dispatch = useDispatch();
  const { setParametres, getProperties } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const cards = useSelector((state) => state.properties);
  const user = useSelector((state) => state.user.user);

  const [params, setParams] = useState({
    name: "",
    surname: "",
    pers_id: "",
    phone: "",
    organization_name: "",
    organization_code: "",
    from_date: "",
    to_date: "",
    complex_number: "",
  });

  useEffect(() => {
    getTypes((data) => {
      setParkingTypes(data.parking.map((e) => ({ name: e.type.ge, id: e.id })));
      setApartmentTypes(
        data.complex.map((e) => ({ name: e.type.ge, id: e.id }))
      );
    });
  }, []);

  const onChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const resetSearch = () => {
    setParams({
      name: "",
      surname: "",
      pers_id: "",
      phone: "",
      organization_name: "",
      organization_code: "",
      from_date: "",
      to_date: "",
      complex_number: "",
    });
    setIndex(null);
    setLegalStatus(null);
    setApartmentType(null);
    setParkingType(null);
    setParkingPropertyType(null);
  };

  const onSearch = () => {
    const tempParams = {
      load_from: 0,
      status: cards.params.status,
      ownership_type: "lease",
      user_name: params.name,
      user_surname: params.surname,
      user_pers_id: params.pers_id,
      user_status: legalStatus
        ? legalStatus.name === "ფიზიკური პირი"
          ? "individual"
          : "legal"
        : null,
      user_full_phone: params.phone
        ? `${callingCodes[index.id]}${params.phone}`
        : null,
      organization_name: params.organization_name,
      organization_identity_num: params.organization_code,
      parking_type_id: parkingType?.id,
      parking_ownership_type: parkingPropertyType
        ? parkingPropertyType.name === "საკუთრება"
          ? "private"
          : "lease"
        : null,
      complex_type_id: apartmentType?.id,
      lease_from_date: dateJSON(params.from_date),
      lease_to_date: dateJSON(params.to_date),
      apartment_number: params.complex_number,
    };
    setParametres(tempParams);

    getProperties(user, tempParams);
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      onSearch();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleEnter);
    };
  }, [params, apartmentType, parkingType]);

  return (
    <div className="filter-container">
      <div className="filter-row">
        <FilterDropdown
          title="იურიდიული სტატუსი"
          data={legalStatuses}
          selected={legalStatus}
          setSelected={setLegalStatus}
        />
        <div className="filter-input-container">
          <input
            className="filter-input"
            placeholder="სახელი"
            name="name"
            onChange={onChange}
            value={params.name}
          />
        </div>
        <div className="filter-input-container">
          <input
            className="filter-input"
            placeholder="გვარი"
            name="surname"
            onChange={onChange}
            value={params.surname}
          />
        </div>
        <div className="filter-input-container">
          <input
            className="filter-input"
            placeholder="პირადი ნომერი"
            name="pers_id"
            onChange={onChange}
            value={params.pers_id}
          />
        </div>
      </div>
      <div className="filter-row">
        <FilterDropdown
          title="კომპლექსის ტიპი"
          data={apartmentTypes}
          selected={apartmentType}
          setSelected={setApartmentType}
        />
        <div className="filter-input-container">
          <input
            className="filter-input"
            placeholder="ორგანიზაციის დასახელება"
            name="organization_name"
            value={params.organization_name}
            onChange={onChange}
          />
        </div>
        <div className="filter-input-container">
          <input
            className="filter-input"
            value={params.organization_code}
            placeholder="ორგანიზაციის საიდენტიფიკაციო კოდი"
            name="organization_code"
            onChange={onChange}
          />
        </div>
        <PhoneInput indexes={indexes} selected={index} setSelected={setIndex} />
      </div>
      <div className="filter-row">
        <DateInput
          placeholder="დაქირავების თარიღიდან"
          name="from_date"
          onChange={onChange}
          value={params.from_date}
        />
        <DateInput
          placeholder="დაქირავების თარიღამდე"
          name="to_date"
          onChange={onChange}
          value={params.to_date}
        />
        <FilterDropdown
          title="პარკინგის ტიპი"
          data={parkingTypes}
          selected={parkingType}
          setSelected={setParkingType}
        />
        <FilterDropdown
          title="პარკინგის საკუთრების ტიპი"
          data={propertyTypes}
          setSelected={setParkingPropertyType}
          selected={parkingPropertyType}
        />
      </div>
      <div className="filter-row">
        <input
          className="filter-input"
          onChange={onChange}
          placeholder="ბინის/ფართის ნომერი"
          name="complex_number"
          value={params.complex_number}
        />
        <input className="filter-input" style={{ visibility: "hidden" }} />
        <input className="filter-input" style={{ visibility: "hidden" }} />
        <div className="filter-buttons">
          <div className="filter-clear-button" onClick={resetSearch}>
            გასუფთავება
          </div>
          <div className="put-button" onClick={onSearch}>
            ძებნა
          </div>
        </div>
      </div>
    </div>
  );
}
