import API from "../../libs/API";
import { store } from "../../state/store.js";
import {
  ATTACH_FILE,
  CLEAR_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  REMOVE_POPUP_MESSAGE,
  RESET_SEENS,
  SET_NOTIFICATIONS_LOADING,
  SET_NOTIFICATIONS_PARAMS,
  SET_POPUP_MESSAGE,
  UPDATE_NOTIFICATIONS,
} from "./types";
const user = store.getState().user.user;

export const getNotifications = (params) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NOTIFICATIONS });
    dispatch({ type: SET_NOTIFICATIONS_LOADING, payload: true });
    API.get(user, "/posts/filter", params, (res) =>
      dispatch({ type: GET_NOTIFICATIONS, payload: res })
    );
  };
};

export const clearNotifications = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NOTIFICATIONS });
  };
};

export const resetSeens = (id) => {
  return (dispatch) => {
    dispatch({ type: RESET_SEENS, payload: id });
  };
};

export const setParams = (params) => {
  return (dispatch) => {
    dispatch({ type: SET_NOTIFICATIONS_PARAMS, payload: params });
  };
};

export const updateNotifications = (params) => {
  return (dispatch) => {
    API.get(
      user,
      `/posts/filter`,
      params,
      (res) => dispatch({ type: UPDATE_NOTIFICATIONS, payload: res.posts }),
      (err) => {}
    );
  };
};

export const attachFile = (notification_id, data) => {
  return (dispatch) => {
    API.post(
      user,
      `/admin/posts/${notification_id}/file`,
      data,
      (res) => {
        dispatch({
          type: SET_POPUP_MESSAGE,
          payload: "ფაილი წარმატებით აიტვირთა",
        });
        dispatch({ type: ATTACH_FILE });
        setTimeout(() => dispatch({ type: REMOVE_POPUP_MESSAGE }), 2000);
      },
      (err) => console.log(err)
    );
  };
};

export const deleteFile = (notification_id, file_id) => {
  return (dispatch) => {
    API.delete(user, `/admin/posts/${notification_id}/file/${file_id}`);
  };
};

export const deleteNotification = (notification_id) => {
  return (dispatch) => {
    API.delete(
      user,
      `/admin/posts/${notification_id}`,
      null,
      () => {
        dispatch({ type: SET_POPUP_MESSAGE, payload: "წარმატებით წაიშალა" });
        dispatch({ type: DELETE_NOTIFICATION, payload: notification_id });
        setTimeout(() => dispatch({ type: REMOVE_POPUP_MESSAGE }), 2000);
      },
      (err) => console.log(err)
    );
  };
};
