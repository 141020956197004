import API, { server } from "../../libs/API"
import { LOGIN_ERROR, LOGIN_FINISH, RESET_USER, SET_LOADING,SET_ERROR, UPDATE_USER, SET_LOGOUT_LOADING} from "./types"
import axios from 'axios';

// Reset user

export const resetUser = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_USER,
        })
    }
}

// Set loading state

export const setLoading = (flag) => {
    return (dispatch) => {
        dispatch({
            type: RESET_USER,
            payload: flag,
        })
    }
}

// Set error

export const setError = (msg) => {
    return (dispatch) => {
        dispatch({
            type: SET_ERROR,
            payload: msg,
        })
    }
}

// Login user

const loginFinish = (res) => ({
    type: LOGIN_FINISH,
    payload: res,
})

const loginError = (err) => ({
    type: LOGIN_ERROR,
    payload: err,
})

export const loginUser = (name, password) => {
    return (dispatch) => {
        const data = {login: name, password}
        dispatch({type: SET_LOADING, payload: true})
        API.post(null, '/admin/login', data, 
        (res) => dispatch(loginFinish(res)),
        (err) => dispatch(loginError(err))
        );
    }
}

// Update user

export const updateUser = (key, value) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_USER,
            payload: [key, value],
        })
    }
}

// Logout user

const logoutError = (user, error, callAfter) => {
    console.log(error)
    
    if (user && error.response.status === 401) {
        if (error.response.data.msg === "Token has expired") {
          axios.post(`${server}/token_refresh`, null, {headers: { Authorization: 'Bearer ' + user.refresh_token }})
          .then((res) => {
            updateUser("access_token", res.access_token);
            callAfter();
          })
          .catch(err => console.log(err))
          return
        }
  
        // request for new token
        if (error.response.data.msg === "5") {
            return
        }
  
        // if (!localStorage.getItem('access_token')) {
        //   window.location.reload()
        //   return
        // }
    }

}

const logoutFinish = () => {

    return {
        type: RESET_USER
    }
}

export const logoutUser = (user) => {
    return (dispatch) => {
        dispatch({type: SET_LOGOUT_LOADING});
        axios.post(`${server}/logout_access`, null, 
            { headers: { Authorization: 'Bearer ' +  user.access_token} 
        })
        .then((res) => {
            axios.post(`${server}/logout_refresh`, null, 
                { headers: { Authorization: 'Bearer ' +  user.refresh_token} 
            }).then(() => dispatch(logoutFinish()))
        })
        .catch(err => {
            logoutError(user, err, logoutUser)
            dispatch({type: RESET_USER})
        });
    }
}