import React, { useState } from "react";
import { DateInput } from "../../filters/LeasesFilter";
import Checkbox from "../../../../Checkbox/Checkbox";
import useToggle from "../../../../../hooks/useToggle";
import useWaivedFees from "../../../../../hooks/useWaivedFees";
import bin from "../../../../../assets/bin.png";
import edit_icon from "../../../../../assets/edit_icon.png";
import Confirmation from "../../../../ConfirmationModal/Confirmation";

function formatToday() {
  let today = new Date();

  // Get the year, month, and day parts of the date
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  let day = String(today.getDate()).padStart(2, "0");

  // Combine the parts into the desired format
  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export default function SavePeriod({
  createWaiveMutation,
  removeWaiveMutation,
  selectedApartment,
  selectedSubcategory,
}) {
  const { data } = useWaivedFees(selectedApartment);

  const currentWaivedFee = data.waived_fees.filter(
    (fee) => fee.fee_category_id === selectedSubcategory
  )[0];

  const [disabled, toggleDisabled] = useToggle(currentWaivedFee ? true : false);
  const [showConfirmation, toggleShowConfirmation] = useToggle();

  const [start_date, set_start_date] = useState(
    currentWaivedFee ? currentWaivedFee.start_date : ""
  );
  const [end_date, set_end_date] = useState(
    currentWaivedFee && !currentWaivedFee.waived_forever
      ? currentWaivedFee.end_date
      : ""
  );
  const [waived_forever, set_waived_forever] = useState(
    currentWaivedFee ? currentWaivedFee.waived_forever : false
  );

  const saveDisabled = waived_forever
    ? false
    : start_date === "" || end_date === "";

  const handleSave = () => {
    if (saveDisabled) {
      return;
    }

    const body = {
      start_date: !start_date && waived_forever ? formatToday() : start_date,
      end_date,
      waived_forever: waived_forever ? true : false,
    };

    createWaiveMutation.mutate({
      body,
      apartment_id: selectedApartment,
      fee_category_id: selectedSubcategory,
    });
    toggleDisabled();
  };

  const removeWaive = () => {
    removeWaiveMutation.mutate({
      apartment_id: selectedApartment,
      category_id: selectedSubcategory,
    });
    toggleShowConfirmation();
    set_end_date("");
    set_start_date("");
    set_waived_forever(false);
    toggleDisabled(true);
  };

  const handleForeverClick = () => {
    set_waived_forever((prev) => {
      return !prev;
    });
  };

  return (
    <div className="save-period">
      <Confirmation
        content={"ნამდვილად გსურთ არჩეულ ფართს ჩაურთოთ მონიშნული გადასახადი?"}
        showConfirm={showConfirmation}
        toggleShowConfirm={toggleShowConfirmation}
        onConfirm={removeWaive}
      />
      <div className="save-period-dates">
        <DateInput
          value={start_date}
          onChange={(e) => set_start_date(e.target.value)}
          name={"start_date"}
          placeholder={"თარიღიდან"}
          disabled={disabled}
          notPast={true}
        />
        <DateInput
          value={end_date}
          onChange={(e) => set_end_date(e.target.value)}
          name={"end_date"}
          placeholder={"თარიღამდე"}
          disabled={disabled}
          notPast={true}
        />
      </div>
      <div className="row-flex-between">
        <Checkbox
          label={"მუდმივად"}
          isChecked={waived_forever}
          onClick={handleForeverClick}
          disabled={disabled}
        />
        {!disabled && (
          <div
            className={`save-period-button ${
              saveDisabled ? "button-disabled" : ""
            }`}
            onClick={handleSave}
          >
            {createWaiveMutation.isPending ? (
              <div className="loader"></div>
            ) : (
              "შენახვა"
            )}
          </div>
        )}
        {disabled && (
          <div className="edit-icons">
            <img src={edit_icon} alt="edit" onClick={toggleDisabled} />
            <img src={bin} alt="delete" onClick={toggleShowConfirmation} />
          </div>
        )}
      </div>
      <div className="warning-text">
        <p>შენიშვნა!</p>
        <p>გადასახადის ინვოისები გენერირდება</p>
        <p>ყოველი თვის 01 რიცხვში</p>
      </div>
    </div>
  );
}
