import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import edit_icon from '../../../../assets/edit_icon.png';
import useToggle from '../../../../hooks/useToggle';
import { getTypes } from '../../../../libs/API';
import { saveFee } from '../../../../state/action-creators';
import arrow from '../../../../assets/arrow.png';

function Fee({fee, currencies, category_id}) {
    const [showDrop, toggleShowDrop] = useToggle();
    const [curr, setCurr] = useState(fee.currency.en);
    const [tariff, setTariff] = useState(fee.tariff);
    const [editing, toggleEditing] = useToggle(false);
    const dispatch = useDispatch();

    const onSelect = (value) => {
        setCurr(value);
    }

    const onSave = () => {
        const data = {
            currency: curr,
            tariff: tariff,
        }
        const newFee = {
            ...fee,
            currency: {...fee.currency, en: curr},
            tariff: Number(tariff),
        }
        dispatch(saveFee(category_id, data, newFee));
        toggleEditing(false);
    }

    useEffect(() => {
        setTariff(fee.tariff.toFixed(2));
    }, [fee.tariff, setTariff])

    return (
        <div className='fee'>
            <div className='fee-data'>
                <p>{fee.complex.type.ge}</p>
                <p>ინვოისის გამოწერა - {fee.generation_day} რიცხვი</p>
                <p>გადახდის ბოლო ვადა - {fee.deadline_after_days} დღე</p>
            </div>
            <div className='fee-tariff'>
                <input type='text'
                className='fee-price' onChange={(e) => setTariff(e.target.value)}
                disabled={!editing} value={tariff} />
                
                {editing && 
                <div className='fee-currency-selector' onClick={toggleShowDrop}>
                    <p>{curr} 
                        <img src={arrow} className="fee-currency-arrow" alt='arrow'/>
                    </p>
                    {showDrop && 
                    <div className='fee-currencies-drop'>
                        {currencies.map((c) => <p key={c.en} onClick={() => onSelect(c.en)}>{c.en}</p>)}
                    </div>}
                </div>}
                {!editing && <p className='fee-currency'>{curr}</p>}
                {!editing && <img src={edit_icon} alt="editicon" className="fees-edit-icon" onClick={toggleEditing}/>}
                {editing && 
                <div className='category-put-button fee-save-button' onClick={onSave}>შენახვა</div>}
            </div>
        </div>
    )
}

export default function FeesColumn({ id }) {
  const fees = useSelector(state => state.subcategories.items.filter((i) => i.id===id)[0]?.fees);
  const [currencies, setCurrencies] = useState([]);


  useEffect(() => {
    getTypes((data) => setCurrencies(data.currency));
  }, []);

  return (
    <div className='fees-container'>
    <div className='fees'>
        {fees?.map((fee) => 
        <Fee key={fee.id} fee={fee} 
        currencies={currencies} category_id={id}
        />)}
    </div>
    </div>
  )
}
