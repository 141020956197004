import React from "react";

export default function LeaseIconFilled({ onClick }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 5.6 3 4.9 3.272 4.365C3.51173 3.89435 3.89435 3.51173 4.365 3.272C4.9 3 5.6 3 7 3H17C18.4 3 19.1 3 19.635 3.272C20.1053 3.5119 20.4875 3.89451 20.727 4.365C21 4.9 21 5.6 21 7V17C21 18.4 21 19.1 20.727 19.635C20.4874 20.1051 20.1051 20.4874 19.635 20.727C19.1 21 18.4 21 17 21H7C5.6 21 4.9 21 4.365 20.727C3.89451 20.4875 3.5119 20.1053 3.272 19.635C3 19.1 3 18.4 3 17V7ZM10.83 11C10.5941 10.3325 10.1298 9.76991 9.51919 9.41164C8.90858 9.05337 8.19098 8.92251 7.49321 9.04219C6.79545 9.16186 6.16246 9.52437 5.70613 10.0656C5.2498 10.6069 4.99951 11.292 4.99951 12C4.99951 12.708 5.2498 13.3931 5.70613 13.9344C6.16246 14.4756 6.79545 14.8381 7.49321 14.9578C8.19098 15.0775 8.90858 14.9466 9.51919 14.5884C10.1298 14.2301 10.5941 13.6675 10.83 13H13V14C13 14.2652 13.1054 14.5196 13.2929 14.7071C13.4804 14.8946 13.7348 15 14 15C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V13H16V14C16 14.2652 16.1054 14.5196 16.2929 14.7071C16.4804 14.8946 16.7348 15 17 15C17.2652 15 17.5196 14.8946 17.7071 14.7071C17.8946 14.5196 18 14.2652 18 14V12.15C18 11.845 17.8788 11.5525 17.6632 11.3368C17.4475 11.1212 17.155 11 16.85 11H14H10.83ZM9 12C9 11.7348 8.89464 11.4804 8.70711 11.2929C8.51957 11.1054 8.26522 11 8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13C8.26522 13 8.51957 12.8946 8.70711 12.7071C8.89464 12.5196 9 12.2652 9 12Z"
        fill="#DAA161"
      />
    </svg>
  );
}
