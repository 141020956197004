import React from "react";

export default function LeaseIcon({ onClick }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="icon"
      onClick={onClick}
    >
      <path
        d="M3 7C3 5.6 3 4.9 3.272 4.365C3.51173 3.89435 3.89435 3.51173 4.365 3.272C4.9 3 5.6 3 7 3H17C18.4 3 19.1 3 19.635 3.272C20.1053 3.5119 20.4875 3.89451 20.727 4.365C21 4.9 21 5.6 21 7V17C21 18.4 21 19.1 20.727 19.635C20.4874 20.1051 20.1051 20.4874 19.635 20.727C19.1 21 18.4 21 17 21H7C5.6 21 4.9 21 4.365 20.727C3.89451 20.4875 3.5119 20.1053 3.272 19.635C3 19.1 3 18.4 3 17V7Z"
        stroke="#333333"
        strokeWidth="2"
      />
      <path
        d="M8 14C9.10457 14 10 13.1046 10 12C10 10.8954 9.10457 10 8 10C6.89543 10 6 10.8954 6 12C6 13.1046 6.89543 14 8 14Z"
        stroke="#333333"
        strokeWidth="2"
      />
      <path
        d="M10 12H14M14 12H16.85C16.8898 12 16.9279 12.0158 16.9561 12.0439C16.9842 12.0721 17 12.1102 17 12.15V14M14 12V14"
        stroke="#333333"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
