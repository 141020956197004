import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import Dropdown from '../../../Dropdown/Dropdown';
import { generateHours } from '../../../../libs/Functions';

const hours = generateHours()

function WorkingHours({
    onHourSelect,
    workHours,
    disabled
}) {
  
  const { mondayFrom, mondayTo, 
          tuesdayFrom, tuesdayTo,
          wednesdayFrom, wednesdayTo,
          thursdayFrom, thursdayTo,
          fridayFrom, fridayTo,
          saturdayFrom, saturdayTo,
          sundayFrom, sundayTo
    } = workHours;

  const onFromSelect = (e, fieldName) => {
    const day = fieldName.split("From")[0];
    onHourSelect(e, fieldName);
    if (e.name === "არასამუშაო დღე") {
        onHourSelect(hours[0], day+"To");
        return;
    }
    onHourSelect(hours[e.id+1], day + "To");
  }

  return (
    <div className='working_hours_container'>
        <label>სამუშაო პერიოდი</label>
        <div className='working_hour_row'>
            <label>ორშაბათი</label>
            <div className='working_hour_inputs'>
                <Dropdown 
                    title={'საათიდან'}
                    height={35}
                    width={140}
                    data={hours}
                    setSelected={(e) => onFromSelect(e, "mondayFrom")}
                    selected={mondayFrom}
                    disabled={disabled}
                />
                <Dropdown 
                    title={'საათამდე'}
                    height={35}
                    width={140}
                    setSelected={(e) => onHourSelect(e, "mondayTo")}
                    selected={mondayTo}
                    data={hours.slice(mondayFrom?.id+1)}
                    disabled={!mondayFrom || mondayFrom.name === hours[0].name || disabled}
                />
            </div>
        </div>

        <div className='working_hour_row'>
            <label>სამშაბათი</label>
            <div className='working_hour_inputs'>
                <Dropdown 
                    title={'საათიდან'}
                    height={35}
                    width={140}
                    data={hours}
                    setSelected={(e) => onFromSelect(e, "tuesdayFrom")}
                    selected={tuesdayFrom}
                    disabled={disabled}
                />
                <Dropdown 
                    title={'საათამდე'}
                    height={35}
                    width={140}
                    data={hours.slice(tuesdayFrom?.id+1)}
                    selected={tuesdayTo}
                    setSelected={(e) => onHourSelect(e, "tuesdayTo")}
                    disabled={!tuesdayFrom || tuesdayFrom.name === hours[0].name || disabled}
                />
            </div>
        </div>

        <div className='working_hour_row'>
            <label>ოთხშაბათი</label>
            <div className='working_hour_inputs'>
                <Dropdown 
                    title={'საათიდან'}
                    height={35}
                    width={140}
                    data={hours}
                    setSelected={(e) => onFromSelect(e, "wednesdayFrom")}
                    selected={wednesdayFrom}
                    disabled={disabled}
                />
                <Dropdown 
                    title={'საათამდე'}
                    height={35}
                    width={140}
                    data={hours.slice(wednesdayFrom?.id+1)}
                    selected={wednesdayTo}
                    setSelected={(e) => onHourSelect(e, "wednesdayTo")}
                    disabled={!wednesdayFrom || wednesdayFrom.name === hours[0].name || disabled}
                />
            </div>
        </div>

        <div className='working_hour_row'>
            <label>ხუთშაბათი</label>
            <div className='working_hour_inputs'>
                <Dropdown 
                    title={'საათიდან'}
                    height={35}
                    width={140}
                    data={hours}
                    setSelected={(e) => onFromSelect(e, "thursdayFrom")}
                    selected={thursdayFrom}
                    disabled={disabled}
                />
                <Dropdown 
                    title={'საათამდე'}
                    height={35}
                    width={140}
                    data={hours.slice(thursdayFrom?.id+1)}
                    selected={thursdayTo}
                    setSelected={(e) => onHourSelect(e, "thursdayTo")}
                    disabled={!thursdayFrom || thursdayFrom.name === hours[0].name || disabled}
                />
            </div>
        </div>

        <div className='working_hour_row'>
            <label>პარასკევი</label>
            <div className='working_hour_inputs'>
                <Dropdown 
                    title={'საათიდან'}
                    height={35}
                    width={140}
                    data={hours}
                    setSelected={(e) => onFromSelect(e, "fridayFrom")}
                    selected={fridayFrom}
                    disabled={disabled}
                />
                <Dropdown 
                    title={'საათამდე'}
                    height={35}
                    width={140}
                    data={hours.slice(fridayFrom?.id+1)}
                    selected={fridayTo}
                    setSelected={(e) => onHourSelect(e, "fridayTo")}
                    disabled={!fridayFrom || fridayFrom.name === hours[0].name || disabled}
                />
            </div>
        </div>

        <div className='working_hour_row'>
            <label>შაბათი</label>
            <div className='working_hour_inputs'>
                <Dropdown 
                    title={'საათიდან'}
                    height={35}
                    width={140}
                    data={hours}
                    setSelected={(e) => onFromSelect(e, "saturdayFrom")}
                    selected={saturdayFrom}
                    disabled={disabled}
                />
                <Dropdown 
                    title={'საათამდე'}
                    height={35}
                    width={140}
                    data={hours.slice(saturdayFrom?.id+1)}
                    selected={saturdayTo}
                    setSelected={(e) => onHourSelect(e, "saturdayTo")}
                    disabled={!saturdayFrom || saturdayFrom.name === hours[0].name || disabled}
                />
            </div>
        </div>

        <div className='working_hour_row'>
            <label>კვირა</label>
            <div className='working_hour_inputs'>
                <Dropdown 
                    title={'საათიდან'}
                    height={35}
                    width={140}
                    data={hours}
                    setSelected={(e) => onFromSelect(e, "sundayFrom")}
                    selected={sundayFrom}
                    disabled={disabled}
                />
                <Dropdown 
                    title={'საათამდე'}
                    height={35}
                    width={140}
                    data={hours.slice(sundayFrom?.id+1)}
                    selected={sundayTo}
                    setSelected={(e) => onHourSelect(e, "sundayTo")}
                    disabled={!sundayFrom || sundayFrom.name === hours[0].name || disabled}
                />
            </div>
        </div>
  
    </div>
  )
}

WorkingHours.propTypes = {
    onHourSelect: PropTypes.func,
    workHours: PropTypes.any,
}

export default WorkingHours;
