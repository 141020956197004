import React, { useEffect, useState } from "react";
import FilterDropdown from "../../../services-tables/filters/FilterDropdown";
import FilterInput from "../../../services-tables/filters/FilterInput";
import "./index.css";
import { useQueryClient } from "@tanstack/react-query";
import useTypesInfo from "../../../../../hooks/useTypesInfo";

function ApartmentFilter({
  params,
  setParams,
  complexType,
  setComplexType,
  setComplexTypes,
  complexTypes,
  setSearchCount,
}) {
  const { floor, apartment_number } = params;

  const queryClient = useQueryClient();
  const { data: typesData, isFetched: typesFetched } = useTypesInfo("complex");

  const onChange = (e) => {
    setParams((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  useEffect(() => {
    if (typesFetched) {
      setComplexTypes(
        typesData.map((e) => ({ ...e, name: e.type.ge, name_en: e.type.en }))
      );
    }
  }, [typesFetched]);

  const onSearch = () => {
    setSearchCount((prev) => prev + 1);
  };
  const clearFilter = () => {
    setParams({
      floor: "",
      apartment_number: "",
    });
    setComplexType(null);
  };

  return (
    <div className="apartment-filter">
      <label>ფართები</label>
      <div className="apartment-filter-inputs">
        <div className="apartment-filter-row">
          <FilterDropdown
            title={"კომპლექსის ტიპი"}
            data={complexTypes}
            selected={complexType}
            setSelected={setComplexType}
          />
          <FilterInput
            placeholder={"სართული"}
            name={"floor"}
            onChange={onChange}
            value={floor}
          />
        </div>
        <div className="apartment-filter-row">
          <FilterInput
            placeholder={"ფართის/ბინის ნომერი"}
            name={"apartment_number"}
            onChange={onChange}
            value={apartment_number}
          />
          <div className="apartment-filter-clear-button" onClick={clearFilter}>
            გასუფთავება
          </div>
          <div className="apartment-put-button" onClick={onSearch}>
            ძებნა
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApartmentFilter;
