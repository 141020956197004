import React from "react";
import useToggle from "../../../../hooks/useToggle.js";
import CommentSection from "./CommentSection";
import { phoneToString } from "../../../../libs/Functions.js";
import ChatIcon from "../../../Icons/ChatIcon.js";

function AddresateRow({ self }) {
  const [showCommentSection, toggleShowCommentSection] = useToggle();

  const onChatClick = (e) => {
    if (self.unseen_comments === -1) {
      return;
    }
    e.stopPropagation();
    toggleShowCommentSection();
  };

  if (!self) {
    return null;
  }
  return (
    <div className="card-main-category addresate-row">
      <div className="addresate-data" onClick={onChatClick}>
        <p>
          {`${self.name} ${self.surname} | ${self.pers_id} | ${phoneToString(
            self.country,
            self.phone
          )}`}{" "}
          {self.organizations.map((org, i) => (
            <span key={i}>{`| ${org.name} | ${org.identity_num}`}</span>
          ))}
        </p>
        <div className="chat-pointer">
          {self.unseen_comments > 0 && (
            <div className="chat-pointer-number">{self.unseen_comments}</div>
          )}
          <ChatIcon
            fill={self.unseen_comments === -1 ? "#BDBDBD" : "#EB5757"}
            background={self.unseen_comments > 0 ? "#EB5757" : null}
          />
        </div>
      </div>
      {showCommentSection && <CommentSection self={self} />}
    </div>
  );
}

export default AddresateRow;
