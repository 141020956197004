import React from 'react';

export default function RequestHeader({ tab }) {
  return (
    <div className='owners-table-header'>
      <div className='request-cards-header owners-header'>
        <div className='card-rows header-cell'>
          მოთხოვნის ნომეკლატურა
        </div>
        <div className='card-rows header-cell'>
          მოთხოვნის მონაცემი
        </div>
        <div className='card-rows header-cell'>
          მოთხოვნის ავტორის მონაცემი
        </div>
        <div className='card-rows header-cell'>
          მოთხოვნის აღწერა
        </div>
        {(tab === "pending_approval" || tab==="pending_payment" || tab==="unpaid" || tab==="rejected") ?  
        <div className='header-cell'>
          მოთხოვნაზე რეაგირება
        </div> : 
        <div className='header-cell'>
          პასუხისმგებელი პირი
        </div> }
      </div>
    </div>
  )
}
