import React from "react";

function FilesData({ self }) {
  const { files } = self;

  const onFileClick = (e, path) => {
    e.stopPropagation();
    window.open(path, "rel=noopener noreferrer");
  };

  if (!files) {
    return null;
  }
  return (
    <div>
      <div className="card-files">
        {files.map((file, i) => (
          <div className="card-file" key={i}>
            <p onClick={(e) => onFileClick(e, file.path)}>{file?.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FilesData;
