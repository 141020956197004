import React from "react";
import AddresateRow from "./AddresateRow";

function AddresateData({ self, users }) {
  return (
    <div className="addresate-row-container">
      {users.map((addresate, i) => (
        <AddresateRow self={addresate} key={i} />
      ))}
    </div>
  );
}

export default AddresateData;
