import { useQuery } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";

const user = store.getState().user.user;

export default function useTypesInfo(key) {
  return useQuery({
    queryKey: ["types"],
    queryFn: async () => API.get(null, "/info/types").then((res) => res[key]),
  });
}
