import './TutorialsCard.css'
import eye from "../../../assets/eye_.svg"
import ReactPlayer from "react-player";
import deleteIcon from "../../../assets/bin_.svg";
import { useDispatch,useSelector } from 'react-redux';
import lucide_eye from '../../../assets/uncrossed-eye.svg'
import { deleteVideo, updateTutorial} from '../../../state/action-creators/tutorialsActions';
import Modal from '../../Modal/Modal';
import { useState } from 'react';
const TutorialsCard = ({url, title, id, is_visible}) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal]=useState(false);
    const user = useSelector(state => state.user.user);
    // const {deleteVideo}=bindActionCreators(actionCreators, dispatch);
    const onDeleteVideo=()=>{
        
        dispatch(
            deleteVideo(user,id))
        setShowModal(false)
    }
    const onUpdateVideo=()=>{
        console.log(is_visible)
        dispatch(
            updateTutorial(user,id))
    }
    return ( 
        <div className="tutorials-card">
            {showModal &&<Modal message={"ნამდვილად გსურთ ვიდეოს წაშლა?"} agreement={onDeleteVideo} setShowModal={setShowModal}/>}
            <div className="card-head">
                <div className="title">{title}</div>
                <div className="icons">
                    <img onClick={onUpdateVideo} src={is_visible? lucide_eye: eye} alt="eye" style={{cursor: 'pointer'}}/>
                    <img onClick={()=>{setShowModal(true)}} src={deleteIcon} alt="delete" className="bin-icon" />
                </div>
            </div>
            <div className="video">
                <div style={{position: "relative", paddingTop: "56.3%"}}>
                    <ReactPlayer
                        url={url}
                        width={"100%"}
                        height={"100%"}
                        // autoPlay
                        // playing
                        style={{
                        position: "absolute",
                        left: "0",
                        top: "0"
                        }}
                        controls={true}
                        config={{
                        youtube: {
                            playerVars: {
                            autoPlay: false
                            }
                        }
                        }}
                    />
                </div>
            </div>
        </div>
     );
}
 
export default TutorialsCard;