import React from "react";

export default function Star({ fill }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3916_80)">
        <path
          d="M3.58277 18.5625C3.48527 19.1175 4.03277 19.5512 4.51527 19.3037L10.0028 16.4837L15.489 19.3037C15.9715 19.5512 16.519 19.1175 16.4215 18.5637L15.384 12.6512L19.7865 8.45621C20.199 8.06371 19.9865 7.34622 19.434 7.26871L13.3115 6.39872L10.5815 0.989965C10.5295 0.880326 10.4475 0.787694 10.3449 0.72283C10.2423 0.657967 10.1235 0.623535 10.0021 0.623535C9.8808 0.623535 9.76194 0.657967 9.65938 0.72283C9.55682 0.787694 9.47478 0.880326 9.42277 0.989965L6.69277 6.39996L0.57027 7.26997C0.0190199 7.34747 -0.19473 8.06496 0.21652 8.45746L4.62027 12.6525L3.58277 18.565V18.5625ZM9.71402 15.1037L5.10652 17.4712L5.97402 12.525C5.99434 12.4111 5.98639 12.294 5.95086 12.1839C5.91534 12.0739 5.85332 11.9742 5.77027 11.8937L2.13777 8.43121L7.20277 7.71122C7.30765 7.69538 7.40713 7.65435 7.49269 7.59165C7.57824 7.52896 7.64733 7.44645 7.69402 7.35122L10.0003 2.77871L12.309 7.35122C12.3557 7.44645 12.4248 7.52896 12.5104 7.59165C12.5959 7.65435 12.6954 7.69538 12.8003 7.71122L17.8653 8.42997L14.2328 11.8925C14.1495 11.9731 14.0874 12.0729 14.0519 12.1833C14.0163 12.2936 14.0085 12.4109 14.029 12.525L14.8965 17.4712L10.289 15.1037C10.1999 15.0578 10.1011 15.0338 10.0009 15.0338C9.90065 15.0338 9.80187 15.0578 9.71277 15.1037H9.71402Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3916_80">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
