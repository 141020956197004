import React, { useEffect, useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Apartment from "./Apartment";
import { useApartments } from "../../../../../hooks/useApartments";
import useToggle from "../../../../../hooks/useToggle";

function ApartmentList({
  selectedApartment,
  setSelectedApartment,
  params,
  complexType,
  searchCount,
  setSearchCount,
}) {
  const [isChecked, toggleIsChecked] = useToggle();

  const { data, isFetching } = useApartments(
    {
      ...params,
      complex_type_id: complexType?.id,
      waived_fees: isChecked,
    },
    searchCount
  );

  const onCheck = () => {
    setSearchCount((prev) => {
      toggleIsChecked();
      return prev + 1;
    });
  };

  const onSelect = (id) => {
    setSelectedApartment(id);
  };

  if (isFetching) {
    <div className="loader-black addresates-loader "></div>;
  }

  return (
    <div className="apartment-list-container">
      <div
        className="check-every-addresate"
        style={{ margin: "0 20px 10px 20px" }}
      >
        <Checkbox
          style={{ flexGrow: "0", marginBottom: "8px" }}
          isChecked={isChecked}
          onClick={onCheck}
        />
        <p style={{ color: "#828282" }}>
          გადასახადიდან გათავისუფლებული ფართები
        </p>
      </div>
      <div className="apartment-list" style={{ gap: 0 }}>
        {data?.apartments?.map((apartment, i) => (
          <Apartment
            self={apartment}
            key={i}
            onSelect={onSelect}
            selectedApartment={selectedApartment}
          />
        ))}
      </div>
    </div>
  );
}

export default ApartmentList;
