import React from "react";
import PropTypes from "prop-types";
import TextArea from "../../../components/Textarea/TextArea.js";
import Modal from "react-modal";
import close from "../../../assets/close.png";
import { DateInput } from "../services-tables/filters/LeasesFilter.js";

function CloseForm({
  content,
  value,
  onChange,
  onConfirm,
  isOpen,
  toggleIsOpen,
  className,
  dateValue,
  onDateChange,
}) {
  const myConfirm = () => {
    if (value.length === 0 || dateValue === "") {
      return;
    }
    onConfirm();
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      className={"modal-content"}
      shouldCloseOnOverlayClick={true}
    >
      <div className="confirm-modal">
        {content && <p>{content}</p>}
        <DateInput
          placeholder={"გადახდის თარიღი"}
          value={dateValue}
          onChange={onDateChange}
          name={"payment_datetime"}
        />
        <TextArea
          className={className}
          value={value}
          onChange={onChange}
          height={200}
          symbolsLimit={3000}
          placeholder={"დახურვის მიზეზი"}
        />
        <div
          className={`confirm-button ${
            (value.length === 0 || dateValue === "") && "button-disabled"
          }`}
          onClick={myConfirm}
        >
          შენახვა
        </div>
        <img
          src={close}
          alt="close"
          className="close-icon"
          width={15}
          onClick={toggleIsOpen}
        />
      </div>
    </Modal>
  );
}

CloseForm.propTypes = {
  content: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleIsOpen: PropTypes.func,
};

export default CloseForm;
