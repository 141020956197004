import React, { useState, useEffect } from 'react';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import axios from 'axios';
import { server } from '../../libs/API';

const INTERVAL_IN_SECONDS = 15;

const HeartbeatWrapper = ({ children }) => {
  const [serverError, setServerError] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      axios.get(server + '/heartbeat')
        .then(response => {
          // Assuming server returns a status indicating success
          if (response.status !== 200) {
            setServerError(true);
          } else {
            setServerError(false);
            setNetworkError(false);
          }
        })
        .catch(error => {
          if (error.code === "ERR_NETWORK") {
            setNetworkError(true);
            setServerError(false);
          } else {
            setServerError(true);
            setNetworkError(false);
          }

        });
    }, INTERVAL_IN_SECONDS*1000); // 15 seconds interval

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  return (
    <>
      {(serverError || networkError) ? (
        <ErrorPage 
          isNetworkError={networkError}
        />
      ) : (
        children
      )}
    </>
  );
};

export default HeartbeatWrapper;
