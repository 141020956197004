import API from "../../libs/API";
import {
  ADD_COMPLEX,
  ADD_ORGANIZATION,
  ADD_OWNER,
  ADD_PARKING,
  ADD_PROPERTY,
  GET_PROPERTIES,
  PROPERTY_ERROR,
  GET_PROPERTY,
  SET_PROPERTY_LOADING,
  SET_PROPERTY_ERROR,
  SET_PROPERTIES_LOADING,
  DELETE_PROPERTY,
  DELETE_COMPLEX,
  DELETE_OWNER,
  DELETE_ORGANIZATION,
  DELETE_PARKING,
  RESET_CURRENT_PROPERTY,
  SET_PROPERTIES_STATUS,
  UPDATE_OWNER,
  UPDATE_PROPERTIES,
  SET_OWNERSHIP_TYPE,
  UPDATE_COMPLEX,
  UPDATE_PARKING,
  SET_PARAMS,
  SET_OWNER_ADDING_LOADING,
  SET_COMPLEX_ADDING_LOADING,
  SET_PARKING_ADDING_LOADING,
  TOGGLE_RESPONSIBLE_PERSON,
} from "./types";
import { store } from "../../state/store.js";
const user = store.getState().user.user;

// const user = JSON.parse(localStorage.getItem('user'))

export const setParametres = (params) => {
  return (dispatch) => {
    dispatch({
      type: SET_PARAMS,
      payload: params,
    });
  };
};

// add complex

const addComplexFinish = (dispatch, complex, card_id) => {
  localStorage.setItem("card_id", card_id);
  dispatch({
    type: ADD_COMPLEX,
    payload: [complex, card_id],
  });
};

const addComplexError = (dispatch, err) => {
  dispatch({
    type: PROPERTY_ERROR,
    payload: err,
  });
};

export const addComplex = (user, complex) => {
  return (dispatch) => {
    const data = {
      ...complex,
      apartment: {
        ...complex.apartment,
        complex_type: complex.apartment.complex_type.id,
      },
    };
    dispatch({ type: SET_COMPLEX_ADDING_LOADING });
    API.post(
      user,
      `/admin/ownership_card/apartment`,
      data,
      (res) => addComplexFinish(dispatch, res.apartment, res.card_id),
      (err) => addComplexError(dispatch, err)
    );
  };
};

// delete complex
const deleteComplexFinish = (dispatch, complex_id) => {
  dispatch({
    type: DELETE_COMPLEX,
    payload: complex_id,
  });
};

export const deleteComplex = (user, card_id, apartment_id) => {
  return (dispatch) => {
    const data = { card_id, apartment_id };
    API.delete(
      user,
      `/admin/ownership_card/apartment`,
      data,
      () => deleteComplexFinish(dispatch, apartment_id),
      (err) => dispatch({ type: PROPERTY_ERROR, payload: err })
    );
  };
};

// delete user and organization
const deleteUserFinish = (dispatch, user_id) => {
  dispatch({
    type: DELETE_OWNER,
    payload: user_id,
  });
};

const deleteOrganizationFinish = (dispatch, organization_id) => {
  dispatch({
    type: DELETE_ORGANIZATION,
    payload: organization_id,
  });
};

export const deleteOwner = (user, card_id, user_id, organization_id) => {
  const data = { user_id, card_id, organization_id };
  return (dispatch) => {
    API.delete(
      user,
      `/admin/ownership_card/user`,
      data,
      () =>
        user_id
          ? deleteUserFinish(dispatch, user_id)
          : deleteOrganizationFinish(dispatch, organization_id),
      (err) => dispatch({ type: PROPERTY_ERROR, payload: err })
    );
  };
};

// delete parking
const deleteParkingFinish = (dispatch, parking_id) => {
  dispatch({
    type: DELETE_PARKING,
    payload: parking_id,
  });
};

export const deleteParking = (user, card_id, parking_id, ownership_type) => {
  const data = { card_id, parking_id, ownership_type };
  return (dispatch) => {
    API.delete(
      user,
      `/admin/ownership_card/parking`,
      data,
      () => deleteParkingFinish(dispatch, parking_id),
      (err) => dispatch({ type: PROPERTY_ERROR, payload: err })
    );
  };
};

// add owner

const addOwnerFinish = (dispatch, owner, card_id) => {
  localStorage.setItem("card_id", card_id);
  const result_owner = {
    ...owner.user,
    is_responsible: owner.is_responsible,
  };
  const result_organization = {
    ...owner.organization,
    is_responsible: owner.is_responsible,
  };
  dispatch({
    type: owner.organization ? ADD_ORGANIZATION : ADD_OWNER,
    payload: [owner.user ? result_owner : result_organization, card_id],
  });
};

const addOwnerError = (dispatch, error) => {
  dispatch({
    type: PROPERTY_ERROR,
    payload: error,
  });
};

export const setPropertyError = (flag) => {
  return (dispatch) => {
    dispatch({ type: SET_PROPERTY_ERROR, payload: flag });
  };
};

export const addOwner = (user, owner) => {
  return (dispatch) => {
    const data = {
      ...owner,
      user: {
        name: owner.user.name,
        surname: owner.user.surname,
        phone: owner.user.phone,
        pers_id: owner.user.pers_id,
        country_code: owner.user.country_code,
        country_phone_prefix: owner.user.country_phone_prefix,
      },
    };
    dispatch({ type: SET_OWNER_ADDING_LOADING });
    API.post(
      user,
      `/admin/ownership_card/user`,
      data,
      (res) =>
        addOwnerFinish(
          dispatch,
          { ...res, is_responsible: owner.is_responsible },
          res.card_id
        ),
      (err) => addOwnerError(dispatch, err)
    );
  };
};

// put owner
const updateOwnerFinish = (dispatch, res) => {
  dispatch({
    type: UPDATE_OWNER,
    payload: res,
  });
};

export const updateOwner = (user, owner) => {
  console.log("owner", owner);
  const data = {
    ...owner,
    user: {
      name: owner.user.name,
      surname: owner.user.surname,
      phone: owner.user.phone,
      pers_id: owner.user.pers_id,
      country_code: owner.user.country_code,
      country_phone_prefix: owner.user.country_phone_prefix,
    },
  };
  console.log("data", data);
  return (dispatch) => {
    API.put(user, `/admin/ownership_card/user`, data, () =>
      updateOwnerFinish(dispatch, {
        ...data,
        user: {
          ...data.user,
          is_responsible: data.is_responsible,
          country: {
            code: owner.user.country_code,
            phone_prefix: owner.user.country_phone_prefix,
          },
        },
      })
    );
  };
};

// put complex
export const updateComplex = (user, complex) => {
  return (dispatch) => {
    API.put(user, `/admin/ownership_card/apartment`, complex, () =>
      dispatch({ type: UPDATE_COMPLEX, payload: complex })
    );
  };
};

// put parking
export const updateParking = (user, parking) => {
  const from = parking.from_date ? new Date(parking.from_date) : null;
  const to = parking.to_date ? new Date(parking.to_date) : null;
  const data = {
    ...parking,
    from_date: from?.toJSON(),
    to_date: to?.toJSON(),
  };
  return (dispatch) => {
    API.put(user, "/admin/ownership_card/parking", data, (res) =>
      dispatch({ type: UPDATE_PARKING, payload: res.parking })
    );
  };
};

// add parking

const addParkingFinish = (dispatch, parking, card_id) => {
  localStorage.setItem("card_id", card_id);
  dispatch({
    type: ADD_PARKING,
    payload: [parking, card_id],
  });
};

const addParkingError = (dispatch, error) => {
  dispatch({
    type: PROPERTY_ERROR,
    payload: error,
  });
};

export const addParking = (user, parking) => {
  return (dispatch) => {
    const from = new Date(parking.from_date);
    const to = new Date(parking.to_date);
    const data = {
      card_id: parking.card_id,
      parking_id: parking.parking_id,
      quantity: parking.quantity,
      ownership_type: parking.ownership_type_en,
      from_date: from.toJSON(),
      to_date: to.toJSON(),
    };
    dispatch({ type: SET_PARKING_ADDING_LOADING });
    API.post(
      user,
      `/admin/ownership_card/parking`,
      data,
      (res) => addParkingFinish(dispatch, res.parking, res.card_id),
      (err) => addParkingError(dispatch, err)
    );
  };
};

// get properties

const getPropertiesFinish = (dispatch, payload) => {
  dispatch({
    type: GET_PROPERTIES,
    payload: payload,
  });
};

const getPropertiesError = (dispatch, err) => {
  dispatch({
    type: PROPERTY_ERROR,
    payload: err,
  });
};

export const getProperties = (user, params) => {
  return (dispatch) => {
    dispatch({ type: SET_PROPERTIES_LOADING, payload: true });
    API.get(
      user,
      `/admin/ownership_cards/filter`,
      params,
      (res) => getPropertiesFinish(dispatch, res.ownership_cards),
      (err) => getPropertiesError(dispatch, err)
    );
  };
};

// update properties
export const updateProperties = (user, params) => {
  return (dispatch) => {
    API.get(
      user,
      `/admin/ownership_cards/filter`,
      params,
      (res) =>
        dispatch({ type: UPDATE_PROPERTIES, payload: res.ownership_cards }),
      (err) => getPropertiesError(dispatch, err)
    );
  };
};

// set property staus
export const setPropertiesStatus = (status) => {
  return (dispatch) => {
    dispatch({ type: SET_PROPERTIES_STATUS, payload: status });
  };
};

// set ownership type
export const setOwnershipType = (type) => {
  return (dispatch) => {
    dispatch({ type: SET_OWNERSHIP_TYPE, payload: type });
  };
};

// get single property

const getPropertyFinish = (dispatch, property) => {
  dispatch({
    type: GET_PROPERTY,
    payload: property,
  });
};

export const getProperty = (user, card_id) => {
  return (dispatch) => {
    dispatch({ type: SET_PROPERTY_LOADING, payload: true });
    API.get(user, `/admin/ownership_card/${card_id}`, null, (res) =>
      getPropertyFinish(dispatch, res.ownership_card)
    );
  };
};

// add property
const addPropertyFinish = (dispatch, property) => {
  dispatch({
    type: ADD_PROPERTY,
    payload: property,
  });
};

const addPropertyError = (dispatch, err) => {
  dispatch({
    type: PROPERTY_ERROR,
    payload: err,
  });
};

export const addProperty = (user, property) => {
  return (dispatch) => {
    API.post(
      user,
      `/admin/ownership_card/${property.card_id}`,
      null,
      () => addPropertyFinish(dispatch, property),
      (err) => addPropertyError(dispatch, err)
    );
  };
};
//reset property
export const resetProperty = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_CURRENT_PROPERTY,
    });
  };
};

// delete property
const deletePropertyFinish = (dispatch, res) => {
  dispatch({
    type: DELETE_PROPERTY,
    payload: res,
  });
};

const deletePropertyError = (dispatch, payload) => {
  dispatch({
    type: GET_PROPERTIES,
    payload: payload,
  });
};

// save to drafts
export const saveToDrafts = (user, card_id) => {
  return (dispatch) => {
    API.put(user, `/admin/ownership_card/${card_id}`, null);
  };
};

// toggle responsible person
export const toggleResponsiblePerson = (body) => {
  return (dispatch) => {
    API.put(user, `/admin/ownership_card/toggle_responsibility`, body, (res) =>
      dispatch({ type: TOGGLE_RESPONSIBLE_PERSON, payload: body })
    );
  };
};

export const deleteProperty = (user, card_id) => {
  return (dispatch) => {
    dispatch({ type: SET_PROPERTY_LOADING, payload: [DELETE_PROPERTY, true] });
    API.delete(
      user,
      `/admin/ownership_card/${card_id}`,
      null,
      (res) => deletePropertyFinish(dispatch, res),
      (err) => deletePropertyError(dispatch, err)
    );
  };
};
