import React from "react";
import PropTypes from "prop-types";

function Checkbox({ label, onClick, isChecked, disabled, style }) {
  const myOnclick = () => {
    if (disabled) return;
    onClick();
  };

  return (
    <div
      className={`services-input-checkbox ${!isChecked && "button-disabled"}`}
      onClick={myOnclick}
      style={style ? style : {}}
    >
      <div className="checkbox">{isChecked && <p>✓</p>}</div>
      <label>{label}</label>
    </div>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default Checkbox;
