import React from "react";

function FilterInput({ type, style, value, onChange, name, placeholder }) {
  return (
    <div className="filter-input-container">
      <input
        type={type}
        className="filter-input"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        style={style}
      />
    </div>
  );
}

export default FilterInput;
