import { ADD_PROPERTY, CLEAR_PROPERTIES, DELETE_PROPERTY, GET_PROPERTIES, SET_OWNERSHIP_TYPE, SET_PARAMS, SET_PROPERTIES_LOADING, SET_PROPERTIES_STATUS, UPDATE_PROPERTIES } from "../action-creators/types";


const initialState = {
    properties: [],
    params: {
        load_from: 0,
        status: 'ready',
        ownership_type: 'private',
    },
    status: "ready",
    ownership_type: 'private',
    isError: false,
    isLoading: false,
    lastFetch: [],
    message: '',
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case UPDATE_PROPERTIES:
            return {
                ...state,
                properties: [...state.properties, ...action.payload],
                lastFetch: action.payload,
                isLoading: false,
            }
        case GET_PROPERTIES:
            return {
                ...state,
                properties: action.payload,
                lastFetch: action.payload,
                isLoading: false,
            }
        case ADD_PROPERTY:
            return {
                ...state,
                properties: [...state.properties, action.payload],
            }
        case DELETE_PROPERTY:
            return {
                ...state,
                isLoading: false,
            }
        case SET_PROPERTIES_LOADING:
            const value = action.payload;
            return {
                ...state,
                properties: [],
                isLoading: value,
            }
        case SET_PROPERTIES_STATUS:
            const status = action.payload;
            return {
                ...state,
                status: status,
            }
        case SET_OWNERSHIP_TYPE:
            return {
                ...state,
                ownership_type: action.payload,
            }
        case SET_PARAMS:
            return {
                ...state,
                params: action.payload,
            }
        case CLEAR_PROPERTIES:
            return {
                ...state,
                properties: [],
            }
        default:
            return state
    }
}

export default reducer;