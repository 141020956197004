import { REMOVE_POPUP_MESSAGE, SET_POPUP_MESSAGE } from "../action-creators/types"

const initialState = {
    msg: '',
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case SET_POPUP_MESSAGE:
            return {
                ...state,
                msg: action.payload,
            }
        case REMOVE_POPUP_MESSAGE:
            return {
                ...state,
                msg: '',
            }
        default:
            return state;
    }
}

export default reducer;