import { APPROVE_REQUEST, ASSIGN_EXECUTOR, CLEAR_REQUESTS, COMPLETE_REQUEST, GET_REQUESTS, REJECT_REQUEST, RESET_REQUEST_STATE, SET_ASSIGN_LOADING, SET_INVOICE_REQUESTS_UPDATING, SET_REQUESTS_LOADING, SET_REQUEST_PARAMS, UPDATE_REQUESTS } from "../action-creators/types";

const initialState = {
    items: [],
    isLoading: false,
    params: {
      load_from: 0,
      status: "active",
    },
    assign_loading: false,
    updating: false,
    lastFetch: [],
    message: '',
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_REQUESTS:
            return {
                ...state,
                items: action.payload.requests,
                isLoading: false,
                lastFetch: action.payload.requests,
                total_cost_sum: action.payload.total_cost_sum,
                updating: false,
            }
        case UPDATE_REQUESTS:
            return {
                ...state,
                items: [...state.items, ...action.payload], 
                lastFetch: action.payload,
                isLoading: false,
                updating: false,
            }
        case ASSIGN_EXECUTOR:
            const id = action.payload;
            return {
                ...state,
                items: state.items.filter((item) => item.id !== id),
                assign_loading: false,
            }
        case SET_ASSIGN_LOADING:
            return {
                ...state,
                assign_loading: action.payload,
            }
        case SET_REQUEST_PARAMS:
            return {
                ...state,
                params: action.payload,
            }
        case SET_INVOICE_REQUESTS_UPDATING:
            return {
                ...state,
                updating: true,
            }
        case SET_REQUESTS_LOADING:
            return {
                ...state, 
                items: [],
                isLoading: action.payload
            }
        case COMPLETE_REQUEST:
            const temp_id = action.payload;
            return {
                ...state,
                items: state.items.filter((i) => i.id !== temp_id),
            }
        case APPROVE_REQUEST:
            const request_id = action.payload;
            return {
                ...state,
                items: state.items.filter((i) => i.id !== request_id),
            }
        case REJECT_REQUEST:
            return {
                ...state,
                items: state.items.filter((i) => i.id !== action.payload),
            }
        case CLEAR_REQUESTS:
            return {
                ...state,
                items: [],
            }
        case RESET_REQUEST_STATE:
            return initialState;
        default:
            return state;
    }
}

export default reducer;