import React, { useEffect, useState } from "react";
import "./form.css";
import Dropdown from "../../Dropdown/Dropdown";
import { propertyTypes } from "../../../constants/index";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import { dateJSON } from "../../../libs/Functions";
import InputField from "../../InputField/InputField";

export default function ParkingForm(props) {
  const {
    selectedParking,
    setSelectedParking,
    selectedProperty,
    setSelectedProperty,
    editingRecord,
    toggleParkingAdding,
    setEditingRecord,
    addParkingToCard,
    card,
    types,
  } = props;
  const [formData, setFormData] = useState({
    quantity: "",
    from_date: "",
    to_date: "",
  });
  const [error, setError] = useState(false);

  const property = useSelector((state) => state.currentProperty);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const { addParking, updateParking } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const onPut = () => {
    const { quantity, from_date, to_date } = formData;
    if (
      !quantity ||
      !selectedParking ||
      !selectedProperty ||
      (selectedProperty.name === "იჯარა" && (!from_date || !to_date))
    ) {
      setError(true);
      return;
    }
    if (editingRecord) {
      const dataToPut = {
        parking_id: selectedParking.id,
        id: editingRecord.id,
        quantity: Number(quantity),
        from_date,
        to_date,
        ownership_type:
          selectedProperty.name === "საკუთრება" ? "private" : "lease",
      };
      updateParking(user, dataToPut);

      if (typeof addParkingToCard === "function") {
        addParkingToCard({
          ...dataToPut,
          ownership_type: {
            en: selectedProperty.name === "იჯარა" ? "lease" : "private",
            ge: selectedProperty.name,
          },
          parking_type: {
            ge: selectedParking.name,
          },
        });
      }

      setFormData({ quantity: "", from_date: "", to_date: "" });
      setSelectedParking(null);
      setSelectedProperty(null);
      toggleParkingAdding();
      setEditingRecord(null);
      setError(false);
      return;
    }

    const dataToAdd = {
      ...formData,
      parking_id: selectedParking.id,
      parking_name: selectedParking.name,
      ownership_type_en:
        selectedProperty.name === "იჯარა" ? "lease" : "private",
      ownership_type_ge: selectedProperty.name,
      from_date: formData.from_date ? dateJSON(formData.from_date) : null,
      to_date: formData.to_date ? dateJSON(formData.to_date) : null,
      card_id: card ? card.id : property.card_id ? property.card_id : null,
    };

    addParking(user, dataToAdd);
    if (typeof addParkingToCard === "function") {
      addParkingToCard({
        ...dataToAdd,
        ownership_type: {
          en: selectedProperty.name === "იჯარა" ? "lease" : "private",
          ge: selectedProperty.name,
        },
        parking_type: {
          ge: selectedParking.name,
        },
      });
    }
    setFormData({ quantity: "", from_date: "", to_date: "" });
    setEditingRecord(null);
    setSelectedParking(null);
    setSelectedProperty(null);
    setError(false);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (editingRecord) {
      setFormData({
        quantity: editingRecord.quantity,
        from_date: editingRecord.from_date,
        to_date: editingRecord.to_date,
      });
      setSelectedParking({
        name: editingRecord.parking_type.ge,
        id: editingRecord.parking_id,
      });
      setSelectedProperty({
        name: editingRecord.ownership_type.ge,
        id: editingRecord.parking_id,
      });
    }
  }, [editingRecord]);

  return (
    <div className="parking-form">
      <div className="parking-type">
        <Dropdown
          isError={error}
          title="პარკინგის ტიპი"
          data={types}
          selected={selectedParking}
          setSelected={setSelectedParking}
        />
        <div className="parking-data-form">
          <div className="parking-dropdown">
            <Dropdown
              isError={error}
              title="საკუთრების ტიპი"
              data={propertyTypes}
              selected={selectedProperty}
              setSelected={setSelectedProperty}
            />
          </div>
          <div className="input-outer">
            <InputField
              name="quantity"
              type="number"
              value={formData?.quantity}
              onChange={onChange}
              className={`input ${error && "input-error"}`}
              placeholder="პარკინგის რაოდენობა"
            />
          </div>
        </div>
        <div
          className={
            selectedProperty?.name === "იჯარა"
              ? "parking-data-dates"
              : "reverse"
          }
        >
          {selectedProperty?.name === "იჯარა" && (
            <div className="date-inputs">
              <div className="input-outer input-date">
                <p>იჯარის თარიღიდან</p>
                <input
                  name="from_date"
                  type="date"
                  value={formData?.from_date}
                  className={`input ${error && "input-error"}`}
                  onChange={onChange}
                />
              </div>
              <div className="input-outer input-date">
                <p>იჯარის თარიღამდე</p>
                <input
                  name="to_date"
                  onChange={onChange}
                  type="date"
                  value={formData?.to_date}
                  className={`input ${error && "input-error"}`}
                />
              </div>
            </div>
          )}
          <div
            className="put-button put-date"
            onClick={onPut}
            style={{ justifySelf: "flex-end" }}
          >
            ჩასმა
          </div>
        </div>
      </div>
    </div>
  );
}
