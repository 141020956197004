import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "../../../../state";
import { bindActionCreators } from "redux";
import CategoryInput from "./CategoryInput";
import CategoriesList from "./CategoriesList";
import ServicesInputs from "./ServicesInputs";
import "./constructor.css";
import FeesColumn from "./FeesColumn";
import { getCategories } from "../../../../state/action-creators";

export default function ConstructorTable({ selectedTable, tab }) {
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const dispatch = useDispatch();
  const { getSubcategories, clearSubcategories } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const user = useSelector((state) => state.user.user);
  const categories = useSelector((state) => state.categories.items);
  const isLoading = useSelector((state) => state.categories.isLoading);
  const isfee = tab === "fees";
  const subcategories = useSelector((state) => state.subcategories.items);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editingSubcategory, setEditingSubcategory] = useState(null);

  const categoriesRef = useRef(null);
  const subcategoriesRef = useRef(null);

  const selectCategory = (parent_id) => {
    if (selectedParent && parent_id === selectedParent) {
      clearSubcategories();
      setSelectedParent(null);
      setSelectedChild(null);
      return;
    }
    if (isfee) {
      getSubcategories(user, parent_id, isfee);
    } else {
      getSubcategories(user, parent_id);
    }
    setSelectedParent(parent_id);
    setSelectedChild(null);
    setEditingSubcategory(null);
  };

  const selectSubcategory = (id) => {
    if (selectedChild && id === selectedChild) {
      setSelectedChild(null);
      return;
    }
    setSelectedChild(id);
  };

  useEffect(() => {
    setSelectedChild(null);
    setSelectedParent(null);
  }, [isLoading]);

  useEffect(() => {
    dispatch(getCategories(user, isfee));
  }, []);

  useEffect(() => {
    categoriesRef.current.scrollTo(0, 0);
  }, [editingCategory]);

  useEffect(() => {
    subcategoriesRef.current.scrollTo(0, 0);
  }, [editingSubcategory]);

  return (
    <div className="table constructor-table">
      <div className="categories constructor-col" ref={categoriesRef}>
        <p className="constructor-col-title">კატეგორიები</p>
        {tab === "services" && (
          <CategoryInput
            editing={editingCategory}
            setEditing={setEditingCategory}
          />
        )}
        <CategoriesList
          isfee={isfee}
          isParent={true}
          items={categories}
          selectCategory={selectCategory}
          selected={selectedParent}
          editing={editingCategory}
          setEditing={setEditingCategory}
        />
      </div>
      <div className="subcategories constructor-col" ref={subcategoriesRef}>
        <p className="constructor-col-title">ქვეკატეგორიები</p>
        {tab === "services" && selectedParent && (
          <CategoryInput
            parent_id={selectedParent}
            editing={editingSubcategory}
            setEditing={setEditingSubcategory}
          />
        )}
        <CategoriesList
          isParent={false}
          items={subcategories}
          selectCategory={selectSubcategory}
          selected={selectedChild}
          editing={editingSubcategory}
          setEditing={setEditingSubcategory}
        />
      </div>
      <div className="data constructor-col no-right-border">
        <p className="constructor-col-title">მონაცემები</p>
        {selectedChild && !isfee && (
          <ServicesInputs
            parent_id={selectedParent}
            subcategory_id={selectedChild}
            set_subcategory_id={selectSubcategory}
          />
        )}
        {selectedChild && isfee && <FeesColumn id={selectedChild} />}
      </div>
    </div>
  );
}
