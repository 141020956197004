import './Ratings.css'
import RatingsCard from '../../../../../components/RatingsCard/RatingsCard';
import {getRatings, getSubRatings} from '../../../../../state/action-creators/ratingsActions'
import { useDispatch,useSelector } from 'react-redux';
import { useEffect,useState } from 'react';

const Ratings = () => {
     const dispatch=useDispatch();
     const user=useSelector(state=>state.user.user);
     const ratings=useSelector(state=>state.ratings.ratings);
     const [isParentPage, setIsParentPage]=useState(true);
     const [nameOfSubCategory, setName]=useState("მოთხოვნების ჯამური მაჩვენებელი")
     var sum={"5":0, "4":0, "3":0, "2":0, "1":0, "0":0}
   //   console.log(sum[5])
     useEffect(()=>{
        dispatch(getRatings(user))
     },[]);
   //   console.log(ratings)
     const calculateSum=()=>{
      for (var i in ratings){
         for (var [key,value] of Object.entries(ratings[i].rating)){
             sum[key]+=value
         }
      }
     }
     calculateSum()
    
    return ( 
     <div className="ratings-container">
      <RatingsCard id={0} isFirstCard={true} name={nameOfSubCategory} setName={setName} isParentPage={isParentPage} setIsParentPage={setIsParentPage}
                      five ={sum[5]} four={sum[4]} three={sum[3]} two={sum[2]} one={sum[1]} 
                      zero={sum[0]} getSubratings={getSubRatings} getRatings={getRatings}/>
         {ratings.map((a)=>
         <RatingsCard key={a.id} id={a.id} isFirstCard={false} setName={setName} name={a.name.ge} isParentPage={isParentPage} setIsParentPage={setIsParentPage}
                     five ={a.rating[5]} four={a.rating[4]} three={a.rating[3]} 
                      two={a.rating[2]} one={a.rating[1]} zero={a.rating[0]} getSubratings={getSubRatings} getRatings={getRatings}/>
       
        
                      )
        
        }
        
     </div>
        
     );
}
 
export default Ratings;