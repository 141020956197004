import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequests, setRequestsParams } from '../../../state/action-creators';
import { DateInput } from '../services-tables/filters/LeasesFilter';
import { dateJSON } from '../../../libs/Functions';

export default function RequestFeesFilter() {
  const [params, setParams] = useState({
    request_id: '', from_requesting_datetime: '',
    to_requesting_datetime: '', transaction_id: '',
  });
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);

  const onChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const resetFilter = () => {
    setParams({
        request_id: '', from_requesting_datetime: '',
        to_requesting_datetime: '', transaction_id: '',
      });
  }

  const onSearch = () => {
    const temp_params = {
        load_from: 0,
        status: "active,processing,done",
        request_id: Number(params.request_id)===0 ? null : Number(params.request_id),
        from_requesting_datetime: dateJSON(params.from_requesting_datetime),
        to_requesting_datetime: dateJSON(params.to_requesting_datetime),
        transaction_id: Number(params.transaction_id)===0 ? null : Number(params.transaction_id),
    }
    
    dispatch(setRequestsParams(temp_params));
    dispatch(getRequests(user, temp_params));

  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      onSearch()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleEnter);
    };
  }, [params])

  return (
    <div className='filter-container'>
        <div className='filter-row'>
            <div className='filter-input-container'>
                <input type="text" className='filter-input' placeholder='მოთხოვნის ნომერი'
                value={params.request_id} onChange={onChange} name="request_id"
                />
            </div>
            <DateInput 
            placeholder="მოთხოვნის თარიღიდან"
            name="from_requesting_datetime" onChange={onChange}
            value={params.from_requesting_datetime}
            />
            <DateInput 
            placeholder="მოთხოვნის თარიღამდე"
            name="to_requesting_datetime" onChange={onChange}
            value={params.to_requesting_datetime}
            />
            <div className='filter-buttons'>
                <div className='filter-clear-button' onClick={resetFilter}>გასუფთავება</div>
                <div className='put-button' onClick={onSearch}>ძებნა</div>
            </div>
        </div>
        <div className='filter-row'>
            <div className='filter-input-container'>
                <input type="text" className='filter-input' placeholder='ტრანზაქციის ნომერი'
                value={params.transaction_id} onChange={onChange} name="transaction_id"
                />
            </div>
            <div className='filter-input-container' style={{visibility: 'hidden'}}></div>
            <div className='filter-input-container' style={{visibility: 'hidden'}}></div>
            <div className='filter-input-container' style={{visibility: 'hidden'}}></div>
        </div>
    </div>
  )
}
