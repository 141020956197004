import React from "react";
import bin from "../../../assets/bin.png";
import edit_icon from "../../../assets/edit_icon.png";
import edit_icon_filled from "../../../assets/filled_edit_icon.png";
import { dateToString } from "../../../libs/Functions";
import Checkbox from "../../Checkbox/Checkbox.js";
import { useDispatch, useSelector } from "react-redux";
import { toggleResponsiblePerson } from "../../../state/action-creators/propertyActions.js";

export default function ParkingList({
  parking_spaces,
  parkingEdit,
  setDeletingRecord,
  parkingEditingRecord,
  isLease,
  selectedResponsible,
  card_id,
}) {
  const dispatch = useDispatch();

  const property = useSelector((state) => state.currentProperty);

  let parking_responsibilities;

  if (!selectedResponsible?.representative) {
    parking_responsibilities = property.users.filter(
      (user) => user.id === selectedResponsible?.id
    )[0]?.parking_responsibilities;
  } else {
    parking_responsibilities = property.organizations.filter(
      (org) => org.id === selectedResponsible?.id
    )[0]?.parking_responsibilities;
  }

  const onCheck = (parking) => {
    if (selectedResponsible?.representative) {
      dispatch(
        toggleResponsiblePerson({
          card_id: card_id,
          organization_id: selectedResponsible.id,
          parking_id: parking.id,
        })
      );
      return;
    }
    dispatch(
      toggleResponsiblePerson({
        card_id: card_id,
        user_id: selectedResponsible.id,
        parking_id: parking.id,
      })
    );
  };

  return (
    <>
      {parking_spaces?.map((parking, i) => (
        <div key={i} className="card-item">
          <div className="d-flex-centered">
            {selectedResponsible && (
              <Checkbox
                label={""}
                onClick={() => onCheck(parking)}
                isChecked={parking_responsibilities?.includes(parking.id)}
              />
            )}
            <p
              className={
                parkingEditingRecord === null
                  ? ""
                  : parkingEditingRecord?.id === parking.id
                  ? "editing"
                  : ""
              }
              style={{ marginTop: "10px" }}
            >
              {parking.parking_type.ge + " პარკინგი"} | {parking.quantity} |{" "}
              {parking.ownership_type.ge}
              {parking.ownership_type.ge === "იჯარა"
                ? ` | ${dateToString(parking.from_date)} - ${dateToString(
                    parking.to_date
                  )}`
                : ""}
            </p>
          </div>
          <div className="edit-icons">
            {!isLease && (
              <img
                src={
                  parkingEditingRecord === null
                    ? edit_icon
                    : parkingEditingRecord?.id === parking.id
                    ? edit_icon_filled
                    : edit_icon
                }
                alt="edit"
                className="edit-icon"
                onClick={() => parkingEdit(parking)}
              />
            )}
            {!isLease && (
              <img
                src={bin}
                alt="bin"
                className="bin"
                onClick={() =>
                  setDeletingRecord({ name: "parking", obj: parking })
                }
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
}
