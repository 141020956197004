import React from "react";
import "./Dashboard.css";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Services from "../Services/Services";
import Tutorials from "../Tutorials/Tutorials";
import Payments from "../Payments/Payments";
import Footer from "../../components/Footer/Footer.js";
import Notifications from "../Notifications/Notifications.js";

const Dashboard = () => {
  const { module, attribute, tab } = useParams();

  return (
    <div className="page-container">
      <div>
        <Header />
        {module === "services" && <Services attribute={attribute} tab={tab} />}
        {module === "how-to-do" && <Tutorials />}

        {module === "payments" && <Payments attribute={attribute} tab={tab} />}

        {module === "notifications" && (
          <Notifications attribute={attribute} tab={tab} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
