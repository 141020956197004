import { useRef, useState } from 'react';
import useToggle from '../../../../hooks/useToggle.js';
import arrow from '../../../../assets/arrow.png';
import useOutsideClick from '../../../../hooks/useOutsideClick.js';
import close from '../../../../assets/close.png';
import { useSelector, useDispatch } from 'react-redux';
import { addExecutor, removeExecutor } from '../../../../state/action-creators/executorsActions.js';
import { assignExecutor, completeRequest, approveRequest, rejectRequest } from '../../../../state/action-creators/requestActions.js';
import Modal from 'react-modal';
import RateStars from './RateStars.js';
import Confirmation from '../../../ConfirmationModal/Confirmation.js';
import RejectForm from './RejectForm.js';

const date = (utc) => {
    const d = new Date(utc);
    const day = d.getDate().toString().padStart(2, '0')
    const month = (d.getMonth()+1).toString().padStart(2, '0');
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${d.getFullYear()} ${hours}:${minutes}`
}


export function SubcategoriesList({ category }) {
    const { subcategories, name} = category;
  
    return (
      <div>
        <div className='card-main-category'>
          <img src={category.icon} alt='icon' className='card-category-icon'/>
          <p>{name.ge}</p>
        </div>
        {subcategories.map((sub) => 
          <div className='card-sub-category' key={sub.id}>
            <img src={sub.icon} alt='icon' className='card-category-icon'/>
            <p>{sub.name.ge}</p>
          </div>)}
      </div>
    )
}

export function RequestData(props) {
    const { datetime, deadline, status, id } = props;
    
    return (
        <div>
            <div className="card-main-category">
                <p>მოთხოვნის ნომერი: {id}</p>
            </div>
            <div className="card-main-category">
                <p>მოთხოვნის თარიღი: {date(datetime)}</p>
            </div>
            <div className="card-main-category">
                <p>რეაგირების ვადა: {date(deadline)}</p>
            </div>
            <div className="card-main-category">
                <p style={{color: 'var(--errorColor)'}}>სტატუსი: {status}</p>
            </div>
        </div>
    )
}

export function UserData({ user, apartment }) {
    const { name, surname, pers_id } = user;
    const { complex_type, floor, number } = apartment;

    return (
        <div>
            <div className="card-main-category">
                <p>სახელი და გვარი: {name + " " + surname}</p>
            </div>
            <div className="card-main-category">
                <p>პირადი ნომერი: {pers_id}</p>
            </div>
            <div className="card-main-category">
                <p>კომპლექსის ტიპი: {complex_type.ge}</p>
            </div>
            <div className="card-main-category">
                <p>სართული: {floor}</p>
            </div>
            <div className="card-main-category">
                <p>ნომერი: {number}</p>
            </div>

        </div>
    )
}

export function RequestReaction({ id, request }) {
    const [showApproveConfirm, toggleShowApproveConfirm] = useToggle(false);
    const [showRejection, toggleShowRejection] = useToggle(false);
    const [reason, setReason] = useState('');
    const dispatch = useDispatch();

    const status = request.status.en;

    const payment_datetime = date(request.payment_deadline_datetime);
    const reaction_datetime = date(status === "rejected" ? request.rejection_datetime : request.approval_datetime);

    const onApprove = () => {
        dispatch(approveRequest(id));
        toggleShowApproveConfirm(false);
    }

    const onReject = () => {
        dispatch(rejectRequest(id, reason));
        toggleShowRejection(false);
    }

    return (
        <>
        {status === "pending_approval" && 
        <div className='request-reaction-column'>
            <Confirmation 
                 content={"ნამდვილად გსურთ მოთხოვნის დადასტურება?"}
                 showConfirm={showApproveConfirm}
                 toggleShowConfirm={toggleShowApproveConfirm}
                 onConfirm={onApprove}
            />
            <RejectForm 
                content={"გთხოვთ მიუთითეთ უარყოფის მიზეზი"}
                isOpen={showRejection}
                onChange={(e) => setReason(e.target.value)}
                onConfirm={onReject}
                toggleIsOpen={toggleShowRejection}
                value={reason}
                className={'rejection-textarea'}
            />
            <div className='request-approve-button' onClick={toggleShowApproveConfirm}>
                დადასტურება
            </div>
            <div className='request-reject-button' onClick={toggleShowRejection}>
                უარყოფა
            </div>
        </div>}
        {(status === "pending_payment" || status==="unpaid" || status === "rejected") &&
         <div>
            <div className='card-main-category'>
                რეაგირების თარიღი: {reaction_datetime}
            </div>
            {status !== "rejected" ? 
            <div className='card-main-category'>
                გადახდის ვადა: {payment_datetime}
            </div> : 
            <div style={{color: 'var(--errorColor)', marginTop: 10}}>
                <p>უარყოფის მიზეზი:</p>
                <p>{request.rejection_reason}</p>
            </div> }
        </div> }
        </>
    )
}

export function ExecutorSelect({ request_id, executor, status, datetime, rate }) {
    const [showDrop, toggleShowDrop] = useToggle();
    const [name, setName] = useState('');
    const [selected, setSelected] = useState(null);
    const {items} = useSelector(state => state.executors);
    const [showConfirm, toggleShowConfirm] = useToggle();
    const xRef = useRef(null);
    const dropRef = useRef(null);
    useOutsideClick(dropRef, () => toggleShowDrop(false));
    const dispatch = useDispatch();
    

    const onSelect = (event, e) => {
        if (event.target === xRef.current) {
            return;
        };
        setSelected(e);
        toggleShowDrop(false);
    }

    const onAdd = () => {
        if (name.split(' ').length < 2) {
            return;
        }
        const [first, second] = name.split(' ');
        setName('');
        const data = {name: first, surname: second};
        dispatch(addExecutor(data));
    }

    const assign = () => {
        if (!selected) return;
        dispatch(assignExecutor(request_id, selected.id));
        setSelected(null);
    }

    const onExecutorDelete = (id) => {
        dispatch(removeExecutor(id));
    }

    const onComplete = () => {
        dispatch(completeRequest(request_id));
        toggleShowConfirm(false);
    }

    return ( 
        <div className='executor'>
        <Modal 
                isOpen={showConfirm}
                overlayClassName='modal-overlay'
                ariaHideApp={false}
                className={'modal-content'}
                shouldCloseOnOverlayClick={true}
        >
            <div className='confirm-modal'>
                <p>დარწმუნებული ხართ, რომ მოთხოვნა</p>
                <p style={{marginTop: '-30px'}}>სრულად არის შესრულებული?</p>
                <div className='confirm-button' onClick={onComplete}>დიახ</div>
                <img src={close} alt='close' className='close-icon' width={15} onClick={toggleShowConfirm}/>
            </div>
        </Modal>
        {status==="active" && 
        <>
        <div className="executor-selector">
            <div className='card-main-category executor-selector' 
            onClick={toggleShowDrop} style={{width: '100%'}}>
                <p>{selected ? selected.name+" "+selected.surname:"სამუშაოს შემსრულებელი"}</p>
                <img src={arrow} alt='arrow' className='executor-selector-arrow'/>
            </div>
            {showDrop && 
            <div className='executor-drop' ref={dropRef}>
                <div className='executors-list'>
                    {items.map((e) => 
                    <p key={e.id} onClick={(event) => onSelect(event, e)} className='executor-name'>
                        {e.name+" "+e.surname} 
                        <span onClick={() => onExecutorDelete(e.id)} ref={xRef}
                        className='executor-delete-icon'>✕</span>
                    </p>)}
                </div>
                <div className='executor-form'>
                    <input type='text' onChange={(e) => setName(e.target.value)}
                    placeholder='სახელი და გვარი' value={name}
                    className='executor-input'/>
                    <div className='executor-add-button' onClick={onAdd}>დამატება</div>
                </div>
            </div>}
        </div>
        
        <div className={`assign-button ${!selected && 'button-disabled'}`} onClick={assign}>
            მინიჭება
        </div>
        </>}
        {(status==="processing" || status==="done" || status==="closed") && 
        <>
        <div>
            {executor && 
            <div className='card-main-category'>
                <p>{executor.name + " " + executor.surname}</p>
            </div>}
            <div className='card-main-category'>
                <p>მინიჭების თარიღი: {date(datetime)}</p>
            </div>
        </div>
        {(status==="closed") && <RateStars rate={rate}/>}
        {status==="processing" && 
        <div className='assign-button' onClick={toggleShowConfirm}
        >შესრულდა</div>}
        </>

        }
        </div>    
    )
}