import React from "react";

export default function LeaserIcon({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 13L11 17V14H2V12H11V9L15 13ZM5 20V16H7V18H17V10.19L12 5.69L7.21 10H4.22L12 3L22 12H19V20H5Z"
        fill={fill}
      />
    </svg>
  );
}
