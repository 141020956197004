import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCategories } from "../../../../state/action-creators/categoriesActions";
import FeeIcon from "../../../Icons/FeeIcon";
import ServiceIcon from "../../../Icons/ServiceIcon";
import TaxReliefIcon from "../../../Icons/TaxReliefIcon";

const orange = "#DAA161";
const black = "#333333";

export default function ControlbarForConstructor(props) {
  const { selectedTable, setSelectedTable } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const { tab } = useParams();

  const onBarClick = (index) => {
    setSelectedTable(index);
    switch (index) {
      case 0:
        navigate("/dashboard/services/constructor/relief-fees");
        return;
      case 1:
        dispatch(getCategories(user, true));
        navigate("/dashboard/services/constructor/fees");
        return;
      case 2:
        dispatch(getCategories(user));
        navigate("/dashboard/services/constructor/services");
        return;
      default:
        return;
    }
  };

  const onReliefFeesClick = () => onBarClick(0);
  const onPaymentsClick = () => onBarClick(1);
  const onServicesClick = () => onBarClick(2);

  return (
    <>
      <div className="controlbar-left"></div>
      <div className="controlbar-right">
        <div
          className={
            tab === "relief-fees" ? "selector selector-selected" : "selector"
          }
          onClick={onReliefFeesClick}
        >
          <TaxReliefIcon fill={tab === "relief-fees" ? orange : black} />
          <p>გადასახადიდან გათავისუფლება</p>
        </div>
        <div
          className={tab === "fees" ? "selector selector-selected" : "selector"}
          onClick={onPaymentsClick}
        >
          <FeeIcon fill={tab === "fees" ? orange : black} />
          <p>გადასახადები</p>
        </div>
        <div
          className={
            tab === "services" ? "selector selector-selected" : "selector"
          }
          onClick={onServicesClick}
        >
          <ServiceIcon fill={tab === "services" ? orange : black} />
          <p>სერვისები</p>
        </div>
      </div>
    </>
  );
}
