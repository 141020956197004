import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'
import home from '../../assets/home.png'
import { useDispatch } from 'react-redux';
import { clearInvoices, clearRequests, resetInvoiceState, resetRequestState } from '../../state/action-creators';
import { clearProperties } from '../../state/action-creators';

export default function Navbar(props) {
  const { title, attributes, setSelected, resetTables, module, toggleShowFilter, attribute } = props;
  const dispatch = useDispatch();
 
  const onAttributeClick = (i) => {
    dispatch(clearRequests());
    dispatch(clearProperties());
    dispatch(clearInvoices());
    if(toggleShowFilter) {
      toggleShowFilter(false);
    }
    resetTables();
    setSelected(i);
  }

  const resetState = () => {
    dispatch(resetInvoiceState());
    dispatch(resetRequestState());
  }

  return (
    <nav className='navbar-container'>
        <div className='navbar-left header-left'>
            <Link to='/home' className='link' onClick={resetState}>
                <img src={home} alt="homeicon" className='home-icon'/>
            </Link>
            <p style={{fontSize: '13px'}}>{title}</p>
        </div>
        <div className='navbar-right header-right'>
          {
            attributes.map((a, i) => 
              <Link key={i} to={`/dashboard/${module}/${a.name.en}/${a.tabs[0].en}`} className="text-link">
                <div 
                  onClick={() => onAttributeClick(i)}
                  className={attribute === a.name.en ? 'nav-selected':''}
                >
                  
                  {a.name.ge}
                </div>
              </Link>
            )
          }
        </div>
    </nav>
  )
}
