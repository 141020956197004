import React, { useEffect, useState } from 'react'
import './filter.css';
import FilterDropdown from './FilterDropdown.js';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../../state';
import { bindActionCreators } from 'redux';
import { legalStatuses, countrys, propertyTypes, callingCodes, uses } from '../../../../constants';
import { getTypes } from '../../../../libs/API';
import { filteredObject } from '../../../../libs/Functions';

export const PhoneInput = ({selected, setSelected, indexes, value, onChange}) => {
  return (
    <div className='phone-input-container'>
      <div className='phone-index-dropdown'>
        <FilterDropdown 
            title="ქვეყნის ინდექსი"
            fontSize={11}
            data={indexes}
            selected={selected}
            setSelected={setSelected}
          />
      </div>
      <input className='phone-input' name="phone" placeholder='ტელეფონის ნომერი' value={value} onChange={onChange}/>
    </div>
  )
}

export default function OwnershipFilter() {
  const [legalStatus, setLegalStatus] = useState(null);
  const [apartmentType, setApartmentType] = useState(null);
  const [parkingType, setParkingType] = useState(null);
  const [responsible, setResponsible] = useState(null);
  const [parkingPropertyType, setParkingPropertyType] = useState(null);
  const [apartmentTypes, setApartmentTypes] = useState([]);
  const [parkingTypes, setParkingTypes] = useState([]);
  const [use, setUse] = useState(null);
  const [index, setIndex] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const cards = useSelector(state => state.properties);

  const { getProperties, setParametres } = bindActionCreators(actionCreators, dispatch)

  const [params, setParams] = useState({
    name: '', surname: '', pers_id: '', phone: '',
    organization_name: '', organization_code: '',
    complex_number: '',
  })

  const indexes = Object.keys(countrys).map((e) => ({name: countrys[e], id: e}))


  useEffect(() => {
    getTypes((data) => {
      setParkingTypes(data.parking.map((e) => ({name: e.type.ge, id: e.id}) ))
      setApartmentTypes(data.complex.map((e) => ({name: e.type.ge, id: e.id}) ))
  });
  }, [])

  const onChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }
  
  const resetSearch = () => {
    setParams({
      name: '', surname: '', pers_id: '', phone: '',
      organization_name: '', organization_code: '',
      complex_number: '',
    });
    setIndex(null); setLegalStatus(null);
    setApartmentType(null); setParkingType(null);
    setParkingPropertyType(null); setResponsible(null);
    setUse(null);
  }

  const onSearch = () => {
    const tempParams = {
      load_from: 0,      
      status: cards.status,
      ownership_type: cards.ownership_type,
      user_name: params.name,
      user_surname: params.surname,
      user_pers_id: params.pers_id,
      user_status: legalStatus ? (legalStatus.name === "ფიზიკური პირი" ? "individual" : "legal"):null,
      user_full_phone: params.phone ? `${callingCodes[index.id]}${params.phone}`:null,
      organization_name: params.organization_name,
      organization_identity_num: params.organization_code,
      parking_type_id: parkingType?.id,
      parking_ownership_type: parkingPropertyType ? (parkingPropertyType.name==="საკუთრება" ? "private":"lease"):null,
      complex_type_id: apartmentType?.id,
      user_is_verified: use?.id,
      user_responsible_person: responsible?.id,
      apartment_number: params.complex_number,
    }
    setParametres(filteredObject(tempParams));

    getProperties(user, filteredObject(tempParams));
  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      onSearch()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleEnter);
    };
  }, [params, apartmentType, parkingType, responsible, use])

  return (
    <div className='filter-container'>
        <div className='filter-row'>
          <FilterDropdown
          title="იურიდიული სტატუსი"
          data={legalStatuses}
          selected={legalStatus}
          setSelected={setLegalStatus}
          />
          <div className='filter-input-container'>
            {/* {params.name && <div className='filter-input-title'>სახელი</div>} */}
            <input className='filter-input'
            placeholder='სახელი' name='name'
            onChange={onChange} value={params.name}
            />
          </div>
          <div className='filter-input-container'>
            <input className='filter-input'
            placeholder='გვარი' name='surname'
            onChange={onChange} value={params.surname}
            />
          </div>
            <div className='filter-input-container'>
              <input className='filter-input'
              placeholder='პირადი ნომერი' name='pers_id'
              onChange={onChange} value={params.pers_id}
            />
          </div>
        </div>
        <div className='filter-row'>
          <FilterDropdown
          title="კომპლექსის ტიპი"
          data={apartmentTypes}
          selected={apartmentType}
          setSelected={setApartmentType}
          />
          <div className='filter-input-container'>
            <input className='filter-input' onChange={onChange}
            placeholder='ორგანიზაციის დასახელება' name='organization_name'
            value={params.organization_name}
            />
          </div>
          <div className='filter-input-container'>
            <input className='filter-input' onChange={onChange}
            placeholder='ორგანიზაციის საიდენტიფიკაციო კოდი' name='organization_code'
            value={params.organization_code}
            />
          </div>
          <FilterDropdown 
          title="აპლიკაციით სარგებლობა"
          data={uses}
          selected={use}
          setSelected={setUse}
          />
        </div>
        <div className='filter-row'>
          <PhoneInput
          indexes={indexes}
          selected={index}
          setSelected={setIndex}
          value={params.phone}
          onChange={onChange}
          />
          <FilterDropdown 
          title="პარკინგის ტიპი"
          data={parkingTypes}
          selected={parkingType}
          setSelected={setParkingType}
          />
          <FilterDropdown 
          title="პარკინგის საკუთრების ტიპი"
          data={propertyTypes}
          setSelected={setParkingPropertyType}
          selected={parkingPropertyType}
          />
          <FilterDropdown 
          title="პასუხისმგებელი პირი"
          data={uses}
          selected={responsible}
          setSelected={setResponsible}
          />
        </div>
        <div className='filter-row'>
          <input className='filter-input' onChange={onChange}
              placeholder='ბინის/ფართის ნომერი' name='complex_number'
              value={params.complex_number}
          />
          <input className='filter-input' style={{visibility: 'hidden'}}/>
          <input className='filter-input' style={{visibility: 'hidden'}}/>
          <div className='filter-buttons'>
            <div className='filter-clear-button' onClick={resetSearch}>გასუფთავება</div>
            <div className='put-button' onClick={onSearch}>ძებნა</div>
          </div>
        </div>
        
    </div>
  )
}
