import React from "react";
import { phoneToString } from "../../../../libs/Functions";
import phone_icon from "../../../../assets/phoneIcon.png";
import Checkbox from "../../../Checkbox/Checkbox.js";
import { useDispatch } from "react-redux";
import { toggleAddresate } from "../../../../state/action-creators/addresateActions.js";

function Addresate({ self }) {
  const dispatch = useDispatch();

  const onCheckClick = () => {
    dispatch(toggleAddresate(self.id));
  };

  return (
    <div className="addresate">
      <Checkbox
        style={{ flexGrow: "0", marginBottom: "8px" }}
        isChecked={self.isSelected}
        onClick={onCheckClick}
      />
      <img src={phone_icon} alt="phone_icon" />
      <p>
        {`${self.name} ${self.surname} | ${self.pers_id} | ${phoneToString(
          self.country,
          self.phone
        )}`}{" "}
        {self?.organizations?.map((org, i) => (
          <span key={i}>{`| ${org.name} | ${org.identity_num}`}</span>
        ))}
      </p>
    </div>
  );
}

export default Addresate;
