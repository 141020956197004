import './OwnersCard.css'
import formclose from '../../assets/close.png'
import useOutsideClick from '../../hooks/useOutsideClick';
import { useRef } from 'react';

const RegistrationModal = ({settingDate, showDate, status, setStatus}) => {
    const ref = useRef(null);
    useOutsideClick(ref, () => setStatus("box-off"))

    return ( 
        <div className={`rectangle_box ${status}`} ref={ref}>
            <div className="box-close" onClick={(event)=>{event.stopPropagation(); showDate(settingDate)}}><img src={formclose} alt="" /></div>
            <p className="application-date">აპლიკაციის დაყენების თარიღი</p>
            <p>{settingDate}</p>
            

        </div>
     );
}
 
export default RegistrationModal;