import React, { useEffect, useState } from "react";
import FilterInput from "../../services-tables/filters/FilterInput";
import FilterDropdown from "../../services-tables/filters/FilterDropdown";
import { getTypes } from "../../../../libs/API";
import { getAddresates } from "../../../../state/action-creators/addresateActions";
import { useDispatch } from "react-redux";
import { filteredObject } from "../../../../libs/Functions.js";
import { userTypes } from "../../../../constants";

function AddresateFIlter() {
  const [complexTypes, setComplexTypes] = useState([]);
  const [complexType, setComplexType] = useState(null);

  const [userType, setUserType] = useState(null);

  const dispatch = useDispatch();

  const [params, setParams] = useState({
    floor: "",
    apartment_number: "",
    first_name: "",
    last_name: "",
    full_phone: "",
  });

  const onChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSearch = () => {
    const tempParams = filteredObject({
      ...params,
      user_type: userType?.name_en,
      complex_type: complexType?.id,
    });
    dispatch(getAddresates(tempParams));
  };

  const clearFilter = () => {
    setParams({
      floor: "",
      apartment_number: "",
      first_name: "",
      last_name: "",
      full_phone: "",
    });
    setComplexType(null);
    setUserType(null);
  };

  useEffect(() => {
    getTypes((data) =>
      setComplexTypes(
        data.complex.map((t) => ({
          name: t.type.ge,
          name_en: t.type.en,
          id: t.id,
        }))
      )
    );
    dispatch(getAddresates({}));
  }, []);

  return (
    <div className="addresate-filter">
      <label>ადრესატების მიზნობრივი ჯგუფი</label>
      <div className="addresate-filter-inputs">
        <div className="addresate-filter-row">
          <FilterDropdown
            title={"კომპლექსის ტიპი"}
            data={complexTypes}
            selected={complexType}
            setSelected={setComplexType}
          />
          <FilterInput
            type={"text"}
            placeholder={"სართული"}
            name={"floor"}
            onChange={onChange}
            value={params.floor}
          />
          <FilterInput
            type={"text"}
            placeholder={"ფართის ნომერი"}
            name={"apartment_number"}
            value={params.apartment_number}
            onChange={onChange}
          />
        </div>
        <div className="addresate-filter-row">
          <FilterInput
            type={"text"}
            placeholder={"სახელი"}
            name={"first_name"}
            value={params.first_name}
            onChange={onChange}
          />
          <FilterInput
            type={"text"}
            placeholder={"გვარი"}
            name={"last_name"}
            value={params.last_name}
            onChange={onChange}
          />
          <FilterInput
            type={"number"}
            placeholder={"ტელეფონის ნომერი"}
            name={"full_phone"}
            value={params.full_phone}
            onChange={onChange}
          />
        </div>
        <div className="addresate-filter-row">
          <FilterDropdown
            title={"მომხმარებლის ტიპი"}
            data={userTypes}
            setSelected={setUserType}
            selected={userType}
          />

          <div className="addresate-filter-clear-button" onClick={clearFilter}>
            გასუფთავება
          </div>
          <div className="addresate-put-button" onClick={onSearch}>
            ძებნა
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddresateFIlter;
