import React, { useEffect, useState } from "react";
import {
  CommunalFeeType,
  FeeType,
  InvoiceData,
  TransactionData,
  UserData,
} from "./InvoiceColumns";
import { useSelector, useDispatch } from "react-redux";
import { RequestData } from "./InvoiceColumns";
import {
  saveInvoice,
  toggleClosingCandidate,
} from "../../../state/action-creators/invoiceActions";
import edit_icon from "../../../assets/edit_icon.png";
import pdf_icon from "../../../assets/pdf_icon.png";
import invoice_cancel from "../../../assets/invoice_cancel.png";
import "./payments.css";
import useToggle from "../../../hooks/useToggle";
import DownloadButton from "../../DownloadButton/DownloadButton";
import CancelForm from "./CancelForm";
import { cancelInvoice } from "../../../state/action-creators/invoiceActions";

function InvoiceCard({
  invoice,
  onCheck,
  checkFlag,
  onUncheck,
  closingChecked,
  checked,
  attribute,
  tab,
}) {
  const { invoice_type } = useSelector((state) => state.invoices.params);
  const [isChecked, setIsChecked] = useState(false);
  const [editing, toggleEditing] = useToggle();
  const dispatch = useDispatch();
  const [updateData, setUpdateData] = useState(
    invoice?.communal_fees?.map((fee) => ({
      id: fee.id,
      previous_value: fee.previous_value.toFixed(2).toString() || "",
      present_value: fee.present_value.toFixed(2).toString() || "",
    }))
  );
  const [showCancelForm, toggleShowCancelForm] = useToggle();
  const [cancelationReason, setCancelationReason] = useState("");

  useEffect(() => {
    if (checkFlag) {
      setIsChecked(true);
    } else {
      onUncheck(invoice.id);
      setIsChecked(false);
    }
  }, [checkFlag]);

  const onClick = () => {
    if (invoice.isSelectedForClosing !== undefined && closingChecked) {
      dispatch(toggleClosingCandidate(invoice.id));
    }
    if (checked) {
      if (isChecked) {
        setIsChecked(false);
        onUncheck(invoice.id);
      } else {
        setIsChecked(true);
        onCheck({ id: invoice.id, comment: invoice.comment });
      }
    }
  };

  const onCancelInvoiceClick = (e) => {
    e.stopPropagation();
    toggleShowCancelForm();
  };

  const handleCancel = () => {
    dispatch(cancelInvoice(invoice.id, cancelationReason));
    toggleShowCancelForm(false);
    setCancelationReason("");
  };

  const myToggleCancelForm = () => {
    if (showCancelForm) {
      setCancelationReason("");
    }
    toggleShowCancelForm();
  };

  const onSave = () => {
    dispatch(saveInvoice(invoice.id, updateData));
    toggleEditing(false);
  };

  return (
    <div
      className={`${
        attribute === "fees" ? "request-invoice-card" : "invoice-card"
      } ${isChecked || invoice.isSelectedForClosing ? "card-selected" : ""}`}
      onClick={onClick}
      style={{ cursor: "default" }}
    >
      {attribute !== "fees" && (
        <>
          <div className="request-column">
            <InvoiceData invoice={invoice} />
          </div>
          <div className="request-column">
            <UserData invoice={invoice} />
          </div>
          <div className="request-column">
            {attribute === "additional" && <div>{invoice.description}</div>}
            <div className="invoice-edit-buttons">
              {!editing && invoice.status?.ge === "დასადასტურებელი" && (
                <img
                  src={edit_icon}
                  className="invoice-edit-icon"
                  alt="edit"
                  onClick={() => toggleEditing(true)}
                />
              )}
              {editing && (
                <div className="save-button" onClick={onSave}>
                  შენახვა
                </div>
              )}
            </div>
            {(invoice_type === "service" ||
              invoice_type === "apartment" ||
              invoice_type === "parking") && <FeeType invoice={invoice} />}
            {invoice_type === "communal" && (
              <CommunalFeeType
                invoice={invoice}
                editing={editing}
                data={updateData}
                setData={setUpdateData}
              />
            )}
          </div>
        </>
      )}

      {attribute === "fees" && (
        <div className="request-column">
          <RequestData request={invoice} />
        </div>
      )}
      <div className="request-column no-right-border">
        <TransactionData
          invoice={invoice}
          type={invoice_type}
          attribute={attribute}
          tab={tab}
        />
      </div>
      {invoice.status.en !== "cancelled" && (
        <div className="cancel-invoice" onClick={onCancelInvoiceClick}>
          <img alt="cancel-invoice" src={invoice_cancel} />
        </div>
      )}
      <CancelForm
        isOpen={showCancelForm}
        value={cancelationReason}
        onChange={(e) => setCancelationReason(e.target.value)}
        content={"მიუთითეთ ინვოისის გაუქმების მიზეზი"}
        toggleIsOpen={myToggleCancelForm}
        onConfirm={handleCancel}
        className="cancel-form"
      />
    </div>
  );
}

export default InvoiceCard;
