import React from "react";
import "./index.css";
import ControlbarForUsers from "./controlbars/ControbarForUsers";
import ControlbarForRequests from "./controlbars/ControlbarForRequests";
import ControlbarForConstructor from "./controlbars/ControlbarForConstructor";
import { useNavigate } from "react-router-dom";

const requestsUrl = "/dashboard/services/requests";

export default function ServicesControlbar(props) {
  const {
    selected,
    selectedUsersTable,
    setSelectedUsersTable,
    toggleShowForm,
    showForm,
    toggleEditing,
    editing,
    selectedRequestsTable,
    setSelectedRequestsTable,
    selectedCategory,
    setSelectedCategory,
    selectedConstructorTable,
    setSelectedConstructorTable,
    showFilter,
    toggleShowFilter,
    attribute,
    tab,
  } = props;

  const nav = useNavigate();

  const showSubNav = tab === "pending_payment" || tab === "unpaid";

  const onPendingPaymentRequests = () => nav(requestsUrl + "/pending_payment");
  const onUnpaidRequests = () => nav(requestsUrl + "/unpaid");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="controlbar-container">
        {attribute === "users" && (
          <ControlbarForUsers
            editing={editing}
            selected={selected}
            selectedTable={selectedUsersTable}
            setSelectedTable={setSelectedUsersTable}
            toggleShowForm={toggleShowForm}
            showForm={showForm}
            toggleEditing={toggleEditing}
            showFilter={showFilter}
            toggleShowFilter={toggleShowFilter}
            attrubute={attribute}
            tab={tab}
          />
        )}
        {attribute === "requests" && (
          <ControlbarForRequests
            showFilter={showFilter}
            toggleShowFilter={toggleShowFilter}
            selectedTable={selectedRequestsTable}
            setSelectedTable={setSelectedRequestsTable}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            attrubute={attribute}
            tab={tab}
          />
        )}
        {attribute === "constructor" && (
          <ControlbarForConstructor
            selectedTable={selectedConstructorTable}
            setSelectedTable={setSelectedConstructorTable}
            attrubute={attribute}
            tab={tab}
          />
        )}
      </div>
      {showSubNav && (
        <div className="sub-navigation">
          <div
            className={`sub-navigation-left ${
              tab === "pending_payment" && "sub-navigation-selected"
            }`}
            onClick={onPendingPaymentRequests}
          >
            გადახდის მოლოდინში
          </div>

          <div
            className={`sub-navigation-right ${
              tab === "unpaid" && "sub-navigation-selected"
            }`}
            onClick={onUnpaidRequests}
          >
            გადაუხდელი
          </div>
        </div>
      )}
    </div>
  );
}
