import React from "react";

export default function ChatIcon({ fill, background }) {
  if (background) {
    return (
      <svg
        width="28"
        height="24"
        viewBox="0 0 28 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 0.75C6.3 0.75 0 5.125 0 10.375C0 13.875 3.5 17.025 7 18.775C7 22.45 3.5 23.675 3.5 23.675C8.4 23.675 11.2 21.4 12.425 20H14C21.7 20 28 15.625 28 10.375C28 5.125 21.7 0.75 14 0.75Z"
          fill="#EB5757"
        />
      </svg>
    );
  }
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.525 1.75C6.825 1.75 0 6.3 0 11.55C0 15.05 1.925 18.025 5.25 19.775C8.575 21.525 5.25 19.95 5.25 19.95C5.075 22.225 3.675 22.925 3.675 22.925L0.525 24.5H4.025C8.4 24.5 11.55 22.575 12.95 21.175H14.35C21.875 21.175 28 16.8 28 11.375C28 5.95 22.05 1.75 14.525 1.75ZM14.35 19.425H12.25L11.9 19.775C11.025 20.65 9.1 22.225 6.125 22.75C6.65 21.875 7 20.825 7 19.25V18.725L6.475 18.55C3.325 16.975 1.75 14.525 1.75 11.55C1.75 7.35 7.875 3.5 14.525 3.5C21 3.5 26.25 7 26.25 11.55C26.25 15.75 20.825 19.425 14.35 19.425Z"
        fill={fill}
      />
    </svg>
  );
}
