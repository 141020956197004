import React from "react";
import ControlbarForServicePayments from "./controlbars/ControlbarForServicePayments";
import ControlbarForComunals from "./controlbars/ControlbarForComunals";
import ControlbarForServiceFees from "./controlbars/ControlbarForServiceFees";

export default function PaymentsControlbar(props) {
  const {
    selectedServicePaymentsTable,
    setSelectedServicePaymentsTable,
    selectedComunalsTable,
    setSelectedComunalsTable,
    showFilter,
    toggleShowFilter,
    showFeeForm,
    toggleShowFeeForm,
    tab,
    attribute,
  } = props;

  return (
    <div className="controlbar-container">
      {(attribute === "service" ||
        attribute === "parking" ||
        attribute === "apartment" ||
        attribute === "additional") && (
        <ControlbarForServicePayments
          selectedTable={selectedServicePaymentsTable}
          setSelectedTable={setSelectedServicePaymentsTable}
          showFilter={showFilter}
          tab={tab}
          attribute={attribute}
          toggleShowFilter={toggleShowFilter}
          showFeeForm={showFeeForm}
          toggleShowFeeForm={toggleShowFeeForm}
        />
      )}
      {attribute === "communal" && (
        <ControlbarForComunals
          selectedTable={selectedComunalsTable}
          setSelectedTable={setSelectedComunalsTable}
          showFilter={showFilter}
          toggleShowFilter={toggleShowFilter}
          tab={tab}
        />
      )}
      {attribute === "fees" && (
        <ControlbarForServiceFees
          showFilter={showFilter}
          toggleShowFilter={toggleShowFilter}
        />
      )}
    </div>
  );
}
