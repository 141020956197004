import React, { useRef } from "react";
import useToggle from "../../../../hooks/useToggle";
import useOutsideClick from "../../../../hooks/useOutsideClick";

const CURRENCIES = ["GEL", "USD", "EUR"];

function CurrencyToggler({ current, setCurrent }) {
  const [showList, toggleShowList] = useToggle();
  const ref = useRef(null);

  useOutsideClick(ref, () => toggleShowList(false));

  const onSelect = (value) => {
    setCurrent(value);
    toggleShowList(false);
  };

  return (
    <div className="currency-toggler" ref={ref}>
      <div className="currency-selector" onClick={toggleShowList}>
        <p> {current} </p>
      </div>
      {showList && (
        <div className="currencies">
          {CURRENCIES.map((currency, i) => (
            <div
              className="currency"
              key={i}
              onClick={() => onSelect(currency)}
            >
              {currency}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CurrencyToggler;
