import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers'
import thunk from 'redux-thunk'

const defaultState = {}

export const store = 
process.env.REACT_APP_ENV_TYPE === "development" ? 
createStore(
    reducers,
    defaultState,
    compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
) : 
createStore(
    reducers,
    defaultState,
    compose(
        applyMiddleware(thunk)
    )
)