import React, { useEffect, useState } from "react";
import NotificationsHeader from "./NotificationsHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  clearNotifications,
  getNotifications,
  updateNotifications,
} from "../../../state/action-creators/notificationActions.js";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import { getTypes } from "../../../libs/API.js";
import NotificationsCard from "./card/NotificationsCard.js";
import { useQueryClient } from "@tanstack/react-query";

const skeletons = [1, 2, 3];

export default function NotificationsTable({
  tab,
  toggleShowForm,
  setEditingData,
}) {
  const [pageSize, setPageSize] = useState(0);

  const defaultFilterParams = {
    direction: tab === "received" ? "received" : "sent",
    status: tab === "delayed" ? "delayed" : "sent",
  };

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { items, lastFetch, params, isLoading } = useSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    dispatch(
      getNotifications({
        load_from: 0,
        ...defaultFilterParams,
      })
    );
  }, [tab]);

  useEffect(() => {
    getTypes((data) => setPageSize(data.page_size));

    return () => {
      dispatch(clearNotifications());
    };
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries(["unseen_comments_count"]);
  }, [items]);
  return (
    <div className="invoice-table-container">
      <NotificationsHeader tab={tab} />
      <div className="notifications-list" id="notifications-list">
        <InfiniteScroll
          dataLength={items.length}
          hasMore={lastFetch.length === pageSize}
          next={() => {
            dispatch(
              updateNotifications({
                ...params,
                load_from: items.length,
                ...defaultFilterParams,
              })
            );
          }}
          loader={<Skeleton height={100} className="card-skeleton" />}
          endMessage={<p></p>}
          scrollableTarget={"notifications-list"}
          scrollThreshold={0.9}
        >
          {items.map((notification, i) => (
            <NotificationsCard
              key={i}
              self={notification}
              toggleShowForm={toggleShowForm}
              setEditingData={setEditingData}
            />
          ))}
          {isLoading &&
            skeletons.map((i) => (
              <Skeleton height={100} className="card-skeleton" key={i} />
            ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}
