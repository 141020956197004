import React, { useState, useEffect } from 'react'
import './filter.css';
import FilterDropdown from './FilterDropdown.js';
import { PhoneInput } from './OwnershipFilter';
import { callingCodes } from '../../../../constants';
import { getTypes } from '../../../../libs/API';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../../../state';
import { bindActionCreators } from 'redux';
import { countrys, propertyTypes } from '../../../../constants';


export default function VisitorsFilter() {
  const indexes = Object.keys(countrys).map((e) => ({name: countrys[e], id: e}))
  const [index, setIndex] = useState(null);
  const [parkingType, setParkingType] = useState(null);
  const [parkingPropertyType, setParkingPropertyType] = useState(null);
  const [parkingTypes, setParkingTypes] = useState([]);

  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const cards = useSelector(state => state.properties);
  const { getProperties, setParametres } = bindActionCreators(actionCreators, dispatch)

  const [params, setParams] = useState({
    name: '', surname: '', pers_id: '', phone: '',
    organization_name: '', organization_code: '',
  })

  useEffect(() => {
    getTypes((data) => {
      setParkingTypes(data.parking.map((e) => ({name: e.type.ge, id: e.id}) ))
  });
  }, []);

  const onChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const resetSearch = () => {
    setParams({
      name: '', surname: '', pers_id: '', phone: '',
    });
    setIndex(null); setParkingType(null);
    setParkingPropertyType(null);
  }

  const onSearch = () => {
    const tempParams = {
      load_from: 0,      
      status: cards.status,
      ownership_type: "visitor",
      user_name: params.name,
      user_surname: params.surname,
      user_full_phone: params.phone ? `${callingCodes[index.id]}${params.phone}`:null,
      parking_type_id: parkingType?.id,
      parking_ownership_type: parkingPropertyType ? (parkingPropertyType.name==="საკუთრება" ? "private":"lease"):null,
    }
    setParametres(tempParams);

    getProperties(user, tempParams);
  }

  function handleEnter(event) {
    if (event.keyCode === 13) {
      onSearch()
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleEnter);
    };
  }, [params, parkingType])

  return (
    <div className='filter-container'>
        <div className="filter-row">
            <PhoneInput 
            indexes={indexes}
            selected={index}
            setSelected={setIndex}
            value={params.phone}
            onChange={onChange}
            />
            <div className='filter-input-container'>
            <input className='filter-input'
            placeholder='სახელი' name='name'
            value={params.name} onChange={onChange}
            />
          </div>
          <div className='filter-input-container'>
            <input className='filter-input'
            placeholder='გვარი' name='surname'
            value={params.surname} onChange={onChange}
            />
          </div>
            <div className='filter-input-container'>
              <input className='filter-input'
              placeholder='პირადი ნომერი' name='pers_id'
              value={params.pers_id} onChange={onChange}
            />
          </div>
        </div>
        <div className="filter-row">
          <FilterDropdown 
          title="პარკინგის ტიპი"
          data={parkingTypes}
          selected={parkingType}
          setSelected={setParkingType}
          />
          <FilterDropdown 
          title="პარკინგის საკუთრების ტიპი"
          data={propertyTypes}
          setSelected={setParkingPropertyType}
          selected={parkingPropertyType}
          />
          <div className='filter-input-container' style={{visibility: 'hidden'}}>
            <input className='filter-input'
            placeholder='ორგანიზაციის საიდენტიფიკაციო კოდი' name='surname'
            />
          </div>
          <div className='filter-buttons'>
            <div className='filter-clear-button' onClick={resetSearch}>გასუფთავება</div>
            <div className='put-button' onClick={onSearch}>ძებნა</div>
          </div>
        </div>
    </div>
  )
}
