import { useQuery } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";

const user = store.getState().user.user;

export function useApartments(params, searchCount) {
  return useQuery({
    queryKey: ["apartments", searchCount],
    queryFn: () =>
      API.get(user, "/admin/apartments/filter", params).then((res) => res),
  });
}
