import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { actionCreators } from '../../../../state';
import { bindActionCreators } from 'redux';
import useToggle from '../../../../hooks/useToggle.js';
import useOutsideClick from '../../../../hooks/useOutsideClick.js';
import InputField from '../../../InputField/InputField.js';
import cloud from '../../../../assets/cloud.png';
import API from '../../../../libs/API';

export default function CategoryInput({ parent_id, editing, setEditing }) {
  const [nameEn, setNameEn] = useState('');
  const [nameGe, setNameGe] = useState('');
  const [language, setLanguage] = useState('KA');

  const [icon, setIcon] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const isUpdating = useSelector(state => parent_id ? state.subcategories.isUpdating:state.categories.isUpdating);
  const [error, setError] = useState(false);
  const { createCategory, updateCategory } = bindActionCreators(actionCreators, dispatch);
  const [icons, setIcons] = useState([]);
  const iconsRef = useRef(null);
  const [showIcons, toggleShowIcons] = useToggle();

  const putDisabled = nameEn === '' || nameGe === '';

  useOutsideClick(iconsRef, () => toggleShowIcons(false));
  

  const toggleLanguage = () => {
    if (language === "KA") {
      setLanguage("EN");
    } else {
      setLanguage("KA");
    }
  }
 
  const onPut = () => {
    if(putDisabled) {
      return;
    }
    const create_data = {
      name_en: nameEn, name_ge: nameGe,
      parent_id,
      icon: icon,
    }
    const update_data = {
      name_en: nameEn, name_ge: nameGe,
      id: editing?.id, icon: icon,
      parent_id,
    }
    if(editing){
      updateCategory(user, update_data);
    } else {
      createCategory(user, create_data);
    }
    setEditing(null);
    setNameEn('');
    setNameGe('');
    setIcon('');
    setLanguage('KA');
    setError(false);
  }

  useEffect(() => {
    API.get(user, `/admin/icons`, null, (res) => setIcons(res.icons));
  }, []);

  useEffect(() => {
    setNameEn(editing ? editing.name.en:'');
    setNameGe(editing ? editing.name.ge:'');
    setIcon(editing ? editing.icon:'');
  }, [editing])

  return (
    <div className='category-input'>
      <div className='category-input-fields'>
        <div className='icon-upload' onClick={toggleShowIcons} ref={iconsRef}>
          {!icon && <p>ICON</p>}
          <img src={icon ? icon:cloud} alt='cloud' className='cloud-icon'/>
          {showIcons && 
          <div className='icon-drop'>
            {icons.map((icon, i) => 
              <img key={i} src={icon} alt={'icon'+i} onClick={() => setIcon(icon)}/>
            )}
          </div>}
        </div>
        {language === "KA" && 
        <InputField 
          error={error}
          type={"text"}
          placeholder={"სახელწოდება"}
          onChange={(e) => setNameGe(e.target.value)}
          value={nameGe}
          language={language}
          toggleLanguage={toggleLanguage}
          symbolLimit={100}
          className={"category-input-field"}
        /> }
        {language === "EN" && 
        <InputField 
          error={error}
          type={"text"}
          placeholder={"name"}
          onChange={(e) => setNameEn(e.target.value)}
          value={nameEn}
          language={language}
          toggleLanguage={toggleLanguage}
          symbolLimit={100}
          className={"category-input-field"}
        /> }
      </div>
      <div 
        className={`category-put-button ${putDisabled ? "button-disabled" : ""}`} 
        onClick={onPut}
        style={{marginTop: 10}}
      >
        {isUpdating ? <div className='loader'></div>:"ჩასმა"}
      </div>
    </div>
  )
}