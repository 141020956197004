import React from 'react'

function InvoiceHeader({tab, attribute}) {
  return (
    <div className='owners-table-header'>
        {attribute==="fees" && 
        <div className='request-invoice-card-header owners-header'>
            <div className='card-rows header-cell'>
              მოთხოვნის მონაცემი
            </div>
            <div className='header-cell'>
              ტრანზაქციის მონაცემი
            </div>
        </div>}
        {attribute!=="fees" && 
        <div className='invoice-cards-header owners-header'>
            <div className='card-rows header-cell'>
            ინვოისის მონაცემი
            </div>
            <div className='card-rows header-cell'>
            გადამხდელის მონაცემი
            </div>
            <div className='card-rows header-cell'>
            გადასახადის სახეობა
            </div>
            <div className='header-cell'>
            ტრანზაქციის მონაცემი
            </div>
        </div>}
    </div>
  )
}

export default InvoiceHeader