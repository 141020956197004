import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import search from "../../../../assets/search.png";
import filled_search from "../../../../assets/filled_search.png";
import add from "../../../../assets/add.png";
import add_filled from "../../../../assets/filled_add.png";
import Overdue from "../../../Icons/Overdue";
import Processing from "../../../Icons/Processing";
import Done from "../../../Icons/Done";
import CancelledIcon from "../../../Icons/CancelledIcon";

const orange = "#DAA161";
const black = "#333333";

export default function ControlbarForServicePayments(props) {
  const {
    toggleShowFilter,
    showFilter,
    tab,
    attribute,
    showFeeForm,
    toggleShowFeeForm,
  } = props;
  const { total_cost_sum } = useSelector((state) => state.invoices);
  const navigate = useNavigate();

  const onBarClick = (link) => {
    navigate(link);
    toggleShowFilter(false);
    toggleShowFeeForm(false);
  };

  const onForPay = () => onBarClick(`/dashboard/payments/${attribute}/unpaid`);
  const onUnpaid = () => onBarClick(`/dashboard/payments/${attribute}/overdue`);
  const onPaid = () => onBarClick(`/dashboard/payments/${attribute}/paid`);
  const onCanceled = () =>
    onBarClick(`/dashboard/payments/${attribute}/cancelled`);

  useEffect(() => {
    toggleShowFeeForm(false);
    return () => {
      toggleShowFeeForm(false);
    };
  }, [attribute]);

  return (
    <>
      <div className="controlbar-left payments-controlbar">
        <img
          src={!showFilter ? search : filled_search}
          onClick={toggleShowFilter}
          alt="search"
        ></img>
        {attribute === "additional" && (
          <img
            src={showFeeForm ? add_filled : add}
            onClick={toggleShowFeeForm}
            alt="add"
          ></img>
        )}
        <div className="total_cost_text">
          <span style={{ fontSize: "14px" }}>სულ</span>{" "}
          {total_cost_sum?.GEL.toFixed(2)} GEL |{" "}
          {total_cost_sum?.USD.toFixed(2)} USD |{" "}
          {total_cost_sum?.EUR.toFixed(2)} EUR
        </div>
      </div>
      <div className="controlbar-right">
        <div
          className={
            tab === "unpaid" ? "selector selector-selected" : "selector"
          }
          onClick={onForPay}
        >
          <Processing fill={tab === "unpaid" ? orange : black} />
          <p>გადასახდელი</p>
        </div>
        <div
          className={
            tab === "overdue" ? "selector selector-selected" : "selector"
          }
          onClick={onUnpaid}
        >
          <Overdue fill={tab === "overdue" ? orange : black} />
          <p>გადაუხდელი</p>
        </div>
        <div
          className={tab === "paid" ? "selector selector-selected" : "selector"}
          onClick={onPaid}
        >
          <Done fill={tab === "paid" ? orange : black} />
          <p>გადახდილი</p>
        </div>

        <div
          className={
            tab === "cancelled" ? "selector selector-selected" : "selector"
          }
          onClick={onCanceled}
        >
          <CancelledIcon fill={tab === "cancelled" ? orange : black} />
          <p>გაუქმებული</p>
        </div>
      </div>
    </>
  );
}
