import React from "react";
import PropTypes from "prop-types";
import TextArea from "../../../components/Textarea/TextArea.js";
import Modal from "react-modal";
import close from "../../../assets/close.png";

function CancelForm({
  content,
  value,
  onChange,
  onConfirm,
  isOpen,
  toggleIsOpen,
  className,
}) {
  const myConfirm = () => {
    if (value.length === 0) {
      return;
    }
    onConfirm();
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      className={"modal-content"}
      shouldCloseOnOverlayClick={true}
    >
      <div className="confirm-modal">
        <p>{content}</p>
        <TextArea
          className={className}
          value={value}
          onChange={onChange}
          height={200}
          symbolsLimit={3000}
          placeholder={"გაუქმების მიზეზი"}
        />
        <div
          className={`confirm-button ${
            value.length === 0 && "button-disabled"
          }`}
          onClick={myConfirm}
        >
          გაგზავნა
        </div>
        <img
          src={close}
          alt="close"
          className="close-icon"
          width={15}
          onClick={toggleIsOpen}
        />
      </div>
    </Modal>
  );
}

CancelForm.propTypes = {
  content: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onConfirm: PropTypes.func,
  isOpen: PropTypes.bool,
  toggleIsOpen: PropTypes.func,
};

export default CancelForm;
