import React from 'react';
import './ErrorPage.css';
import kluchi from '../../assets/kluchi.png';

export default function UnderConstruction({ isNetworkError }) {

  return (
    <div className='page-container column-flex-centered' style={{justifyContent: 'center'}}> 
        <div className='login-inner column-flex-centered shadow'>
            <div className='login-headers'>
                <h4>შპს რეზიდ სერვისი</h4>
                <h1>rezid.bpms.ge</h1>
                <h4>ბიზნეს პროცესების მართვის სისტემა</h4>
                {!isNetworkError &&
                <div className='message-box'>
                    <img src={kluchi} alt='kluchi'/>
                    <p>მიმდინარეობს ადმინისტრირებადი პანელის ტექნიკური განახლება</p>
                    <p>სისტემა მალე გაეშვება</p>
                    <p>ბოდიშს გიხდით შეფერხებისთვის</p>
                </div> }
                {isNetworkError && 
                <div className='message-box'>
                    <img src={kluchi} alt='kluchi'/>
                    <p>კავშირის პრობლემა</p>
                </div> }
            </div>
        </div>
    </div>
  )
}
