import API from "../../libs/API";
import { GET_RATINGS } from "./types";

export const getRatings = (user) => {
    return (dispatch)=>{
      API.get(user, '/admin/requests/rating/statistics', null,
    (res)=>dispatch({type:GET_RATINGS, payload: res.statistics}),
    (err)=>console.log(err))
    }
}

export const getSubRatings = (user, id) => {
    return (dispatch)=>{
      API.get(user, `/admin/requests/rating/statistics`, {parent_id: id},
    (res)=>dispatch({type:GET_RATINGS, payload: res.statistics}),
    (err)=>console.log(err))
    }
}
 
export default getRatings;