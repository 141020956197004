import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators } from "../../../state";
import { getTypes } from "../../../libs/API";
import { filteredObject } from "../../../libs/Functions";
import "./index.css";
import OwnersCard from "../../OwnersComponent/OwnersCard";
import OwnersTableHeader from "../../OwnersComponent/OwnersTableHeader";
import DownloadButton from "../../../components/DownloadButton/DownloadButton";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function OwnersTable({ toggleEditing, onCardClick, tab }) {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(0);
  const { updateProperties, getProperties, setParametres } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const user = useSelector((state) => state.user.user);
  const owners = useSelector((state) => state.properties);
  const params = owners.params;
  const skeletons = [1, 2, 3, 4];
  const isLoading = owners.isLoading;

  useEffect(() => {
    if (tab === "draft") {
      getProperties(user, {
        load_from: 0,
        status: tab,
        ownership_type: "private",
      });
      setParametres({ ...params, status: tab });
    } else {
      getProperties(user, {
        status: "ready",
        load_from: 0,
        ownership_type: tab,
      });
      setParametres({ ...params, ownership_type: tab });
    }
  }, [owners.status, tab]);

  useEffect(() => {
    getTypes((data) => setPageSize(data.page_size));
  }, []);

  return (
    <div className="owners-list-table">
      {owners.properties.length !== 0 && <OwnersTableHeader tab={tab} />}
      <div className="owners-list" id="owners-list">
        <InfiniteScroll
          dataLength={owners.properties.length}
          hasMore={owners.lastFetch.length === pageSize}
          next={() =>
            updateProperties(user, {
              ...params,
              load_from: owners.properties.length,
              ownership_type: tab,
            })
          }
          loader={<Skeleton height={100} className="card-skeleton" />}
          endMessage={<p></p>}
          scrollableTarget={"owners-list"}
          scrollThreshold={0.9}
        >
          {isLoading &&
            skeletons.map((_, i) => (
              <Skeleton key={i} height={100} className="card-skeleton" />
            ))}
          {!isLoading &&
            owners.properties.map((a, i) => (
              <OwnersCard
                key={i}
                isLease={owners.ownership_type === "lease"}
                toggleEditing={toggleEditing}
                onCardClick={onCardClick}
                ownership_card={a}
                tab={tab}
              />
            ))}
        </InfiniteScroll>
      </div>
      {owners.properties.length !== 0 && tab !== "draft" && (
        <DownloadButton
          link={"/admin/ownership_cards/filter/download"}
          params={filteredObject(params)}
        />
      )}
    </div>
  );
}
