import React from "react";

export default function Category({ self }) {
  return (
    <div className="fee-category">
      <div className="fee-category-icon">
        <img src={self.icon} alt={self.name.ge} />
      </div>
      <div className="fee-category-name">
        <p> {self.name.ge} </p>
      </div>
    </div>
  );
}
