import { useQuery } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";

const user = store.getState().user.user;

export function useUnseenCounts() {
  return useQuery({
    queryKey: ["unseen_comments_count"],
    queryFn: () => API.get(user, "/unseen_comments_count"),
  });
}
