import { store } from '../../state/store.js';
import API from '../../libs/API.js';
import { ADD_EXECUTOR, GET_EXECUTORS, REMOVE_EXECUTOR } from './types';
const user = store.getState().user.user;

export const getExecutors = () => {
    return (dispatch) => {
        API.get(user, `/admin/executors`, null, 
        (res) => dispatch({type: GET_EXECUTORS, payload: res.executors}));
    }
}

export const addExecutor = (executor) => {
    return (dispatch) => {
        API.post(user, `/admin/executor`, executor, 
        (res) => dispatch({type: ADD_EXECUTOR, payload: res.executor}));
    }
}

export const removeExecutor = (id) => {
    return (dispatch) => {
        dispatch({type: REMOVE_EXECUTOR, payload: id});
        API.delete(user, `/admin/executor`, {id}, );
    }
}