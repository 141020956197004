import { ADD_VIDEO, GET_VIDEOS, DELETE_VIDEO, UPDATE_VIDEO } from "../action-creators/types";

const initialState = {
    videos: [],
    isLoading: false,
    message: '',
}

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case GET_VIDEOS:
            return {
                ...state,
                videos: action.payload,
            }
        case ADD_VIDEO:
            return {
                ...state,
                videos: [...state.videos, action.payload],
            }
        case DELETE_VIDEO:
            return {
                ...state,
                videos: state.videos.filter((vid) => vid.id !== action.payload)
            }
        case UPDATE_VIDEO:
            return {
                ...state,
                videos:state.videos.map((a) => a.id === action.payload ? {...a, is_visible:!a.is_visible} : a)
            }
        default:
            return state;
    }
}

export default reducer;