import {
  ADD_OWNER,
  ADD_COMPLEX,
  ADD_PARKING,
  PROPERTY_ERROR,
  ADD_ORGANIZATION,
  GET_PROPERTY,
  DELETE_PROPERTY,
  ADD_PROPERTY,
  DELETE_COMPLEX,
  DELETE_PARKING,
  DELETE_OWNER,
  DELETE_ORGANIZATION,
  RESET_CURRENT_PROPERTY,
  SET_PROPERTY_LOADING,
  UPDATE_OWNER,
  UPDATE_COMPLEX,
  UPDATE_PARKING,
  SET_PROPERTY_ERROR,
  SET_OWNER_ADDING_LOADING,
  SET_COMPLEX_ADDING_LOADING,
  SET_PARKING_ADDING_LOADING,
  TOGGLE_RESPONSIBLE_PERSON,
} from "../action-creators/types";

const initialState = {
  users: [],
  apartments: [],
  parking_spaces: [],
  organizations: [],
  isLoading: false,
  owner_adding: false,
  complex_adding: false,
  parking_adding: false,
  isError: false,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OWNER:
      const [user, user_card_id] = action.payload;
      return {
        ...state,
        isCreated: true,
        users: [
          ...state.users,
          {
            ...user,
            apartment_responsibilities: [],
            parking_responsibilities: [],
          },
        ],
        owner_adding: false,
        isLoading: false,
        card_id: user_card_id,
      };
    case DELETE_OWNER:
      const owner_id = action.payload;
      return {
        ...state,
        users: state.users.filter(({ id }) => owner_id !== id),
        isLoading: false,
      };
    case ADD_COMPLEX:
      const [apartment, apartment_card_id] = action.payload;
      return {
        ...state,
        isCreated: true,
        apartments: [...state.apartments, apartment],
        card_id: apartment_card_id,
        complex_adding: false,
      };
    case DELETE_COMPLEX:
      const complex_id = action.payload;
      return {
        ...state,
        apartments: state.apartments.filter(({ id }) => complex_id !== id),
        isLoading: false,
      };
    case ADD_PARKING:
      const [parking, parking_card_id] = action.payload;
      return {
        ...state,
        isCreated: true,
        parking_spaces: [...state.parking_spaces, parking],
        card_id: parking_card_id,
        parking_adding: false,
      };

    case DELETE_PARKING:
      const my_id = action.payload;
      return {
        ...state,
        parking_spaces: state.parking_spaces.filter(
          ({ parking_id }) => parking_id !== my_id
        ),
        isLoading: false,
      };

    case ADD_ORGANIZATION:
      const [organization, org_card_id] = action.payload;
      return {
        ...state,
        organizations: [
          ...state.organizations,
          {
            ...organization,
            apartment_responsibilities: [],
            parking_responsibilities: [],
          },
        ],
        card_id: org_card_id,
        owner_adding: false,
      };
    case DELETE_ORGANIZATION:
      const org_id = action.payload;
      return {
        ...state,
        organizations: state.organizations.filter(({ id }) => org_id !== id),
        isLoading: false,
      };
    case GET_PROPERTY:
      const card = action.payload;
      return {
        ...state,
        ...card,
        users: card.users,
        apartments: card.apartments,
        parking_spaces: card.parking_spaces,
        organizations: card.organizations,
        card_id: card.id,
        isLoading: false,
      };
    case ADD_PROPERTY:
      localStorage.removeItem("card_id");
      return initialState;
    case UPDATE_OWNER:
      const owner = action.payload;

      return !owner.organization
        ? {
            ...state,
            users: state.users.map((u) =>
              u.id === owner.user_id ? { ...owner.user, id: owner.user_id } : u
            ),
          }
        : {
            ...state,
            organizations: state.organizations.map((o) =>
              o.id === owner.organization_id
                ? {
                    ...owner.organization,
                    representative: {
                      ...owner.user,
                      id: owner.organization.representative_id,
                      country: {
                        code: owner.user.country_code,
                        phone_prefix: owner.user.country_phone_prefix,
                      },
                    },
                    id: owner.organization_id,
                    is_responsible: owner.user.is_responsible,
                  }
                : o
            ),
          };
    case UPDATE_COMPLEX:
      const type = action.payload.complex_type;
      const complex = {
        ...action.payload,
        complex_type:
          type === 1 ? { ge: "საცხოვრებელი ბლოკი" } : { ge: "ბიზნეს ბლოკი" },
      };
      return {
        ...state,
        apartments: state.apartments.map((a) =>
          a.id === complex.id ? complex : a
        ),
      };
    case UPDATE_PARKING:
      const park = action.payload;
      return {
        ...state,
        parking_spaces: state.parking_spaces.map((p) =>
          p.id === park.id ? park : p
        ),
      };
    case SET_PROPERTY_LOADING:
      const value = action.payload;
      return {
        ...state,
        isLoading: value,
      };
    case RESET_CURRENT_PROPERTY:
      return initialState;
    case DELETE_PROPERTY:
      const data = action.payload;
      return {
        users: [],
        apartments: [],
        parking_spaces: [],
        organizations: [],
        isLoading: false,
        isError: false,
        message: data.message ? data.message.ge : null,
      };
    case PROPERTY_ERROR:
      const error = action.payload;
      return {
        ...state,
        isError: true,
        owner_adding: false,
        complex_adding: false,
        parking_adding: false,
        message: Object.values(error.response.data)[0][0],
      };
    case SET_PROPERTY_ERROR:
      return {
        ...state,
        isError: action.payload,
        adding: false,
      };
    case SET_OWNER_ADDING_LOADING:
      return {
        ...state,
        owner_adding: true,
      };
    case SET_COMPLEX_ADDING_LOADING:
      return {
        ...state,
        complex_adding: true,
      };
    case SET_PARKING_ADDING_LOADING:
      return {
        ...state,
        parking_adding: true,
      };
    case TOGGLE_RESPONSIBLE_PERSON:
      const temp = action.payload;
      if (temp.apartment_id) {
        if (temp.organization_id) {
          return {
            ...state,
            organizations: state.organizations.map((user) => {
              if (user.id === temp.organization_id) {
                if (
                  user.apartment_responsibilities.includes(temp.apartment_id)
                ) {
                  return {
                    ...user,
                    apartment_responsibilities:
                      user.apartment_responsibilities.filter(
                        (item) => item !== temp.apartment_id
                      ),
                  };
                } else {
                  return {
                    ...user,
                    apartment_responsibilities: [
                      ...user.apartment_responsibilities,
                      temp.apartment_id,
                    ],
                  };
                }
              } else {
                return user;
              }
            }),
          };
        }
        return {
          ...state,
          users: state.users.map((user) => {
            if (user.id === temp.user_id) {
              if (user.apartment_responsibilities.includes(temp.apartment_id)) {
                return {
                  ...user,
                  apartment_responsibilities:
                    user.apartment_responsibilities.filter(
                      (item) => item !== temp.apartment_id
                    ),
                };
              } else {
                return {
                  ...user,
                  apartment_responsibilities: [
                    ...user.apartment_responsibilities,
                    temp.apartment_id,
                  ],
                };
              }
            } else {
              return user;
            }
          }),
        };
      }
      if (temp.parking_id) {
        if (temp.organization_id) {
          return {
            ...state,
            organizations: state.organizations.map((user) => {
              if (user.id === temp.organization_id) {
                if (user.parking_responsibilities.includes(temp.parking_id)) {
                  return {
                    ...user,
                    parking_responsibilities:
                      user.parking_responsibilities.filter(
                        (item) => item !== temp.parking_id
                      ),
                  };
                } else {
                  return {
                    ...user,
                    parking_responsibilities: [
                      ...user.parking_responsibilities,
                      temp.parking_id,
                    ],
                  };
                }
              } else {
                return user;
              }
            }),
          };
        }
        return {
          ...state,
          users: state.users.map((user) => {
            if (user.id === temp.user_id) {
              if (user.parking_responsibilities.includes(temp.parking_id)) {
                return {
                  ...user,
                  parking_responsibilities:
                    user.parking_responsibilities.filter(
                      (item) => item !== temp.parking_id
                    ),
                };
              } else {
                return {
                  ...user,
                  parking_responsibilities: [
                    ...user.parking_responsibilities,
                    temp.parking_id,
                  ],
                };
              }
            } else {
              return user;
            }
          }),
        };
      }
    default:
      return state;
  }
};

export default reducer;
