import React from 'react'
import './Header.css';
import logoutIcon from '../../assets/logout.png';
import pdf from '../../assets/instruction.pdf';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';
import { Link } from 'react-router-dom';

const Header = () =>  {
  const { phone } = useSelector(state => state.user.user)
  const userState = useSelector(state => state.user);
  const { logout_loading } = useSelector(state => state.user);

  // console.log(userState.user)

  const dispatch = useDispatch()
  const { logoutUser } = bindActionCreators(actionCreators, dispatch)

  const logout = () => {
    logoutUser(userState.user);
  }

  return (
    <nav className='header-container'>
      <div className='header-left'>
        <Link to='/home' className='link logo'><h1 className='logo'>rezid.bpms.ge</h1></Link>
        <div>
          <p>შპს რეზიდ სერვისი</p>
          <p>ბიზნეს პროცესების მართვის სისტემა</p>
        </div>
      </div>
      <div className='header-right'>
        <a className='instruction-button' href={pdf} target='_blank'>ინსტრუქცია</a>
        <p>{ phone }</p>
          {!logout_loading && <img src={logoutIcon} alt="logout" className='logout-icon' onClick={logout}/> }
          {logout_loading && <div className='loader-black'></div>}
      </div>
    </nav>
  )
}

export default Header;
