import {
  CREATE_NOTIFICATION,
  GET_ADDRESATES,
  SELECT_ALL_ADDRESATES,
  SET_ADDRESATES_LOADING,
  SET_ADDRESATES_PARAMS,
  TOGGLE_ADDRESATE,
  UNSELECT_ADDRESATES,
} from "../action-creators/types";

const initialState = {
  items: [],
  params: {
    load_from: 0,
  },
  lastFetch: [],
  isLoading: false,
  isError: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADDRESATES:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
      };
    case SET_ADDRESATES_LOADING:
      return {
        ...state,
        items: [],
        isLoading: action.payload,
      };
    case SET_ADDRESATES_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case SELECT_ALL_ADDRESATES:
      const isAllChecked =
        !state.items.filter((addresate) => !addresate.isSelected).length > 0;
      return {
        ...state,
        items: state.items.map((addresate) => ({
          ...addresate,
          isSelected: !isAllChecked,
        })),
      };
    case TOGGLE_ADDRESATE:
      return {
        ...state,
        items: state.items.map((addresate) => {
          if (action.payload === addresate.id) {
            return { ...addresate, isSelected: !addresate.isSelected };
          } else {
            return addresate;
          }
        }),
      };
    case UNSELECT_ADDRESATES:
      return {
        ...state,
        items: state.items.map((addresate) => ({
          ...addresate,
          isSelected: false,
        })),
      };
    case CREATE_NOTIFICATION:
      return {
        ...state,
        items: state.items.map((addresate) => ({
          ...addresate,
          isSelected: false,
        })),
      };
    default:
      return state;
  }
};

export default reducer;
