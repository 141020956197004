import React, { useEffect } from 'react';
import './Tutorials.css';
import { useDispatch, useSelector } from 'react-redux';
import { getTutorials } from '../../state/action-creators/tutorialsActions';
import TutorialsCard from '../../components/tutorialsComponent/TutorialsCard/TutorialsCard.js';
import Navbar from '../../components/Navbar/Navbar.js';
import TutorialsForm from '../../components/tutorialsComponent/TutorialsForm/TutorialsForm.js';
export default function Tutorials() {

  const dispatch = useDispatch();

  const user = useSelector(state => state.user.user);
  const tutorials=useSelector(state => state.tutorials.videos);

  useEffect(() => {
    dispatch(getTutorials(user))
  }, []);

  return (
    <div className="tutorials">
      <Navbar 
            title="ვიდეო ინსტრუქციების მართვის მოდული"
            attributes={[]}
            setSelected={false}
            selected={0}
        />
      <div className="tutorials-container">
      <TutorialsForm/>
      <div className="videos">
        {tutorials.map((vid)=>
            <TutorialsCard  key={vid.id} url={vid.url} title={vid.title} id={vid.id} is_visible={vid.is_visible}/>
             
        )}
      </div>
      </div>
    </div>
  )
}
