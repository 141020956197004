import React, { useEffect, useState } from 'react'
import Dropdown from '../../../Dropdown/Dropdown.js';
import { useSelector, useDispatch } from 'react-redux';
import './constructor.css';
import edit_icon from '../../../../assets/edit_icon.png';
import API, { getTypes } from '../../../../libs/API';
import useToggle from '../../../../hooks/useToggle.js';
import { getSubcategories } from '../../../../state/action-creators/categoriesActions.js';
import Checkbox from '../../../Checkbox/Checkbox.js';
import TextArea from '../../../Textarea/TextArea.js';
import WorkingHours from './WorkingHours.js';
import InputField from '../../../InputField/InputField.js';
import { workingHoursToJSON, workingHoursToObject } from '../../../../libs/Functions.js';

export default function ServicesInputs({ subcategory_id, set_subcategory_id, parent_id }) {
  const service = useSelector(state=>state.subcategories.items.filter((c) => c.id===subcategory_id)[0]?.service);
  const user = useSelector(state=>state.user.user);
  const dispatch = useDispatch();

  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceType, setServiceType] = useState(service ? {name: service.type.ge, name_en: service.type.en}:null);
  const [currencies, setCurrencies] = useState([]);
  const [currency, setCurrency] = useState(service ? {name: service.currency.ge, name_en: service.currency.en}:null);
  const [timeUnits, setTimeUnits] = useState([]);
  const [timeUnit, setTimeUnit] = useState(service ? {name: service.action_period_unit.ge, name_en: service.action_period_unit.en}:null);
  const [calculationOptions, setCalculationOptions] = useState([]);
  const [calculationOption, setCalculationOption] = 
    useState(service ? { name: service.price_calculation_option.ge, name_en: service.price_calculation_option.en } : null);
  const [descriptionEn, setDescriptionEn] = useState(service ? service.service_description_en : '');
  const [descriptionGe, setDescriptionGe] = useState(service ? service.service_description_ge : '');
  const [descriptionLanguage, setDescriptionLanguage] = useState("KA");

  const [periodTypes, setPeriodTypes] = useState([]);
  const [periodType, setPeriodType] = useState(service && service.service_getting_period_type ? {name: service.service_getting_period_type.ge, name_en: service.service_getting_period_type.en} : null);

  const [maxDays, setMaxDays] = useState(service ? service.service_getting_period_max_days : null);

  const toggleLanguage = () => {
    if (descriptionLanguage === "KA") {
      setDescriptionLanguage("EN");
    } else {
      setDescriptionLanguage("KA");
    }
  }

  const [descRequired, toggleDescRequired] = useToggle(service ? service.description_required:false);
  const [imageRequired, toggleImageRequired] = useToggle(service ? service.image_required:false);
  const [quantityRequired, toggleQuantityRequired] = useToggle(service ? service.quantity_required:false);
  const [approveRequired, toggleApproveRequired] = useToggle(service ? service.approval_required: false);

  const [approveTime, setApproveTime] = useState(service ? service.approval_period : '');
  const [payTime, setPayTime] = useState(service ? service.payment_period : '');

  const [price, setPrice] = useState(service ? service.action_period:'');
  const [period, setPeriod] = useState(service ? service.price:'');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [editing, setEditing] = useState(false);


  // weekday hours state
  const [workHours, setWorkHours] = useState(
  service ? 
  workingHoursToObject(service.working_hours) 
  : {
    mondayFrom: null, mondayTo: null,
    tuesdayFrom: null, tuesdayTo: null,
    wednesdayFrom: null, wednesdayTo: null,
    thursdayFrom: null, thursdayTo: null,
    fridayFrom: null, fridayTo: null,
    saturdayFrom: null, saturdayTo: null,
    sundayFrom: null, sundayTo: null
  });

  const onHourSelect = (e, fieldName) => {
    setWorkHours((prevState) => ({
      ...prevState,
      [fieldName]: e,
    }))
  }

  useEffect(() => {
    getTypes((data) => {
      setServiceTypes(data.service_type.map((t, i) => ({name: t.ge, name_en: t.en, id: i})));
      setCurrencies(data.currency.map((t, i) => ({name: t.ge, name_en: t.en, id: i})));
      setTimeUnits(data.time_unit.map((t, i) => ({name: t.ge, name_en: t.en, id: i})));
      setCalculationOptions(data.service_price_calculation_options.map((t, i) => ({name: t.ge, name_en: t.en, id: i})));
      setPeriodTypes(data.service_getting_period_types.map((t, i) => ({name: t.ge, name_en: t.en, id: i})));
    });
  }, []);

  useEffect(() => {
    setServiceType(service ? {name: service.type.ge, name_en: service.type.en}:null);
    setCurrency(service ? {name: service.currency.ge, name_en: service.currency.en}:null);
    setTimeUnit(service ? {name: service.action_period_unit.ge, name_en: service.action_period_unit.en}:null);
    toggleDescRequired(service ? service.description_required:null); toggleImageRequired(service ? service.image_required:null);
    toggleQuantityRequired(service ? service.quantity_required:null); setPeriod(service ? service.action_period:'');
    setPrice(service ? service.price:''); toggleDescRequired(service ? service.description_required:false);
    toggleImageRequired(service ? service.image_required:false); toggleQuantityRequired(service ? service.quantity_required:false);

    setCalculationOption(service ? { name: service.price_calculation_option.ge, name_en: service.price_calculation_option.en } : null);
    setDescriptionEn(service ? service.service_description_en : '');
    setDescriptionGe(service ? service.service_description_ge : '');
    toggleApproveRequired(service ? service.approval_required : false);
    setApproveTime(service ? service.approval_period : '');
    setPayTime(service ? service.payment_period : '');
    setPeriodType(service && service.service_getting_period_type ? {name: service.service_getting_period_type.ge, name_en: service.service_getting_period_type.en} : {name: 'არცერთი', name_en: null});
    setMaxDays(service ? service.service_getting_period_max_days : null);
    setWorkHours(service ? 
      workingHoursToObject(service.working_hours) 
      : {
        mondayFrom: null, mondayTo: null,
        tuesdayFrom: null, tuesdayTo: null,
        wednesdayFrom: null, wednesdayTo: null,
        thursdayFrom: null, thursdayTo: null,
        fridayFrom: null, fridayTo: null,
        saturdayFrom: null, saturdayTo: null,
        sundayFrom: null, sundayTo: null
      });
  }, [subcategory_id]);

  const afterSave = () => {
    set_subcategory_id(null);
    dispatch(getSubcategories(user, parent_id));
    setLoading(false);
  }

  const afterSaveError = () => {
    setLoading(false);
  }

  const post_data = {
    category_id: subcategory_id,
    price: Number(price), currency: currency?.name_en,
    type: serviceType?.name_en, 
    quantity_required: quantityRequired,
    description_required: descRequired,
    image_required: imageRequired, action_period: Number(period),
    action_period_unit: timeUnit?.name_en,
    price_calculation_option: calculationOption?.name_en,
    service_description_en: descriptionEn,
    service_description_ge: descriptionGe,
    approval_required: approveRequired,
    working_hours: workingHoursToJSON(workHours),
  }
  if (approveRequired) {
    post_data.approval_period = approveTime;
    post_data.payment_period = payTime;
  }
  if (periodType?.name_en === "daily") {
    post_data.service_getting_period_max_days = maxDays;
  }

  const put_data = {...post_data, id: service?.id}

  const allFieldsAreFilled = 
    !(Object.values(post_data).includes(undefined) || Object.values(post_data).includes(0) || 
    Object.values(post_data).includes(null) || Object.values(post_data).includes(''));

  post_data.service_getting_period_type = periodType ? periodType.name_en : null;
  put_data.service_getting_period_type = periodType ? periodType.name_en : null;

  const onSave = () => {
    if (!allFieldsAreFilled) {
      return;
    }
    setLoading(true); setError(false);
    if (!service) {
      API.post(user, `/admin/service`, post_data, afterSave, afterSaveError);
    } else {
      API.put(user, `/admin/service`, put_data, afterSave, afterSaveError);
    }
    
  }

  return timeUnits && (
    <div className='services-inputs'>
      <div className='services-inputs-row'>
        <Dropdown 
        title="სერვისის ტიპი"
        isError={error}
        data={serviceTypes}
        selected={serviceType} setSelected={setServiceType}
        disabled={!editing && service !== null}
        />
        <Dropdown 
          title={"ღირებულების დაანგარიშების ტიპი"}
          data={calculationOptions}
          selected={calculationOption}
          setSelected={setCalculationOption}
          isError={error}
          height={40}
          disabled={!editing && service !== null}
        />
      </div>
      <div className='services-inputs-row'>
        <InputField 
          onChange={(e) => setPeriod(e.target.value)}
          type={'number'}
          error={error}
          placeholder={'რეაგირების პერიოდი'}
          value={period}
          disabled={!editing && service !== null}
        />
        
        <Dropdown 
         title="დროის ერთეული"
         isError={error}
         data={timeUnits}
         selected={timeUnit} setSelected={setTimeUnit}
         disabled={!editing && service !== null}
        />
      </div>
      <div className='services-inputs-row'>
        <InputField 
          onChange={(e) => setPrice(e.target.value)}
          type={'number'}
          error={error}
          placeholder={'ღირებულება'}
          value={price}
          disabled={!editing && service !== null}
        />
        <Dropdown 
        title="ვალუტა"
        isError={error}
        data={currencies}
        selected={currency} setSelected={setCurrency}
        disabled={!editing && service !== null}
        />
      </div>
      <div className='services-inputs-col-row'>
        <div className='services-inputs-col'>
          <Checkbox 
            isChecked={descRequired}
            onClick={toggleDescRequired}
            label={"მოთხოვნის აღწერა"}
            disabled={!editing && service !== null}
          />
          <Checkbox 
            isChecked={imageRequired}
            onClick={toggleImageRequired}
            label={"სურათის მიბმა"}
            disabled={!editing && service !== null}
          />
          <Checkbox 
          isChecked={quantityRequired}
          onClick={toggleQuantityRequired}
          label={"რაოდენობის მითითება"}
          disabled={!editing && service !== null}
          />
        </div>
        <div className='services-inputs-col'>
          <Dropdown 
            title={"სერვისის მიღების პერიოდის ტიპი"}
            data={[{name: "არცერთი", name_en: null}, ...periodTypes]}
            selected={periodType}
            setSelected={setPeriodType}
            disabled={!editing && service !== null}
          />
          {periodType?.name_en === "daily" && 
            <InputField 
              onChange={(e) => setMaxDays(e.target.value)}
              type={'number'}
              error={error}
              placeholder={'მონიშვნადი დღეები'}
              value={maxDays}
              disabled={!editing && service !== null}
            />
            
          }
          </div>
      </div>

      <div className='services-inputs-row'>
      </div>
      <div className='services-inputs-row'>
        {descriptionLanguage === "KA" && 
        <TextArea 
          placeholder={"სერვისის აღწერა"}
          height={200}
          value={descriptionGe}
          onChange={(e) => setDescriptionGe(e.target.value)}
          language={"KA"}
          toggleLanguage={toggleLanguage}
          symbolsLimit={3000}
          className={"service-textarea"}
          disabled={!editing && service !== null}
        /> }
        {descriptionLanguage === "EN" && 
        <TextArea 
          placeholder={"Service description"}
          height={200}
          value={descriptionEn}
          onChange={(e) => setDescriptionEn(e.target.value)}
          language={"EN"}
          toggleLanguage={toggleLanguage}
          symbolsLimit={3000}
          className={"service-textarea"}
          disabled={!editing && service !== null}
        /> }
      </div>
      <div className='services-inputs-row'>
        <Checkbox 
          label={"მოთხოვნის დადასტურება"}
          isChecked={approveRequired}
          onClick={toggleApproveRequired}
          disabled={!editing && service !== null}
        />
      </div>
      {approveRequired && 
      <div className='services-inputs-row'>
        <InputField 
          type={'number'}
          value={approveTime}
          onChange={(e) => setApproveTime(e.target.value)}
          placeholder={'დადასტურების დრო (წუთი)'}
          disabled={!approveRequired || (!editing && service !== null)}
        />
        <InputField 
          type={'number'}
          value={payTime}
          onChange={(e) => setPayTime(e.target.value)}
          placeholder={'გადახდის დრო (წუთი)'}
          disabled={!approveRequired || (!editing && service !== null)}
        />
      </div> }
       <WorkingHours
        onHourSelect={onHourSelect}
        workHours={workHours}
        approveRequired={approveRequired}
        disabled={!editing && service !== null}
        />
      {(editing || !service) &&
      <div 
        className={`category-put-button ${!allFieldsAreFilled && 'button-disabled'}`} 
        onClick={onSave}
        style={{marginTop: '20px', alignSelf: 'center'}}
      >
        {loading ? <div className='loader'></div>:"შენახვა"}
      </div> }
      {(!editing && service !== null) && 
      <div className='service-update-icon' onClick={() => setEditing(true)}>
        <img alt='edit' src={edit_icon}/>
      </div> }
    </div>
  )
}