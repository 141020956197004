import { combineReducers } from "redux";
import userReducer from "./userReducer";
import currentPropertyReducer from "./currentPropertyReducer";
import propertyReducer from "./propertyReducer";
import tutorialsReducer from "./tutorialReducer";
import categoriesReducer from "./categoriesReducer";
import subcategoriesReducer from "./subcategoriesReducer";
import requestReducer from "./requestReducer";
import executorReducer from "./executorReducer";
import ratingsReducer from "./ratingsReducer";
import invoiceReducer from "./invoiceReducer";
import popupReducer from "./popupReducer";
import addresateReducer from "./addresateReducer";
import notificationReducer from "./notificationReducer";

const reducers = combineReducers({
  user: userReducer,
  currentProperty: currentPropertyReducer,
  properties: propertyReducer,
  tutorials: tutorialsReducer,
  categories: categoriesReducer,
  subcategories: subcategoriesReducer,
  requests: requestReducer,
  executors: executorReducer,
  ratings: ratingsReducer,
  invoices: invoiceReducer,
  popup: popupReducer,
  addresates: addresateReducer,
  notifications: notificationReducer,
});

export default reducers;
