import React from 'react';
import bin from '../../../assets/bin.png';
import edit_icon from '../../../assets/edit_icon.png';
import phoneIcon from '../../../assets/phoneIcon.png';
import { dateToString } from '../../../libs/Functions';

export default function OwnerList({
    owners,
    userEditingRecord,
    userEdit,
    setDeletingRecord,
    isLease,
    user
}) {
  return (
    <>
    {  owners.users.map((u, i) => 
        <div key={i} className='card-item owner-card-item' style={isLease ? {justifyContent: 'flex-start'} : {}}>
            {user.is_verified && <img src={phoneIcon} 
            onClick={(event)=>{event.stopPropagation(); dateToString(user.verification_date)}}  
            className="phoneIcon phone-in-edit" alt="phone icon" style={{left: '0', top: '5px'}}/>}
            <p className={userEditingRecord?.id===u.id ? 'editing':''}>
            {`${u.name} ${u.surname} | ${u.pers_id} | ${u.country.code} (${u.country.phone_prefix}) ${u.phone}`}</p>
            <div className='edit-icons'>
                {!isLease && <img src={edit_icon} alt='edit' className='edit-icon' onClick={() => userEdit(u)}/>}
                {!isLease && <img src={bin} alt='bin' className='bin' onClick={() => setDeletingRecord({name: "owner", obj: u})}/>}
            </div>    
        </div>
    )}
    </>
  )
}
