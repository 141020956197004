import React, { useEffect } from "react";

function Apartment({ self, onSelect, selectedApartment }) {
  const handleClick = () => {
    if (selectedApartment !== null) {
      onSelect(null);
      return;
    }
    onSelect(self.id);
  };

  useEffect(() => {
    return () => {
      onSelect(null);
    };
  }, []);

  return (
    <div
      className={`checkbox-row ${
        selectedApartment === self.id ? "checkbox-row-selected" : ""
      }`}
      style={{
        padding: "15px 5px",
      }}
      onClick={handleClick}
    >
      <p>
        {`${self.complex_type.ge} | სართ. ${self.floor} | № ${self.number} | ${self.area} ㎡`}
      </p>
    </div>
  );
}

export default Apartment;
