import React from 'react';
import { ExecutorSelect, RequestData, RequestReaction, SubcategoriesList, UserData } from './RequestCardColumns';
import Modal from 'react-modal';
import useToggle from '../../../../hooks/useToggle';
import { dateToString } from '../../../../libs/Functions';

const date = (utc) => {
  const d = new Date(utc);
  const day = d.getDate().toString().padStart(2, '0')
  const month = (d.getMonth()+1).toString().padStart(2, '0');
  const hours = d.getHours().toString().padStart(2, '0');
  const minutes = d.getMinutes().toString().padStart(2, '0');
  return `${day}.${month}.${d.getFullYear()} ${hours}:${minutes}`
}

export default function RequestCard({ request }) {
  const { user, category, requesting_datetime, 
    deadline_datetime, status, apartment, description, id, service_getting_periods } = request;
  const [showImage, toggleShowImage] = useToggle();

  return (
    <div className='request-card' style={{cursor: 'default'}}>
        <div className="request-column">
            {category && <SubcategoriesList category={category}/>}
        </div>
        <div className="request-column">
            <RequestData status={status.ge}
            datetime={requesting_datetime} 
            deadline={deadline_datetime}
            id={id}
            />
        </div>
        
        <div className="request-column">
            <UserData user={user} apartment={apartment}/>
        </div>
        
        <div className="request-column">
            {description && 
            <>
              
              <div className='card-main-category'>
                <p>{description ? description:''}</p>
              </div>
              <p style={{marginTop: 10}}>სერვისის მიღების პერიოდი</p>
              <div className='card-main-category'>
                {service_getting_periods.map((period) => 
                  <p>{dateToString(period.date)} | {period.from_time}-დან - {period.to_time}-მდე</p>
                )}
              </div>
              <p style={{marginTop: 10}}>დადასტურების ვადა</p>
              <div className='card-main-category'>
                <p>{date(request?.approval_deadline_datetime)}</p>
              </div>
            </>
            }
            {request.image_url && <img src={request.image_url} 
            alt='requestimg' className='request-img' onClick={toggleShowImage}/>}
        </div>
        
        <div className="request-column no-right-border">
            {status.en !== "pending_approval" && 
            <ExecutorSelect request_id={request.id} executor={request.executor} rate={request.rate}
            status={status.en} datetime={request.requesting_datetime}/> }

            {(status.en === "pending_approval" || status.en === "pending_payment" || status.en === "unpaid" || status.en === "rejected")  
            && 
            <RequestReaction id={id} request={request}/> }

        </div>
        <Modal 
          isOpen={showImage}
          overlayClassName='modal-overlay'
          ariaHideApp={false}
          className={'modal-content'}
          shouldCloseOnOverlayClick={true}
          onRequestClose={toggleShowImage}
        >
          <img src={request.image_url} alt='requestimg' className='request-img-big'/>
        </Modal>
    </div>
  )
}
