import React, { useRef } from 'react'
import useToggle from '../../hooks/useToggle'
import useOutsideClick from '../../hooks/useOutsideClick'
import arrow from '../../assets/arrow.png'
import PropTypes from 'prop-types';
import './Dropdown.css'

function Dropdown(props) {
  const { title, data, selected, setSelected, isError, height, width, disabled } = props;
  const [showList, toggleShowList] = useToggle(false);
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => toggleShowList(false));

  const onSelect = (e) => {
    if (disabled) {
      return;
    }
    setSelected(e);
    toggleShowList();
  }

  const onClick = () => {
    if (disabled || !data) {
      return;
    }
    toggleShowList();
  }

  const style = {
    border: isError && '1px solid var(--errorColor)', 
    height: height ? height : 'auto',
    width: width ? width : 'auto'
  }

  return (
    <div className='dropdown-container' ref={wrapperRef}>
      <div className='dropdown-selector' style={style} onClick={onClick}>
          {selected && <div className='dropdown-title'><nobr>{title}</nobr></div>}
          <p className={selected ? '':'dropdown-notselected'}>{!selected ? title:selected.name}</p>
          <img src={arrow} alt='arrow' className='arrow'/>
      </div>
      {showList && <div className='dropdown-list'>
          {data.map((e, i) => 
              <div 
                  key={e.id} 
                  className='dropdown-element'
                  onClick={() => onSelect(e)}
              >
                  {e.name}
              </div>)}
      </div>}
    </div>
  )
}

Dropdown.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
  isError: PropTypes.bool,
}

export default Dropdown;