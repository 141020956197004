import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getRequests, updateRequests } from '../../../../state/action-creators/requestActions.js';
import { getExecutors } from '../../../../state/action-creators/executorsActions.js';
import { getTypes } from '../../../../libs/API.js';
import DownloadButton from '../../../DownloadButton/DownloadButton.js';
import RequestHeader from './RequestHeader.js';
import RequestCard from './RequestCard.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import './requests.css';
import { filteredObject } from '../../../../libs/Functions.js';

export default function RequestsTable({tab, selectedCategory}) {
  const { items, isLoading, params, lastFetch } = useSelector(state => state.requests);
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(0);
  const user = useSelector(state => state.user.user);

  const skeletons = [1, 2, 3, 4];

  useEffect(() => {
    if(tab==="rates") return;
    dispatch(getExecutors());
    dispatch(getRequests(user, {load_from: 0, status: tab, category_id: selectedCategory?.id,
    sorting: tab==="processing" || tab==="done,closed" ? (tab==="processing" ? "assignment_datetime" : "execution_datetime"):null
  } ));
  }, [tab, selectedCategory]);

  useEffect(() => {
    getTypes((data) => setPageSize(data.page_size));
  }, []);

  return (
    <div className='invoice-table-container'>
        <RequestHeader tab={tab}/>
        <div className='request-list' id='request-list'>
          <InfiniteScroll
            dataLength={items.length}
            hasMore={lastFetch.length === pageSize}
            next={() => dispatch(updateRequests(user, {...params, load_from: items.length, status: tab}))}
            loader={<Skeleton height={100} className="card-skeleton"/>}
            endMessage={<p></p>}
            scrollableTarget={'request-list'}
            scrollThreshold={0.9}
          >
            { isLoading && 
                skeletons.map((_,i) => <Skeleton key={i}
                height={100} className="card-skeleton"/>)
              } 
              {items.map((req, i) => 
                <RequestCard key={i} request={req}/>
              )}
          </InfiniteScroll>
        </div>
        {items.length !== 0 && <DownloadButton link={'/admin/service/requests/filter/download'} params={filteredObject(params)} only_payments={false}/> }
    </div>
  )
}
