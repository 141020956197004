import React from "react";

export default function OwnerIcon({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 20V12H2L12 3L22 12H19V20H5ZM12 5.69L7 10.19V18H17V10.19L12 5.69ZM11 17V16H13V17H11ZM11 15C10.72 15 10.5 14.78 10.5 14.5V13.6C9.6 13.08 9 12.11 9 11C9 9.34 10.34 8 12 8C13.66 8 15 9.34 15 11C15 12.11 14.4 13.08 13.5 13.6V14.5C13.5 14.78 13.28 15 13 15H11Z"
        fill={fill}
      />
    </svg>
  );
}
