import { useMutation, useQueryClient } from "@tanstack/react-query";
import API from "../libs/API";
import { store } from "../state/store";
import { setMessage } from "../state/action-creators";

const user = store.getState().user.user;

export default function useWaiveMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ body, apartment_id, fee_category_id }) => {
      return API.put(
        user,
        `/admin/apartment/${apartment_id}/waive_fee/${fee_category_id}`,
        body
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["subcategory"]);
      const message = data._schema
        ? "არასწორი თარიღები"
        : "არჩეული ფართი გათავისუფლდა მონიშნული გადასახადიდან!";
      store.dispatch(setMessage(message));
    },
  });
}
