import { useQuery } from "@tanstack/react-query";
import { store } from "../state/store";
import API from "../libs/API";

const user = store.getState().user.user;

export function useRental(apartment_id) {
  return useQuery({
    queryKey: ["rental", apartment_id],
    queryFn: () =>
      API.get(user, `/apartment/${apartment_id}/rental`).then((res) => {
        return res.ownership_card;
      }),
    enabled: apartment_id !== null,
    refetchOnWindowFocus: false,
  });
}
