import React from "react";
import phone_icon from "../../../../assets/phoneIcon.png";
import { phoneToString } from "../../../../libs/Functions";

export default function User({ self }) {
  if (!self) {
    return "NULL";
  }
  return (
    <div className="rent-user">
      <img src={phone_icon} alt="phone_icon" />
      <p>
        {`${self?.name} ${self.surname} | ${self.pers_id} | ${phoneToString(
          self.country,
          self.phone
        )}`}{" "}
      </p>
    </div>
  );
}
