import React from "react";
import Modal from "react-modal";
import Calendar from "react-calendar";
import Dropdown from "../../../Dropdown/Dropdown.js";
import "./calendar.css";
import { generateHours } from "../../../../libs/Functions.js";

const hours = generateHours().slice(1);

function DatetimeInputModal({
  isOpen,
  toggleIsOpen,
  value,
  onChange,
  messageTime,
  setMessageTime,
  toggleDateReady,
  hasTime = true,
}) {
  const isDisabled = hasTime === true ? !value || !messageTime : !value;

  const onPut = () => {
    if (isDisabled) {
      return;
    }
    toggleDateReady(true);
    toggleIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      className={"modal-content datetime-input-modal"}
      shouldCloseOnOverlayClick={true}
      onRequestClose={toggleIsOpen}
    >
      <label className="calendar-title">გაგზავნის თარიღი და დრო</label>
      <div className="calendar-inner">
        <div
          className="calendar-left"
          style={
            hasTime
              ? {}
              : {
                  width: "100%",
                  borderRight: "none",
                  marginLeft: "50px",
                }
          }
        >
          <Calendar minDate={new Date()} value={value} onChange={onChange} />
        </div>
        {hasTime && (
          <div className="calendar-right">
            <Dropdown
              title="დრო"
              data={hours}
              selected={messageTime}
              setSelected={setMessageTime}
            />
          </div>
        )}
      </div>
      <div
        className={`calendar-put ${isDisabled && "add-button-disabled"}`}
        onClick={onPut}
      >
        ჩასმა
      </div>
    </Modal>
  );
}

export default DatetimeInputModal;
