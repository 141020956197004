import React, { useState, useEffect, useRef } from "react";
import "./form.css";
import close from "../../../assets/form-close.png";
import Dropdown from "../../Dropdown/Dropdown";
import PersonForm from "./PersonForm";
import ApartmentForm from "./ApartmentForm";
import ParkingForm from "./ParkingForm";
import editaddicon from "../../../assets/edit-add-icon.png";
import edit_add_filled from "../../../assets/edit_add_filled.png";
import { legalStatuses } from "../../../constants/index";
import { getTypes } from "../../../libs/API";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { actionCreators } from "../../../state";
import { bindActionCreators } from "redux";
import useToggle from "../../../hooks/useToggle";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toggleResponsiblePerson } from "../../../state/action-creators";
import Confirmation from "../../ConfirmationModal/Confirmation";
import { dateToString as date } from "../../../libs/Functions";
import PropertyUsersList from "./PropertyUsersList";
import OrganizationsList from "./OrganizationsList";
import ApartmentList from "./ApartmentList";
import OwnerList from "./OwnerList";
import LeaseOrganizationList from "./LeaseOrganizationList";
import ParkingList from "./ParkingList";

function OwnerForm({
  toggleShowForm,
  toggleEditing,
  editing,
  setSelectedTable,
  showLeaseForm,
  toggleShowLeaseForm,
  setApartmentForRental,
  setOwnersForRental,
  tab,
}) {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedResponsibleStatus, setSelectedResponsibleStatus] =
    useState(null);
  const [selectedApartmentType, setSelectedApartmentType] = useState(null);
  const [selectedParkingType, setSelectedParkingType] = useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [parkingTypes, setParkingTypes] = useState([]);
  const [apartmentTypes, setApartmentTypes] = useState([]);
  const [ownerAdding, toggleOwnerAdding] = useToggle();
  const [responsibleAdding, toggleResponsibleAdding] = useToggle();
  const [complexAdding, toggleComplexAdding] = useToggle();
  const [parkingAdding, toggleParkingAdding] = useToggle();

  const [userEditingRecord, setUserEditingRecord] = useState(null);

  const [selectedResponsible, setSelectedResponsible] = useState(null);

  const [complexEditingRecord, setComplexEditingRecord] = useState(null);
  const [parkingEditingRecord, setParkingEditingRecord] = useState(null);
  const [responsibleEditingRecord, setResponsibleEditingRecord] =
    useState(null);
  const [showConfirm, toggleShowConfirm] = useToggle();
  const [deleting, setDeleting] = useState(null);

  const organizationCodeRef = useRef(null);

  const setDeletingRecord = (value) => {
    toggleShowConfirm(true);
    setDeleting(value);
  };

  const [companyData, setCompanyData] = useState({
    code: "",
    name: "",
  });

  const [responsibleCompanyData, setrResponsibleCompanyData] = useState({
    code: "",
    name: "",
  });

  const onResponsibleCompanyChange = (e) => {
    setrResponsibleCompanyData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onChange = (e) => {
    setCompanyData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const property = useSelector((state) => state.currentProperty);

  const regular_users = property.users.filter((user) => !user.is_responsible);
  const regular_organizations = property.organizations.filter(
    (org) => !org.is_responsible
  );

  const responsible_users = property.users.filter(
    (user) => user.is_responsible
  );
  const responsible_organizations = property.organizations.filter(
    (org) => org.is_responsible
  );

  const user = useSelector((state) => state.user.user);
  const properties = useSelector((state) => state.properties);
  const isLease = tab === "lease";
  const isVisitor = tab === "visitor";
  const can_add = !(
    property.users.length === 0 &&
    property.apartments.length === 0 &&
    property.parking_spaces.length === 0
  );

  const dispatch = useDispatch();
  const {
    addProperty,
    getProperty,
    deleteProperty,
    deleteComplex,
    deleteOwner,
    deleteParking,
    resetProperty,
    saveToDrafts,
    setPropertiesStatus,
    getProperties,
  } = bindActionCreators(actionCreators, dispatch);

  const onAddProperty = () => {
    if (!can_add) {
      return;
    }
    addProperty(user, {
      users: property.users,
      apartments: property.apartments,
      parking_spaces: property.parking_spaces,
      organizations: property.organizations,
      card_id: property.card_id,
    });
    localStorage.removeItem("card_id");
    toggleShowForm(false);
    toggleEditing(false);
  };

  const onDelete = () => {
    const obj = deleting.obj;
    switch (deleting.name) {
      case "owner":
        deleteOwner(user, property.card_id, obj.id, null);
        break;
      case "organization":
        deleteOwner(user, property.card_id, null, obj.id);
        break;
      case "complex":
        deleteComplex(user, property.card_id, obj.id);
        break;
      case "parking":
        deleteParking(
          user,
          property.card_id,
          obj.parking_id,
          obj.ownership_type.en
        );
        break;
      default:
        break;
    }
    toggleShowConfirm(false);
  };

  const onClose = () => {
    if (editing) {
      toggleEditing(false);
      resetProperty();
      localStorage.removeItem("card_id");
      getProperties(user, {
        load_from: 0,
        status: tab === "draft" ? tab : properties.status,
        ownership_type: tab === "draft" ? "private" : tab,
      });
      return;
    }
    const card_id = localStorage.getItem("card_id");
    if (card_id) {
      deleteProperty(user, card_id);
      localStorage.removeItem("card_id");
    }
    localStorage.removeItem("card_id");
    toggleShowForm(false);
    getProperties(user, {
      load_from: 0,
      status: tab === "draft" ? tab : properties.status,
      ownership_type: tab === "draft" ? "private" : tab,
    });
  };

  const userAdd = () => {
    setUserEditingRecord(null);
    toggleOwnerAdding();
    setSelectedStatus(null);
  };

  const complexAdd = () => {
    setComplexEditingRecord(null);
    toggleComplexAdding();
    setSelectedApartmentType(null);
  };

  const parkingAdd = () => {
    setParkingEditingRecord(null);
    toggleParkingAdding();
    setSelectedParkingType(null);
    setSelectedPropertyType(null);
  };

  const responsibleAdd = () => {
    setResponsibleEditingRecord(null);
    toggleResponsibleAdding();
    setSelectedResponsibleStatus(null);
  };

  const draftAdd = () => {
    if (!can_add) {
      return;
    }
    saveToDrafts(user, property.card_id);
    localStorage.removeItem("card_id");
    toggleShowForm();
    setSelectedTable(3);
    setPropertiesStatus("draft");
    resetProperty();
  };

  const userEdit = (u) => {
    if (userEditingRecord) {
      setUserEditingRecord(null);
      setSelectedStatus(null);
      return;
    }
    setUserEditingRecord(u);
    setSelectedStatus({ name: "ფიზიკური პირი", id: 1 });
  };

  const responsibleUserEdit = (u) => {
    if (responsibleEditingRecord) {
      setResponsibleEditingRecord(null);
      setSelectedResponsibleStatus(null);
      return;
    }
    setResponsibleEditingRecord(u);
    setSelectedResponsibleStatus({ name: "ფიზიკური პირი", id: 1 });
  };

  const organizationEdit = (o) => {
    if (userEditingRecord) {
      setUserEditingRecord(null);
      setSelectedStatus(null);
      setCompanyData({
        name: "",
        code: "",
        organization_id: "",
      });
      return;
    }
    setCompanyData({
      name: o.name,
      code: o.identity_num,
      organization_id: o.id,
    });
    setSelectedStatus({ name: "იურიდიული პირი", id: 2 });
    setUserEditingRecord(o.representative);

    toggleOwnerAdding(true);
  };

  const responsibleOrganizationEdit = (o) => {
    if (responsibleEditingRecord) {
      setResponsibleEditingRecord(null);
      setSelectedResponsibleStatus(null);
      setrResponsibleCompanyData({
        name: "",
        code: "",
        organization_id: "",
      });
      return;
    }
    setrResponsibleCompanyData({
      name: o.name,
      code: o.identity_num,
      organization_id: o.id,
    });
    setSelectedResponsibleStatus({ name: "იურიდიული პირი", id: 2 });
    setResponsibleEditingRecord(o.representative);
    toggleResponsibleAdding(true);
  };

  const complexEdit = (c) => {
    if (complexEditingRecord) {
      setComplexEditingRecord(null);
      toggleComplexAdding(false);
      return;
    }
    setComplexEditingRecord(c);
    toggleComplexAdding(true);
  };

  const parkingEdit = (p) => {
    if (parkingEditingRecord) {
      setParkingEditingRecord(null);
      toggleParkingAdding(false);
      return;
    }
    setParkingEditingRecord(p);
    toggleParkingAdding(true);
  };

  const responsiblePersonToggle = (user_id) => {
    dispatch(toggleResponsiblePerson(property.card_id, user_id));
  };

  useEffect(() => {
    const card_id = localStorage.getItem("card_id");
    getTypes((data) => {
      setParkingTypes(data.parking.map((e) => ({ name: e.type.ge, id: e.id })));
      setApartmentTypes(
        data.complex.map((e) => ({ name: e.type.ge, id: e.id }))
      );
    });
    if (card_id && !editing) getProperty(user, card_id);

    return () => {
      localStorage.removeItem("card_id");
      toggleShowForm(false);
      toggleEditing(false);
      resetProperty();
    };
  }, []);

  // render functions
  const renderUserEditIcon = () => {
    if (editing && !ownerAdding && !isLease && !isVisitor) {
      return (
        <img
          src={editaddicon}
          alt="add"
          className="edit-add-icon"
          onClick={userAdd}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderUserAddIcon = () => {
    if (editing && ownerAdding && !isLease) {
      return (
        <img
          src={edit_add_filled}
          alt="add"
          className="edit-add-icon"
          onClick={userAdd}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderComplexAddIcon = () => {
    if (editing && !complexAdding && !isLease) {
      return (
        <img
          src={editaddicon}
          alt="add"
          className="edit-add-icon"
          onClick={complexAdd}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderComplexAddIconFilled = () => {
    if (editing && complexAdding && !isLease) {
      return (
        <img
          src={edit_add_filled}
          alt="add"
          className="edit-add-icon"
          onClick={complexAdd}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderParkingEditIcon = () => {
    if ((editing && !parkingAdding) || (isLease && !parkingAdding)) {
      return (
        <img
          src={editaddicon}
          alt="add"
          className="edit-add-icon"
          onClick={parkingAdd}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderParkingEditIconFilled = () => {
    if (editing && parkingAdding) {
      return (
        <img
          src={edit_add_filled}
          alt="add"
          className="edit-add-icon"
          onClick={parkingAdd}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderResponsibleAddIcon = () => {
    if (editing && !responsibleAdding) {
      return (
        <img
          src={editaddicon}
          alt="add"
          className="edit-add-icon"
          onClick={responsibleAdd}
        />
      );
    }
  };
  const renderResponsibleAddIconFilled = () => {
    if (editing && responsibleAdding) {
      return (
        <img
          src={edit_add_filled}
          alt="add"
          className="edit-add-icon"
          onClick={responsibleAdd}
        />
      );
    }
  };

  return (
    apartmentTypes &&
    parkingTypes && (
      <div className="table form-table">
        {
          <div className="owner-form">
            <Confirmation
              content={"ნამდვილად გსურთ ჩანაწერის წაშლა?"}
              onConfirm={onDelete}
              toggleShowConfirm={toggleShowConfirm}
              showConfirm={showConfirm}
            />
            <div className={`owner-form-left column-flex-centered`}>
              <div className="form-item">
                <div className="ownership-title-component">
                  {renderUserEditIcon()}
                  {renderUserAddIcon()}
                  <label>
                    {isVisitor
                      ? "ვიზიტორი"
                      : isLease
                      ? "ფართის დამქირავებელი"
                      : "ფართის მეპატრონე"}
                  </label>
                </div>
                {(ownerAdding || !editing) && (
                  <Dropdown
                    title="იურიდიული სტატუსი"
                    data={legalStatuses}
                    selected={selectedStatus}
                    setSelected={setSelectedStatus}
                  />
                )}

                {(selectedStatus?.name === "ფიზიკური პირი" ||
                  (selectedStatus?.name === "ფიზიკური პირი" &&
                    ownerAdding)) && (
                  <>
                    <PersonForm
                      selected={selectedStatus}
                      setSelected={setSelectedStatus}
                      editingRecord={userEditingRecord}
                      setEditingRecord={setUserEditingRecord}
                      editing={editing}
                    />
                  </>
                )}
                {(selectedStatus?.name === "იურიდიული პირი" ||
                  (selectedStatus?.name === "იურიდიული პირი" &&
                    ownerAdding)) && (
                  <>
                    <div className="organization-form">
                      <input
                        type="text"
                        name="code"
                        value={companyData.code}
                        onChange={onChange}
                        ref={organizationCodeRef}
                        className="input"
                        placeholder="ორგანიზაციის საიდენთიფიკაციო კოდი"
                      />
                      <input
                        type="text"
                        name="name"
                        value={companyData.name}
                        onChange={onChange}
                        className="input"
                        placeholder="ორგანიზაციის დასახელება"
                      />
                    </div>
                    <label>ორგანიზაციის წარმომადგენელი</label>
                    <PersonForm
                      company={companyData}
                      setCompanyData={setCompanyData}
                      organizationCodeRef={organizationCodeRef}
                      setSelected={setSelectedStatus}
                      selected={selectedStatus}
                      editingRecord={userEditingRecord}
                      setEditingRecord={setUserEditingRecord}
                      editing={editing}
                    />
                  </>
                )}
              </div>
              <div className="card-item-list owners-card-list">
                {(property.isLoading || property.owner_adding) && (
                  <Skeleton height={35} />
                )}
                <PropertyUsersList
                  users={regular_users}
                  isLease={isLease}
                  responsiblePersonToggle={responsiblePersonToggle}
                  setDeletingRecord={setDeletingRecord}
                  userEdit={userEdit}
                  userEditingRecord={userEditingRecord}
                />
                <OrganizationsList
                  organizations={regular_organizations}
                  isLease={isLease}
                  organizationEdit={organizationEdit}
                  setDeletingRecord={setDeletingRecord}
                  userEditingRecord={userEditingRecord}
                />

                {isLease && !property.isLoading && (
                  <div className="form-item lease-item">
                    <label style={{ marginLeft: 45 }}>ქირავნობის პერიოდი</label>
                    <div className="card-item" style={{ marginLeft: 40 }}>
                      <p>{`${date(property.to_date)} - დღემდე`}</p>
                    </div>
                  </div>
                )}
              </div>

              {!isLease && (
                <div className="form-item">
                  <div className="ownership-title-component">
                    {renderResponsibleAddIcon()}
                    {renderResponsibleAddIconFilled()}
                    <label>პასუხისმგებელი პირი</label>
                  </div>
                  {(responsibleAdding || !editing) && (
                    <Dropdown
                      title="იურიდიული სტატუსი"
                      data={legalStatuses}
                      selected={selectedResponsibleStatus}
                      setSelected={setSelectedResponsibleStatus}
                    />
                  )}
                  {(selectedResponsibleStatus?.name === "ფიზიკური პირი" ||
                    (selectedResponsibleStatus?.name === "ფიზიკური პირი" &&
                      responsibleAdding)) && (
                    <>
                      <PersonForm
                        selected={selectedResponsibleStatus}
                        setSelected={setSelectedResponsibleStatus}
                        editingRecord={responsibleEditingRecord}
                        setEditingRecord={setResponsibleEditingRecord}
                        isResponsible={true}
                        editing={editing}
                      />
                    </>
                  )}
                  {(selectedResponsibleStatus?.name === "იურიდიული პირი" ||
                    (selectedResponsibleStatus?.name === "იურიდიული პირი" &&
                      responsibleAdding)) && (
                    <>
                      <div className="organization-form">
                        <input
                          type="text"
                          name="code"
                          value={responsibleCompanyData.code}
                          onChange={onResponsibleCompanyChange}
                          className="input"
                          placeholder="ორგანიზაციის საიდენთიფიკაციო კოდი"
                        />
                        <input
                          type="text"
                          name="name"
                          value={responsibleCompanyData.name}
                          onChange={onResponsibleCompanyChange}
                          className="input"
                          placeholder="ორგანიზაციის დასახელება"
                        />
                      </div>
                      <label>ორგანიზაციის წარმომადგენელი</label>
                      <PersonForm
                        company={responsibleCompanyData}
                        setSelected={setSelectedResponsibleStatus}
                        selected={selectedResponsibleStatus}
                        editingRecord={responsibleEditingRecord}
                        setEditingRecord={setResponsibleEditingRecord}
                        isResponsible={true}
                        editing={editing}
                      />
                    </>
                  )}
                </div>
              )}
              <div className="card-item-list owners-card-list">
                {(property.isLoading || property.owner_adding) && (
                  <Skeleton height={35} />
                )}
                <PropertyUsersList
                  users={responsible_users}
                  isLease={isLease}
                  setDeletingRecord={setDeletingRecord}
                  userEdit={responsibleUserEdit}
                  userEditingRecord={responsibleEditingRecord}
                  isResponsible={true}
                  selectedResponsible={selectedResponsible}
                  setSelectedResponsible={setSelectedResponsible}
                />
                <OrganizationsList
                  organizations={responsible_organizations}
                  isLease={isLease}
                  organizationEdit={responsibleOrganizationEdit}
                  setDeletingRecord={setDeletingRecord}
                  userEditingRecord={responsibleEditingRecord}
                  userEdit={responsibleUserEdit}
                  isResponsible={true}
                  selectedResponsible={selectedResponsible}
                  setSelectedResponsible={setSelectedResponsible}
                />
              </div>
            </div>
            <div className="owner-form-right">
              <div className="form-item">
                {!isVisitor && (
                  <>
                    <div className="ownership-title-component">
                      {renderComplexAddIcon()}

                      {renderComplexAddIconFilled()}
                      <label>ფართის მონაცემი</label>
                    </div>

                    {(complexAdding || !editing) && !isLease && (
                      <ApartmentForm
                        selected={selectedApartmentType}
                        setSelected={setSelectedApartmentType}
                        types={apartmentTypes}
                        editingRecord={complexEditingRecord}
                        toggleComplexAdding={toggleComplexAdding}
                        setEditingRecord={setComplexEditingRecord}
                      />
                    )}
                    <div className="card-item-list">
                      {(property.isLoading || property.complex_adding) && (
                        <Skeleton height={35} />
                      )}
                      <ApartmentList
                        apartments={property.apartments}
                        complexEdit={complexEdit}
                        complexEditingRecord={complexEditingRecord}
                        isLease={isLease}
                        setDeletingRecord={setDeletingRecord}
                        selectedResponsible={selectedResponsible}
                        card_id={property.card_id}
                        showLeaseForm={showLeaseForm}
                        toggleShowLeaseForm={toggleShowLeaseForm}
                        toggleShowForm={toggleShowForm}
                        setApartmentForRental={setApartmentForRental}
                        users={property.users}
                        organizations={property.organizations}
                        setOwnersForRental={setOwnersForRental}
                      />
                    </div>
                  </>
                )}

                {isLease && (
                  <>
                    <div className="ownership-title-component">
                      <label>ფართის მეპატრონე</label>
                    </div>
                    <div className="card-item-list owners-card-list">
                      {(property.isLoading || property.parking_adding) && (
                        <Skeleton height={35} />
                      )}
                      <OwnerList
                        owners={property}
                        isLease={isLease}
                        setDeletingRecord={setDeletingRecord}
                        userEdit={userEdit}
                        userEditingRecord={userEditingRecord}
                        user={user}
                      />
                      <LeaseOrganizationList
                        property={property}
                        isLease={isLease}
                        organizationEdit={organizationEdit}
                        setDeletingRecord={setDeletingRecord}
                        userEditingRecord={userEditingRecord}
                      />
                    </div>
                  </>
                )}
                <div className="ownership-title-component">
                  {renderParkingEditIcon()}

                  {renderParkingEditIconFilled()}
                  <label>პარკინგის მონაცემი</label>
                </div>
                {(parkingAdding || !editing) && (
                  <ParkingForm
                    selectedParking={selectedParkingType}
                    setSelectedParking={setSelectedParkingType}
                    selectedProperty={selectedPropertyType}
                    setSelectedProperty={setSelectedPropertyType}
                    editingRecord={parkingEditingRecord}
                    toggleParkingAdding={toggleParkingAdding}
                    setEditingRecord={setParkingEditingRecord}
                    types={parkingTypes}
                  />
                )}
                <div className="card-item-list">
                  {property.isLoading && <Skeleton height={35} />}
                  <ParkingList
                    parking_spaces={property.parking_spaces}
                    isLease={isLease}
                    parkingEdit={parkingEdit}
                    parkingEditingRecord={parkingEditingRecord}
                    setDeletingRecord={setDeletingRecord}
                    selectedResponsible={selectedResponsible}
                    card_id={property.card_id}
                  />
                </div>
              </div>
            </div>
          </div>
        }
        <img src={close} alt="close" className="form-close" onClick={onClose} />
        {!isLease && (
          <div className="form-actions">
            {(!editing || tab === "draft") && (
              <div
                className={`form-btn-primary ${
                  !can_add && "add-button-disabled"
                }`}
                onClick={onAddProperty}
              >
                დამატება
              </div>
            )}
            {!editing && (
              <div
                className={`form-btn-secondary ${
                  !can_add && "add-button-disabled"
                }`}
                onClick={draftAdd}
              >
                მონახაზში შენახვა
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
}

OwnerForm.propTypes = {
  toggleShowForm: PropTypes.func,
  toggleEditing: PropTypes.func,
  editing: PropTypes.bool,
  setSelectedTable: PropTypes.func,
  tab: PropTypes.string,
};

export default OwnerForm;
