import './OwnersCard.css';

const OwnersTableHeader = ({tab}) => {
    const isLease = tab==="lease";
    const isVisitor = tab==="visitor";
    let className = '';

    if (isLease) {
        className = 'lease_card owners-header';
    }
    else if (isVisitor) {
        className = "visitor_card owners-header";
    } else {
        className = "owners_card owners-header";
    }

    return ( <div className="owners-table-header">
        <div className={className}>
            <div className="card-rows header-cell">
                {isVisitor ? "ვიზიტორი": (isLease ? "ფართის დამქირავებელი":"ფართს მეპატრონე")}
            </div>
            {!isVisitor && <div className="apartment-rows header-cell">
                ფართის მონაცემი
            </div>}
            {isLease && <div className='header-cell'>
                ქირავნობის პერიოდი
            </div>}
            <div className="parking-column header-cell">
                პარკინგის მონაცემი
            </div>
        </div>
    </div> );
}
 
export default OwnersTableHeader;