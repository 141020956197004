import {
  CLEAR_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  RESET_SEENS,
  SET_NOTIFICATIONS_LOADING,
  SET_NOTIFICATIONS_PARAMS,
  UPDATE_NOTIFICATIONS,
} from "../action-creators/types";

const initialState = {
  items: [],
  isLoading: false,
  params: {
    load_from: 0,
  },
  lastFetch: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        items: action.payload.posts,
        lastFetch: action.payload.posts,
        isLoading: false,
      };
    case SET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_NOTIFICATIONS_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case RESET_SEENS:
      return {
        ...state,
        items: state.items.map((notification) => {
          return {
            ...notification,
            target_users: notification.target_users.map((user) => {
              return user.id === action.payload
                ? { ...user, unseen_comments: 0 }
                : user;
            }),
          };
        }),
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        items: [],
      };
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        items: [...state.items, ...action.payload],
        lastFetch: action.payload,
        isLoading: false,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
};

export default reducer;
