import React, { useState, useEffect } from 'react'
import './Login.css'
import close from '../../assets/close.png'
import eye from '../../assets/eye.png'
import lucide_eye from '../../assets/lucide_eye.png';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../state/index';

const Login = () => {
  const [formData, setFormData] = useState({
    name: "",
    password: "",
  });
  const [passType, setPassType] = useState('password');

  const user = useSelector(state => state.user)

  const dispatch = useDispatch()
  const { loginUser, resetUser, setError } = bindActionCreators(actionCreators, dispatch)

  const togglePassType = () => {
    passType === 'password' ? setPassType('text'):setPassType('password')
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, password } = formData
    if (name.length === 0 || password.length === 0) {
      setError("შეავსე ყველა ველი")
      return
    }
    loginUser(formData.name, formData.password)
  }

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  useEffect(() => {
    if (user.user)  {
      window.location.assign('/home');
    }
  }, [user.user])

  return (
    <div className='page-container column-flex-centered' style={{justifyContent: 'center'}}>
        <div className='login-inner column-flex-centered shadow'>
            <div className='login-headers'>
              <h4>შპს რეზიდ სერვისი</h4>
              <h1>rezid.bpms.ge</h1>
              <h4>ბიზნეს პროცესების მართვის სისტემა</h4>
              <form className='login-form column-flex-centered' onSubmit={onSubmit}>
                <p>ავტორიზაცია</p>
                <div className='input-container'>
                  <input 
                    className={user.isError ? 'form-input error-border disabled':'form-input'} 
                    type="text" name='name'
                    placeholder='მომხმარებელი'
                    value={formData.name}
                    onChange={onChange}
                  />
                </div>
                <div className='input-container'>
                  <input
                    className={user.isError ? 'form-input error-border disabled':'form-input'}
                    type={passType} name='password'
                    value={formData.password}
                    placeholder='პაროლი'
                    onChange={onChange}
                  />
                  <img src={passType==="password" ? eye:lucide_eye} alt='eye' className='eye-icon' onClick={togglePassType}/>
                </div>
                <button 
                  className={user.isError ? 'form-button disabled':'form-button'}>
                    {!user.isLoading && "შესვლა"}
                    {user.isLoading && <div className='loader'></div>}
                </button>
                
                {user.isError && 
                  <div className='error-msg'>
                    <p>{user.message}</p>
                    <img src={close} alt='close' className='close-icon' onClick={resetUser}/>
                  </div>
                }
              </form>
            </div>
        </div>
        <div className='login-inner shadow copyright'>
        Developed by LLC BPMS | bpms.ge
        </div>
    </div>
  )
}

export default Login;