import React, { useEffect, useState } from "react";
import "./lease.css";
import Apartment from "./Apartment";
import RentalForm from "./RentalForm";
import Users from "./Users";
import RentInfo from "./RentInfo";
import useCancelRentalMutation from "../../../../hooks/useCancelRentalMutation";
import useToggle from "../../../../hooks/useToggle";
import Confirmation from "../../../ConfirmationModal/Confirmation";
import ParkingList from "../ParkingList";
import edit_add from "../../../../assets/edit-add-icon.png";
import edit_add_filled from "../../../../assets/edit_add_filled.png";

import bin from "../../../../assets/bin.png";
import edit_icon from "../../../../assets/edit_icon.png";
import edit_icon_filled from "../../../../assets/filled_edit_icon.png";
import ParkingForm from "../ParkingForm";
import API, { getTypes } from "../../../../libs/API";
import { useSelector } from "react-redux";
import RentalEditForm from "./RentalEditForm";

export default function LeaseForm({
  toggleShowLeaseForm,
  card,
  ownersForRental,
  setCard,
  apartmentForRental,
  setApartmentForRental,
}) {
  const apartment = card?.apartments ? card.apartments[0] : apartmentForRental;
  const [showCancelConfirmation, toggleShowCancelConfirmation] = useToggle();
  const [parkingEditingRecord, setParkingEditingRecord] = useState(null);
  const [parkingAdding, toggleParkingAdding] = useToggle();
  const [parkingTypes, setParkingTypes] = useState([]);

  const [editingRecord, setEditingRecord] = useState(null);

  const [selectedParkingType, setSelectedParkingType] = useState(null);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [parkingDeletingRecord, setParkingDeletingRecord] = useState(null);
  const [showParkingDeleteConfirm, toggleShowParkingDeleteConfirm] =
    useToggle();

  const user = useSelector((state) => state.user.user);

  const isOverDated = card ? new Date(card.to_date) < new Date() : false;

  console.log(card);

  const cancelMutation = useCancelRentalMutation();

  const cancelRental = () => {
    cancelMutation.mutate({
      apartmentId: apartment.id,
      callBack: () => {
        setApartmentForRental(apartment);
        setCard(null);
      },
    });
  };

  const parkingEdit = (p) => {
    if (parkingEditingRecord) {
      setParkingEditingRecord(null);
      toggleParkingAdding(false);
      return;
    }
    setParkingEditingRecord(p);
    toggleParkingAdding(true);
  };

  const parkingAdd = () => {
    setParkingEditingRecord(null);
    toggleParkingAdding();
    setSelectedParkingType(null);
    setSelectedPropertyType(null);
  };

  const addParkingToCard = (parking) => {
    //const includes = card.parking_spaces.map((e) => e.id).includes(parking.id);
    setCard({
      ...card,
      parking_spaces: [parking],
    });
    setParkingEditingRecord(null);
    toggleParkingAdding(false);
  };

  const setDeletingRecord = (value) => {
    toggleShowParkingDeleteConfirm();
    setParkingDeletingRecord(value);
  };

  const removeParkingFromCard = () => {
    const parking = parkingDeletingRecord.obj;
    API.delete(
      user,
      `/admin/ownership_card/parking`,
      {
        card_id: card.id,
        parking_id: parking.parking_id,
        ownership_type: parking.ownership_type.en,
      },
      (res) => {
        setCard({
          ...card,
          parking_spaces: card.parking_spaces.filter(
            (space) => space.id !== parking.id
          ),
        });
        toggleShowParkingDeleteConfirm();
      }
    );
  };

  const onEditClick = () => {
    setEditingRecord(card);
  };

  useEffect(() => {
    getTypes((data) => {
      setParkingTypes(data.parking.map((e) => ({ name: e.type.ge, id: e.id })));
    });

    return () => {
      setCard(null);
      setApartmentForRental(null);
      toggleShowLeaseForm(false);
    };
  }, []);

  return (
    <div className="table form-table">
      <Confirmation
        content={"ნამდვილად გსურთ ქირავნობის გაუქმება?"}
        showConfirm={showCancelConfirmation}
        toggleShowConfirm={toggleShowCancelConfirmation}
        onConfirm={cancelRental}
      />
      <Confirmation
        content={"ნამდვილად გსურთ პარკინგის წაშლა?"}
        showConfirm={showParkingDeleteConfirm}
        toggleShowConfirm={toggleShowParkingDeleteConfirm}
        onConfirm={removeParkingFromCard}
      />
      <div className="lease-form">
        <div className="lease-form-left" style={{ paddingRight: "80px" }}>
          {card?.ownership_type.en !== "lease" && (
            <RentalForm
              apartment={apartment}
              card={card}
              setCard={setCard}
              toggleShowLeaseForm={toggleShowLeaseForm}
            />
          )}
          {card?.ownership_type.en === "lease" && (
            <div className="rent-info-outter">
              <RentInfo card={card} editingRecord={editingRecord} />
              {!editingRecord && !isOverDated && (
                <div className="rental-edit-icons">
                  <img
                    src={parkingEditingRecord ? edit_icon_filled : edit_icon}
                    alt="edit"
                    className="edit-icon"
                    onClick={onEditClick}
                  />

                  {apartment.is_rented && (
                    <img
                      src={bin}
                      alt="bin"
                      className="bin"
                      onClick={toggleShowCancelConfirmation}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {editingRecord && (
            <RentalEditForm
              editingRecord={editingRecord}
              setEditingRecord={setEditingRecord}
              setCard={setCard}
            />
          )}
        </div>
        <div className="lease-form-right">
          <Apartment apartment={apartment} />
          {card?.owners?.users?.length > 0 && (
            <Users users={card.owners.users} />
          )}
          {!card && (
            <Users
              users={ownersForRental.users}
              organizations={ownersForRental.organizations}
            />
          )}
          {card && (
            <div className="rental-parking-container">
              <div className="d-flex-centered rental-parking-title">
                <img
                  src={parkingAdding ? edit_add_filled : edit_add}
                  alt="add-icon"
                  onClick={parkingAdd}
                />
                <label style={{ marginLeft: "20px" }}>პარკინგის მონაცემი</label>
              </div>
              {parkingAdding && (
                <ParkingForm
                  selectedParking={selectedParkingType}
                  setSelectedParking={setSelectedParkingType}
                  selectedProperty={selectedPropertyType}
                  setSelectedProperty={setSelectedPropertyType}
                  editingRecord={parkingEditingRecord}
                  toggleParkingAdding={toggleParkingAdding}
                  setEditingRecord={setParkingEditingRecord}
                  addParkingToCard={addParkingToCard}
                  card={card}
                  types={parkingTypes}
                />
              )}
              <ParkingList
                parking_spaces={card.parking_spaces}
                isLease={false}
                parkingEdit={parkingEdit}
                parkingEditingRecord={parkingEditingRecord}
                setDeletingRecord={setDeletingRecord}
                selectedResponsible={null}
                card_id={card.id}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
