import React from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import ModuleCard from "../../components/ModuleCard/ModuleCard";
import Footer from "../../components/Footer/Footer";
import servicesIcon from "../../assets/servicesIcon.svg";
import howToDo from "../../assets/howToDo.svg";
import finances from "../../assets/finances.svg";
import notification from "../../assets/notification.png";
import { useUnseenCounts } from "../../hooks/useUnseenCounts";

const Home = () => {
  const { data, isFetching } = useUnseenCounts();

  if (isFetching) {
    return "...Loading";
  }

  return (
    <div className="page-container">
      <Header />
      <div className="modules">
        <div className="modules-row">
          <Link
            to="/dashboard/services/requests/pending_approval"
            className="text-link"
          >
            <ModuleCard
              moduleIcon={servicesIcon}
              moduleNameEN="services"
              moduleNameGE="სერვისების მართვის მოდული"
            />
          </Link>
          <Link to="/dashboard/payments/service/unpaid" className="text-link">
            <ModuleCard
              moduleIcon={finances}
              moduleNameEN="payments"
              moduleNameGE="გადახდების მართვის მოდული"
            />
          </Link>
          <Link to={"/dashboard/how-to-do"} className="text-link">
            <ModuleCard
              moduleIcon={howToDo}
              moduleNameEN="how to do"
              moduleNameGE="ვიდეო ინსტრუქციების მართვის მოდული"
            />
          </Link>
        </div>
        <div className="modules-row">
          <Link
            to={"/dashboard/notifications/messages/sent"}
            className="text-link notifications-module-card"
          >
            <ModuleCard
              moduleIcon={notification}
              moduleNameEN="notifications & offers"
              moduleNameGE="შეტყობინებების და შეთავაზებების მოდული"
              commentsCount={
                data.unseen_comments_count?.sent +
                data.unseen_comments_count?.received +
                data.unseen_posts_count
              }
            />
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
