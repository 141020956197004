import React, { useEffect, useState } from "react";
import ApartmentFilter from "./ApartmentFilter";
import ApartmentList from "./ApartmentList";
import CategoryList from "./CategoryList";
import "./index.css";
import SavePeriod from "./SavePeriod";
import useWaiveMutation from "../../../../../hooks/useWaiveMutation";
import useRemoveWaiveMutation from "../../../../../hooks/useRemoveWaiveMutation";

function TaxReliefTable() {
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [complexTypes, setComplexTypes] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [complexType, setComplexType] = useState(null);
  const [params, setParams] = useState({
    floor: "",
    apartment_number: "",
  });

  const [searchCount, setSearchCount] = useState(0);

  // States for categories
  const [categories, setCategories] = useState([]);

  // saving waive mutation
  const createWaiveMutation = useWaiveMutation();
  const removeWaiveMutation = useRemoveWaiveMutation();

  const onSubcategoryClick = (id) => {
    if (!selectedApartment) {
      return;
    }
    setSelectedSubcategory(id);
  };

  useEffect(() => {
    if (!selectedApartment) setSelectedSubcategory(null);
  }, [selectedApartment]);

  return (
    <div className="table constructor-table">
      <div className="constructor-col apartments-col">
        <ApartmentFilter
          params={params}
          setParams={setParams}
          complexType={complexType}
          setComplexType={setComplexType}
          complexTypes={complexTypes}
          setComplexTypes={setComplexTypes}
          setSearchCount={setSearchCount}
        />
        <ApartmentList
          complexType={complexType}
          params={params}
          selectedApartment={selectedApartment}
          setSelectedApartment={setSelectedApartment}
          searchCount={searchCount}
          setSearchCount={setSearchCount}
        />
      </div>
      <div className="constructor-col categories-col">
        <label>გადასახადები</label>
        <CategoryList
          categories={categories}
          setCategories={setCategories}
          selectedSubcategory={selectedSubcategory}
          setSelectedSubcategory={setSelectedSubcategory}
          onSubcategoryClick={onSubcategoryClick}
          selectedApartment={selectedApartment}
        />
      </div>
      <div className="constructor-col categories-col no-right-border">
        <label>პერიოდი</label>
        {selectedApartment && selectedSubcategory && (
          <SavePeriod
            createWaiveMutation={createWaiveMutation}
            removeWaiveMutation={removeWaiveMutation}
            selectedApartment={selectedApartment}
            selectedSubcategory={selectedSubcategory}
          />
        )}
      </div>
    </div>
  );
}

export default TaxReliefTable;
