import { ADD_CATEGORY, ADD_SUBCATEGORY, CATEGORIES_ERROR, CLEAR_CATEGORIES, CLEAR_SUBCATEGORIES, 
    GET_CATEGORIES, GET_SUBCATEGORIES, SAVE_FEE, SET_CATEGORIES_LOADING, SET_CATEGORY_UPDATE_LOADING, SET_FEE_SAVING_LOADING, SET_SUBCATEGORIES_LOADING, 
    SET_SUBCATEGORY_UPDATE_LOADING, 
    SUBCATEGORIES_ERROR, TOGGLE_CATEGORY_VISIBILITY, UPDATE_CATEGORY, UPDATE_SUBCATEGORY } from "./types"
import API from '../../libs/API.js';
import { store } from '../../state/store.js';
const user = store.getState().user.user;

export const getCategories = (user, isfee=false) => {
    return (dispatch) => {
        dispatch({type: SET_CATEGORIES_LOADING, payload: true});
        dispatch({type: CLEAR_CATEGORIES});
        dispatch({type: CLEAR_SUBCATEGORIES});
        API.get(user, isfee ? `/admin/fee/categories`:`/service/categories`, null, 
        (res) => dispatch({type: GET_CATEGORIES, payload: res.categories, isfee: isfee}),
        (err) => dispatch({type: CATEGORIES_ERROR, payload: err}),
        )
    }
}

export const getSubcategories = (user, parent_id, isfee=false) => {
    return (dispatch) => {
        dispatch({type: SET_SUBCATEGORIES_LOADING, payload: true});
        dispatch({type: CLEAR_SUBCATEGORIES});
        API.get(user, isfee ? `/admin/fee/categories`:`/service/categories`, {parent_id: parent_id},
        (res) => dispatch({type: GET_SUBCATEGORIES, payload: res.categories, isfee: isfee}),
        (err) => dispatch({type: SUBCATEGORIES_ERROR, payload: err}),
        )
    }
}

export const clearCategories = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_CATEGORIES,
        })
    }
}

export const clearSubcategories = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SUBCATEGORIES,
        })
    }
}

export const toggleCategoryVisibility = (user, id) => {
    return (dispatch) => {
        dispatch({type: TOGGLE_CATEGORY_VISIBILITY, payload: id});
        API.put(user, `/admin/service/category/visibility`, {id},);
    }
}

export const createCategory = (user, data) => {
    const type = data.parent_id ? ADD_SUBCATEGORY : ADD_CATEGORY;
    return (dispatch) => {
        API.post(user, `/admin/service/category`, data, 
        (res) => dispatch({type: type, payload: res.category}));
    }
}

export const updateCategory = (user, data) => {
    const type = data.parent_id ? UPDATE_SUBCATEGORY : UPDATE_CATEGORY;
    const loadingType = data.parent_id ? SET_SUBCATEGORY_UPDATE_LOADING : SET_CATEGORY_UPDATE_LOADING;
    return (dispatch) => {
        dispatch({type: loadingType, payload: true});
        API.put(user, `/admin/service/category`, data, 
        (res) => dispatch({type: type, payload: res.category}));
    }
}

export const saveFee = (id, data, newFee) => {
    return (dispatch) => {
        dispatch({type: SET_FEE_SAVING_LOADING, payload: true});
        API.put(user, `/admin/fee/${newFee.id}`, data, 
        () => dispatch({type: SAVE_FEE, payload: [id, newFee]}));
    }
}
