import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { removeMessage } from "./state/action-creators";
import close from "./assets/form-close.png";
import Login from "./pages/Login/Login.js";
import Home from "./pages/Home/Home.js";
import Dashboard from "./pages/Dashboard/Dashboard.js";
import { useRef } from "react";
import useOutsideClick from "./hooks/useOutsideClick.js";

function App() {
  const user = useSelector((state) => state.user);
  const popup = useSelector((state) => state.popup);

  const errorRef = useRef(null);

  const dispatch = useDispatch();

  useOutsideClick(errorRef, () => dispatch(removeMessage()));

  const routes = (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Navigate to={user.user ? "/home" : "/login"} replace />}
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard/:module/:attribute/:tab"
          element={user.user ? <Dashboard /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/dashboard/:module"
          element={user.user ? <Dashboard /> : <Navigate to="/login" replace />}
        />
        {/* <Route path="/home" element={<Home />}/> */}
        <Route
          path="/home"
          element={user.user ? <Home /> : <Navigate to="/login" replace />}
        />
        {/* <Route path="/home" element={ <Home />}/> */}
      </Routes>
    </Router>
  );

  return (
    <>
      {routes}
      {popup.msg !== "" && (
        <div className="error-msg" ref={errorRef}>
          <p>{popup.msg}</p>
          <img
            src={close}
            alt="close"
            className="close-icon"
            style={{ width: "15px" }}
            onClick={() => dispatch(removeMessage())}
          />
        </div>
      )}
    </>
  );
}

export default App;
