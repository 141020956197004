import {
  APPROVE_INVOICES,
  CANCEL_INVOICE,
  CLEAR_INVOICES,
  CLOSE_INVOICES,
  GET_INVOICES,
  RESET_INVOICE_STATE,
  SAVE_INVOICE,
  SET_INVOICES_LOADING,
  SET_INVOICES_PARAMS,
  SET_INVOICES_UPDATING,
  TOGGLE_CLOSING_CANDIDATE,
  TOGGLE_EVERY_CLOSING_CANDIDATE,
  UPDATE_INVOICES,
} from "../action-creators/types";

const initialState = {
  items: [],
  total_cost_sum: null,
  params: {
    load_from: 0,
    status: "unpaid",
    invoice_type: "service",
  },
  lastFetch: [],
  updating: false,
  isLoading: false,
  isError: false,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        items: action.payload.invoices,
        total_cost_sum: action.payload.total_cost_sum,
        isLoading: false,
        updating: false,
        lastFetch: action.payload.invoices,
        message: "fetched",
      };
    case UPDATE_INVOICES:
      return {
        ...state,
        items: [...state.items, ...action.payload.invoices],
        total_cost_sum: action.payload.total_cost_sum,
        lastFetch: action.payload.invoices,
        isLoading: false,
        updating: false,
        message: "updated",
      };
    case SET_INVOICES_PARAMS:
      return {
        ...state,
        params: action.payload,
      };
    case SAVE_INVOICE:
      const updated = action.payload;
      return {
        ...state,
        items: state.items.map((invoice) =>
          invoice.id === updated.id ? updated : invoice
        ),
      };
    case CANCEL_INVOICE:
      return {
        ...state,
        items: state.items.filter((invoice) => invoice.id !== action.payload),
      };
    case APPROVE_INVOICES:
      let result = state.items;
      let load = action.payload.map((e) => e.id);
      result = result.filter((e) => !load.includes(e.id));
      return {
        ...state,
        items: result,
      };
    case CLOSE_INVOICES:
      let data = action.payload.invoices_closing_data.map((e) => e.invoice_id);
      return {
        ...state,
        items: state.items.filter((e) => !data.includes(e.id)),
      };
    case TOGGLE_EVERY_CLOSING_CANDIDATE:
      return {
        ...state,
        items: state.items.map((item) => ({
          ...item,
          isSelectedForClosing: action.payload,
        })),
      };
    case TOGGLE_CLOSING_CANDIDATE:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.payload
            ? { ...item, isSelectedForClosing: !item.isSelectedForClosing }
            : item
        ),
      };
    case SET_INVOICES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        items: [],
      };
    case SET_INVOICES_UPDATING:
      return {
        ...state,
        updating: action.payload,
      };
    case RESET_INVOICE_STATE:
      return initialState;
    case CLEAR_INVOICES:
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
};

export default reducer;
