import React from "react";
import { datetimeToString, localDateString } from "../../../../libs/Functions";
import chat_icon from "../../../../assets/chat_icon.png";

function Comment({ comment, isMine }) {
  const { text, sending_datetime } = comment;
  const dateToString = localDateString(sending_datetime);

  return (
    <div className={isMine ? "my-comment-container" : "comment-container"}>
      <img alt="chat-icon" src={chat_icon} className="comment-chat-icon" />
      <div
        className={"comment"}
        style={{ alignItems: isMine ? "flex-end" : "" }}
      >
        <div className="comment-date">{dateToString}</div>
        <p className={isMine ? `my-comment-text` : `comment-text`}>{text}</p>
      </div>
    </div>
  );
}

export default Comment;
