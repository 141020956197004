import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTypes } from "../../../../libs/API";
import FilterDropdown from "./FilterDropdown.js";
import { DateInput } from "./LeasesFilter.js";
import { dateJSON, filteredObject } from "../../../../libs/Functions";
import {
  getCategories,
  getRequests,
  getSubcategories,
} from "../../../../state/action-creators";
import { setRequestsParams } from "../../../../state/action-creators";

export default function RequestFilter({
  tab,
  globalCategory,
  setGlobalCategory,
}) {
  const [complexTypes, setComplexTypes] = useState([]);
  const [complexType, setComplexType] = useState(null);
  //const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(globalCategory);
  //const [subcategories, setSubcategories] = useState([]);
  const [subcategory, setSubcategory] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const categories = useSelector((state) =>
    state.categories.items.map((c) => ({ name: c.name.ge, id: c.id }))
  );
  const subcategories = useSelector((state) =>
    state.subcategories.items.map((c) => ({ name: c.name.ge, id: c.id }))
  );
  const globalParams = useSelector((state) => state.requests.params);
  const executors = useSelector((state) =>
    state.executors.items.map((e) => ({
      name: e.name + " " + e.surname,
      id: e.id,
    }))
  );
  const [executor, setExecutor] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);

  const isApproved = tab === "pending_payment" || tab === "unpaid";
  const isRejected = tab === "rejected";

  const [params, setParams] = useState({
    apartment_number: "",
    request_id: "",
    from_requesting_datetime: "",
    to_requesting_datetime: "",
    user_name: "",
    user_surname: "",
    user_pers_id: "",
    from_execution_date: "",
    to_execution_date: "",
    from_approval_datetime: "",
    to_approval_datetime: "",
    from_payment_deadline_datetime: "",
    to_payment_deadline_datetime: "",
    from_approval_deadline_datetime: "",
    to_approval_deadline_datetime: "",
    from_rejection_datetime: "",
    to_rejection_datetime: "",
  });

  const onChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    category && dispatch(getSubcategories(user, category.id));
  }, [category]);

  useEffect(() => {
    if (globalCategory === null) return;
    setCategory(globalCategory);
  }, [globalCategory]);

  const onCategoryChange = (e) => {
    setGlobalCategory(null);
    setCategory(e);
  };

  const resetFilter = () => {
    setParams({
      apartment_number: "",
      request_id: "",
      from_requesting_datetime: "",
      to_requesting_datetime: "",
      user_name: "",
      user_surname: "",
      user_pers_id: "",
      from_execution_date: "",
      to_execution_date: "",
      from_approval_datetime: "",
      to_approval_datetime: "",
      from_payment_deadline_datetime: "",
      to_payment_deadline_datetime: "",
      from_approval_deadline_datetime: "",
      to_approval_deadline_datetime: "",
      from_rejection_datetime: "",
      to_rejection_datetime: "",
    });
    setCategory(null);
    setComplexType(null);
    setSubcategory(null);
    setExecutor(null);
    setSelectedRate(null);
  };

  const onSearch = () => {
    const temp_params = {
      ...globalParams,
      user_name: params.user_name,
      user_surname: params.user_surname,
      user_pers_id: params.user_pers_id,
      //...(!isNaN(params.request_id) && {request_id: params.request_id}),
      request_id: Number(params.request_id),
      apartment_number: params.apartment_number,
      to_requesting_datetime: dateJSON(params.to_requesting_datetime),
      from_requesting_datetime: dateJSON(params.from_requesting_datetime),
      from_execution_datetime: dateJSON(params.from_requesting_datetime),
      to_execution_datetime: dateJSON(params.to_requesting_datetime),
      from_approval_datetime: dateJSON(params.from_approval_datetime),
      to_approval_datetime: dateJSON(params.to_approval_datetime),
      from_payment_deadline_datetime: dateJSON(
        params.from_payment_deadline_datetime
      ),
      from_approval_deadline_datetime: dateJSON(
        params.from_approval_deadline_datetime
      ),
      from_rejection_datetime: dateJSON(params.from_rejection_datetime),
      rate: selectedRate?.name,
      status: tab,
      complex_type_id: Number(complexType ? complexType.id : 0),
      category_id: category?.id,
      subcategory_id: subcategory?.id,
      executor_id: executor?.id,
      sorting:
        tab === "processing" || tab === "done,closed"
          ? tab === "processing"
            ? "assignment_datetime"
            : "execution_datetime"
          : null,
    };
    dispatch(setRequestsParams(filteredObject(temp_params)));
    dispatch(getRequests(user, filteredObject(temp_params)));
  };

  useEffect(() => {
    getTypes((data) =>
      setComplexTypes(
        data.complex.map((t) => ({
          name: t.type.ge,
          name_en: t.type.en,
          id: t.id,
        }))
      )
    );
    dispatch(getCategories(user));
  }, []);

  function handleEnter(event) {
    if (event.keyCode === 13) {
      onSearch();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleEnter);
    };
  }, [params]);

  return (
    <div className="filter-container">
      <div className="filter-row">
        <FilterDropdown
          title="სერვისის კატეგორია"
          selected={category}
          data={categories}
          setSelected={onCategoryChange}
        />
        <FilterDropdown
          title="სერვისის ქვეკატეგორია"
          selected={subcategory}
          data={subcategories}
          setSelected={setSubcategory}
        />
        <FilterDropdown
          title="კომპლექსის ტიპი"
          selected={complexType}
          data={complexTypes}
          setSelected={setComplexType}
        />
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            name="apartment_number"
            placeholder="ფართის ნომერი"
            value={params.apartment_number}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="filter-row">
        <div className="filter-input-container">
          <input
            type="number"
            className="filter-input"
            name="request_id"
            placeholder="მოთხოვნის ნომერი"
            value={params.request_id}
            onChange={onChange}
          />
        </div>
        <DateInput
          placeholder="მოთხოვნის თარიღიდან"
          name="from_requesting_datetime"
          onChange={onChange}
          value={params.from_requesting_datetime}
        />
        <DateInput
          placeholder="მოთხოვნის თარიღამდე"
          name="to_requesting_datetime"
          onChange={onChange}
          value={params.to_requesting_datetime}
        />
        {(isApproved || tab === "pending_approval") && (
          <DateInput
            placeholder="რეაგირების ვადიდან"
            name="from_approval_datetime"
            onChange={onChange}
            value={params.from_approval_deadline_datetime}
          />
        )}
        {isRejected && (
          <DateInput
            placeholder="უარყოფის თარიღიდან"
            name="from_rejection_datetime"
            onChange={onChange}
            value={params.from_rejection_datetime}
          />
        )}
        {tab === "active" && (
          <div
            className="filter-input-container input-placeholder"
            style={{ visibility: "hidden" }}
          >
            <input
              type="text"
              className="filter-input"
              placeholder="ფართის ნომერი"
            />
          </div>
        )}
        {(tab === "processing" || tab === "done,closed") && (
          <FilterDropdown
            title="პასუხისმგებელი პირი"
            data={executors}
            selected={executor}
            setSelected={setExecutor}
          />
        )}
      </div>
      <div className="filter-row">
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            name="user_name"
            placeholder="მოთხოვნის ავტორის სახელი"
            value={params.user_name}
            onChange={onChange}
          />
        </div>
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            name="user_surname"
            placeholder="მოთხოვნის ავტორის გვარი"
            value={params.user_surname}
            onChange={onChange}
          />
        </div>
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            name="user_pers_id"
            placeholder="მოთხოვნის ავტორის პირადი ნომერი"
            value={params.user_pers_id}
            onChange={onChange}
          />
        </div>
        {(tab === "active" || tab === "processing") && (
          <div
            className="filter-input-container"
            style={{ visibility: "hidden" }}
          >
            <input
              type="text"
              className="filter-input"
              placeholder="ფართის ნომერი"
            />
          </div>
        )}
        {(isApproved || tab === "pending_approval") && (
          <DateInput
            placeholder="რეაგირების ვადამდე"
            name="to_approval_datetime"
            onChange={onChange}
            value={params.to_approval_deadline_datetime}
          />
        )}
        {isRejected && (
          <DateInput
            placeholder="უარყოფის თარიღამდე"
            name="to_rejection_datetime"
            onChange={onChange}
            value={params.to_rejection_datetime}
          />
        )}
        {tab === "done,closed" && (
          <FilterDropdown
            title="შეფასების ვარსკვლავი"
            selected={selectedRate}
            setSelected={setSelectedRate}
            data={[
              { name: 0, id: 0 },
              { name: 1, id: 1 },
              { name: 2, id: 2 },
              { name: 3, id: 3 },
              { name: 4, id: 4 },
              { name: 5, id: 5 },
            ]}
          />
        )}
      </div>
      {isApproved && (
        <div className="filter-row">
          {(isApproved || tab === "pending_approval") && (
            <DateInput
              placeholder="დადასტურების თარიღიდან"
              name="from_approval_datetime"
              onChange={onChange}
              value={params.from_approval_datetime}
            />
          )}
          {(isApproved || tab === "pending_approval") && (
            <DateInput
              placeholder="დადასტურების თარიღამდე"
              name="to_approval_datetime"
              onChange={onChange}
              value={params.to_approval_datetime}
            />
          )}
          <DateInput
            placeholder="გადახდის ვადიდან"
            name="from_payment_deadline_datetime"
            onChange={onChange}
            value={params.from_payment_deadline_datetime}
          />
          <DateInput
            placeholder="გადახდის ვადამდე"
            name="to_payment_deadline_datetime"
            onChange={onChange}
            value={params.to_payment_deadline_datetime}
          />
        </div>
      )}
      {tab === "done,closed" && (
        <div className="filter-row">
          <DateInput
            placeholder="შესრულების თარიღიდან"
            name="from_execution_date"
            onChange={onChange}
            value={params.from_execution_date}
          />
          <DateInput
            placeholder="შესრულების თარიღამდე"
            name="to_execution_date"
            onChange={onChange}
            value={params.to_execution_date}
          />
          <div
            className="filter-input-container"
            style={{ visibility: "hidden" }}
          >
            <input
              type="text"
              className="filter-input"
              placeholder="ფართის ნომერი"
            />
          </div>
          <div
            className="filter-input-container"
            style={{ visibility: "hidden" }}
          >
            <input
              type="text"
              className="filter-input"
              placeholder="ფართის ნომერი"
            />
          </div>
        </div>
      )}
      <div className="filter-row">
        <div className="filter-buttons">
          <div className="filter-clear-button" onClick={resetFilter}>
            გასუფთავება
          </div>
          <div className="put-button" onClick={onSearch}>
            ძებნა
          </div>
        </div>
      </div>
    </div>
  );
}
