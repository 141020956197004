import "./ModuleCard.css";

const ModuleCard = ({
  moduleIcon,
  moduleNameEN,
  moduleNameGE,
  commentsCount,
}) => {
  return (
    <div className="module_card shadow">
      {commentsCount && commentsCount !== 0 && (
        <div className="notification-pointer">{commentsCount}</div>
      )}
      <div className="module_icon">
        <img src={moduleIcon} alt="icon" />
        <p className="module_nameEn">{moduleNameEN}</p>
      </div>
      <p className="module_name">{moduleNameGE}</p>
    </div>
  );
};

export default ModuleCard;
