import React from "react";
import Modal from "react-modal";
import close from "../../../src/assets/close.png";
import PropTypes from "prop-types";

function Confirmation({ onConfirm, toggleShowConfirm, showConfirm, content }) {
  return (
    <Modal
      isOpen={showConfirm}
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      className={"modal-content"}
      shouldCloseOnOverlayClick={true}
    >
      <div className="confirm-modal">
        <p>{content}</p>
        <div className="confirm-button" onClick={onConfirm}>
          დიახ
        </div>
        <img
          src={close}
          alt="close"
          className="close-icon"
          width={15}
          onClick={toggleShowConfirm}
        />
      </div>
    </Modal>
  );
}

Confirmation.propTypes = {
  onConfirm: PropTypes.func,
  toggleShowConfirm: PropTypes.func,
  showConfirm: PropTypes.bool,
  content: PropTypes.string,
};

export default Confirmation;
