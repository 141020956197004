import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterDropdown from "../services-tables/filters/FilterDropdown";
import { DateInput } from "../services-tables/filters/LeasesFilter";
import { getTypes } from "../../../libs/API";
import { getInvoices } from "../../../state/action-creators";
import { dateJSON } from "../../../libs/Functions";

function InvoiceFilter({ tab, attribute }) {
  const [params, setParams] = useState({
    from_date: "",
    to_date: "",
    user_name: "",
    apartment_num: "",
    user_surname: "",
    user_pers_id: "",
    invoice_id: "",
    transaction_id: "",
    from_payment_datetime: "",
    to_payment_datetime: "",
  });
  const [complexTypes, setComplexTypes] = useState([]);
  const [complexType, setComplexType] = useState(null);
  const [parkingTypes, setParkingTypes] = useState([]);
  const [parkingType, setParkingType] = useState(null);
  const globalParams = useSelector((state) => state.invoices.params);
  const dispatch = useDispatch();

  const onChange = (e) => {
    setParams((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    getTypes((data) =>
      setComplexTypes(
        data.complex.map((t) => ({
          name: t.type.ge,
          name_en: t.type.en,
          id: t.id,
        }))
      )
    );
    getTypes((data) =>
      setParkingTypes(
        data.parking.map((t) => ({
          name: t.type.ge,
          name_en: t.type.en,
          id: t.id,
        }))
      )
    );
  }, []);

  const resetFilter = () => {
    setParams({
      from_date: "",
      to_date: "",
      user_name: "",
      apartment_num: "",
      user_surname: "",
      user_pers_id: "",
      invoice_id: "",
      transaction_id: "",
      from_payment_datetime: "",
      to_payment_datetime: "",
    });
    setComplexType(null);
    setParkingType(null);
  };

  const onSearch = () => {
    const temp_params = {
      ...params,
      ...globalParams,
      from_date: dateJSON(params.from_date),
      to_date: dateJSON(params.to_date),
      from_payment_datetime: dateJSON(params.from_payment_datetime),
      to_payment_datetime: dateJSON(params.to_payment_datetime),
      complex_type: Number(complexType ? complexType.id : 0),
      parking_type: parkingType?.name_en,
      invoice_id:
        Number(params.invoice_id) === 0 ? null : Number(params.invoice_id),
      transaction_id:
        Number(params.transaction_id) === 0
          ? null
          : Number(params.transaction_id),
      user_name: params.user_name,
      user_surname: params.user_surname,
      user_pers_id: params.user_pers_id,
      apartment_num: params.apartment_num,
    };

    if (attribute === "additional") {
      delete temp_params["user_name"];
      delete temp_params["user_surname"];
      delete temp_params["user_pers_id"];
      temp_params.addressee_name = params.user_name;
      temp_params.addressee_surname = params.user_surname;
      temp_params.addressee_pers_id = params.user_pers_id;
    }

    dispatch(getInvoices(temp_params));
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      onSearch();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleEnter);
    };
  }, [params, parkingType]);

  return (
    <div className="filter-container">
      <div className="filter-row">
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            placeholder="ინვოისის ნომერი"
            value={params.invoice_id}
            onChange={onChange}
            name="invoice_id"
          />
        </div>
        <DateInput
          placeholder="ინვოისის თარიღიდან"
          name="from_date"
          value={params.from_date}
          onChange={onChange}
        />
        <DateInput
          placeholder="ინვოისის თარიღამდე"
          name="to_date"
          value={params.to_date}
          onChange={onChange}
        />
        {attribute !== "parking" && attribute !== "additional" && (
          <FilterDropdown
            title="კომპლექსის ტიპი"
            data={complexTypes}
            selected={complexType}
            setSelected={setComplexType}
          />
        )}
        {attribute !== "parking" && attribute === "additional" && (
          <div
            className="filter-input-container"
            style={{ visibility: "hidden" }}
          >
            <input
              type="text"
              className="filter-input"
              placeholder="გადამხდელის სახელი"
            />
          </div>
        )}

        {attribute === "parking" && (
          <FilterDropdown
            title="პარკინგის ტიპი"
            data={parkingTypes}
            selected={parkingType}
            setSelected={setParkingType}
          />
        )}
      </div>
      <div className="filter-row">
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            placeholder="გადამხდელის სახელი"
            value={params.user_name}
            onChange={onChange}
            name="user_name"
          />
        </div>
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            placeholder="გადამხდელის გვარი"
            value={params.user_surname}
            onChange={onChange}
            name="user_surname"
          />
        </div>
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            placeholder="გადამხდელის პირადი ნომერი"
            value={params.user_pers_id}
            onChange={onChange}
            name="user_pers_id"
          />
        </div>
        <div className="filter-input-container">
          <input
            type="text"
            className="filter-input"
            placeholder="ფართის ნომერი"
            name="apartment_num"
            value={params.apartment_num}
            onChange={onChange}
            style={attribute === "additional" ? { visibility: "hidden" } : {}}
          />
        </div>
      </div>
      {tab !== "paid" && (
        <div className="filter-buttons">
          <div className="filter-clear-button" onClick={resetFilter}>
            გასუფთავება
          </div>
          <div className="put-button" onClick={onSearch}>
            ძებნა
          </div>
        </div>
      )}

      {tab === "paid" && (
        <div className="filter-row">
          <div className="filter-input-container">
            <input
              type="text"
              className="filter-input"
              placeholder="ტრანზაქციის ნომერი"
              name="transaction_id"
              value={params.transaction_id}
              onChange={onChange}
            />
          </div>
          <DateInput
            placeholder="ტრანზაქციის თარიღიდან"
            name="from_payment_datetime"
            value={params.from_payment_datetime}
            onChange={onChange}
          />
          <DateInput
            placeholder="ტრანზაქციის თარიღამდე"
            name="to_payment_datetime"
            value={params.to_payment_datetime}
            onChange={onChange}
          />
          <div className="filter-buttons">
            <div className="filter-clear-button" onClick={resetFilter}>
              გასუფთავება
            </div>
            <div className="put-button" onClick={onSearch}>
              ძებნა
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InvoiceFilter;
