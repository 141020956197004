import React, { useEffect, useState } from "react";
import "./form.css";
import { countrys, callingCodes } from "../../../constants/index";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../state";
import CountryDropdown from "../../CountryDropdown/CountryDropdown";
import InputField from "../../InputField/InputField.js";
import API from "../../../libs/API.js";
import useFocusOut from "../../../hooks/useFocusOut.js";

export default function PersonForm({
  company,
  setSelected,
  editingRecord,
  setEditingRecord,
  isResponsible,
  setCompanyData,
  organizationCodeRef,
}) {
  const indexes = Object.keys(countrys).map((e) => ({
    name: countrys[e],
    id: e,
  }));
  const [selectedIndex, setSelectedIndex] = useState(
    editingRecord
      ? {
          name: countrys[editingRecord.country.code],
          id: editingRecord.country.code,
        }
      : ""
  );
  const [formData, setFormData] = useState(
    editingRecord
      ? {
          ...editingRecord,
          country_code: editingRecord.country.code,
          country_phone_prefix: editingRecord.country.phone_prefix,
        }
      : {
          name: "",
          surname: "",
          pers_id: "",
          phone: "",
          country_code: "",
          country_phone_prefix: "",
        }
  );
  const [error, setError] = useState(false);

  const property = useSelector((state) => state.currentProperty);
  const user = useSelector((state) => state.user.user);

  const onFocusOut = () => {
    const code = callingCodes[selectedIndex.id].substring(1);
    const phone_number = code + formData.phone;

    API.get(
      user,
      "/admin/user_organization/search",
      { phone_number },
      (res) => {
        setFormData({
          ...formData,
          ...res,
        });
      }
    );
  };

  const onOrganizationFocusOut = () => {
    API.get(
      user,
      "/admin/user_organization/search",
      { identity_number: company.code },
      (res) => {
        setSelectedIndex({
          name: countrys[res.representative.country.code],
          id: res.representative.country.code,
        });
        setCompanyData({
          ...company,
          name: res.name,
        });
        setFormData(res.representative);
      }
    );
  };
  useFocusOut(organizationCodeRef, onOrganizationFocusOut);

  useEffect(() => {
    if (editingRecord) {
      setFormData({
        ...editingRecord,
        country_code: editingRecord.country.code,
        id: editingRecord.id,
        country_phone_prefix: editingRecord.country.phone_prefix,
      });
      setSelectedIndex({
        name: countrys[editingRecord.country.code],
        id: editingRecord.country.code,
      });
    }
  }, [editingRecord]);

  const resetForm = () => {
    setSelected(null);
    setFormData({
      name: "",
      surname: "",
      phone: "",
      pers_id: "",
      country_code: "",
      country_phone_prefix: "",
    });
  };

  const dispatch = useDispatch();
  const { addOwner, updateOwner } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onPut = () => {
    const { name, surname, phone } = formData;
    if (!name || !surname || !phone || !selectedIndex) {
      setError(true);
      return;
    }
    !editingRecord
      ? addOwner(
          user,
          company
            ? {
                card_id: property.card_id ? property.card_id : null,
                user: {
                  ...formData,
                  country_code: selectedIndex.id,
                  country_phone_prefix: callingCodes[selectedIndex.id],
                  country_index: selectedIndex.name,
                },
                organization: {
                  identity_num: company.code,
                  name: company.name,
                },
                is_responsible: isResponsible,
              }
            : {
                card_id: property.card_id ? property.card_id : null,
                user: {
                  ...formData,
                  country_code: selectedIndex.id,
                  country_phone_prefix: callingCodes[selectedIndex.id],
                  country_index: selectedIndex.name,
                  is_responsible: isResponsible,
                },
                is_responsible: isResponsible,
              }
        )
      : updateOwner(
          user,
          company
            ? {
                card_id: property.card_id ? property.card_id : null,
                organization_id: company.organization_id,
                user: {
                  ...formData,
                  country_code: selectedIndex.id,
                  country_phone_prefix: callingCodes[selectedIndex.id],
                  country_index: selectedIndex.name,
                },
                organization: {
                  identity_num: company.code,
                  representative_id: editingRecord.id,
                  name: company.name,
                },
                is_responsible: isResponsible,
              }
            : {
                card_id: property.card_id ? property.card_id : null,
                user_id: editingRecord.id,
                user: {
                  ...formData,
                  country_code: selectedIndex.id,
                  country_phone_prefix: callingCodes[selectedIndex.id],
                  country_index: selectedIndex.name,
                  is_responsible: isResponsible,
                },
                is_responsible: isResponsible,
              }
        );
    setEditingRecord(null);
    setError(false);
    resetForm();
  };

  return (
    <>
      <div className="person-form">
        <div className="country-index">
          {/* <Dropdown 
                    isError={error}
                    title='ტელეფონის ქვეყნის ინდექსი'
                    data={indexes}
                    selected={selectedIndex}
                    setSelected={setSelectedIndex}
                /> */}
          <CountryDropdown
            placeholder="ტელეფონის ქვეყნის ინდექსი"
            data={indexes}
            selected={selectedIndex}
            setSelected={setSelectedIndex}
          />
        </div>
        <div className="input-outer tel">
          {formData.phone && (
            <div className="input-title">ტელეფონის ნომერი</div>
          )}
          <InputField
            type={"number"}
            value={formData.phone}
            name={"phone"}
            onChange={onChange}
            placeholder={"ტელეფონის ნომერი"}
            className={`input ${error && "input-error"}`}
            onFocusOut={onFocusOut}
          />
        </div>
        <div className="input-outer name">
          {formData.name && <div className="input-title">სახელი</div>}
          <InputField
            type="text"
            value={formData.name}
            name="name"
            className={`input ${error && "input-error"}`}
            onChange={onChange}
            placeholder="სახელი"
          />
        </div>
        <div className="input-outer lastname">
          {formData.surname && <div className="input-title">გვარი</div>}
          <InputField
            type="text"
            value={formData.surname}
            name="surname"
            className={`input ${error && "input-error"}`}
            onChange={onChange}
            placeholder="გვარი"
          />
        </div>
        <div className="input-outer id-number">
          {formData.pers_id && <div className="input-title">პირადი ნომერი</div>}
          <InputField
            value={formData.pers_id}
            type="text"
            name="pers_id"
            onChange={onChange}
            className={`input ${error && "input-error"}`}
            placeholder="პირადი ნომერი"
          />
        </div>
      </div>
      <div className="put-button" onClick={onPut}>
        ჩასმა
      </div>
    </>
  );
}
