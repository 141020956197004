import React from "react";
import send_icon from "../../../../assets/send_icon.png";

function CommentInput({
  onChange,
  value,
  placeholder,
  name,
  className,
  symbolsLimit,
  onSubmit,
}) {
  return (
    <form onSubmit={onSubmit} className={className}>
      <input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={"comment-input"}
        name={name}
      />
      <button type="submit">
        <img alt="send-icon" src={send_icon} className="send-icon" />
      </button>
      {symbolsLimit && (
        <div className="input-symbol-limit" style={{ right: 40, top: 11 }}>
          {value.length} / {symbolsLimit}
        </div>
      )}
    </form>
  );
}

export default CommentInput;
