import React from "react";
import PropTypes from "prop-types";
import "./TextArea.css";

function TextArea({
  value,
  onChange,
  placeholder,
  height,
  name,
  language,
  toggleLanguage,
  disabled,
  symbolsLimit,
  className,
}) {
  const myOnChange = (e) => {
    if (e.target.value.length > symbolsLimit) return;
    onChange(e);
  };

  return (
    <div className="text-area-container">
      <textarea
        className={`input textarea ${className ? className : ""}`}
        value={value}
        onChange={(e) => myOnChange(e)}
        placeholder={placeholder}
        style={{ height: height }}
        disabled={disabled}
        name={name}
      />
      {language && (
        <div className="language-switcher" onClick={toggleLanguage}>
          {language}
        </div>
      )}
      {symbolsLimit && (
        <div
          className={`symbols-limit ${
            value.length > symbolsLimit && "red-outline"
          }`}
        >
          {value.length}/{symbolsLimit}
        </div>
      )}
    </div>
  );
}

TextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextArea;
