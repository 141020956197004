import React from "react";
import bin from "../../../assets/bin.png";
import edit_icon from "../../../assets/edit_icon.png";
import edit_icon_filled from "../../../assets/filled_edit_icon.png";
import Checkbox from "../../Checkbox/Checkbox.js";
import { useDispatch, useSelector } from "react-redux";
import {
  resetProperty,
  toggleResponsiblePerson,
} from "../../../state/action-creators/propertyActions.js";
import LeaseIcon from "../../Icons/LeaseIcon.js";
import LeaseIconFilled from "../../Icons/LeaseIconFilled.js";
import { useNavigate } from "react-router-dom";

export default function ApartmentList({
  apartments,
  complexEditingRecord,
  setDeletingRecord,
  complexEdit,
  isLease,
  selectedResponsible,
  showLeaseForm,
  toggleShowLeaseForm,
  users,
  organizations,
  setApartmentForRental,
  setOwnersForRental,
  toggleShowForm,
  card_id,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const property = useSelector((state) => state.currentProperty);

  const isOrganization = selectedResponsible?.representative;

  let apartment_responsibilities;

  if (!isOrganization) {
    apartment_responsibilities = property.users.filter(
      (user) => user.id === selectedResponsible?.id
    )[0]?.apartment_responsibilities;
  } else {
    apartment_responsibilities = property.organizations.filter(
      (org) => org.id === selectedResponsible?.id
    )[0]?.apartment_responsibilities;
  }

  const onKeyClick = (apartment) => {
    navigate("/dashboard/services/users/lease");
    setApartmentForRental(apartment);
    setOwnersForRental({ users: users, organizations: organizations });
    dispatch(resetProperty());
    toggleShowLeaseForm(true);
    toggleShowForm(false);
  };

  const onCheck = (complex) => {
    if (selectedResponsible.representative) {
      dispatch(
        toggleResponsiblePerson({
          card_id: card_id,
          organization_id: selectedResponsible.id,
          apartment_id: complex.id,
        })
      );
      return;
    }
    dispatch(
      toggleResponsiblePerson({
        card_id: card_id,
        user_id: selectedResponsible.id,
        apartment_id: complex.id,
      })
    );
  };

  return (
    <>
      {apartments.map((c, i) => (
        <div key={i} className="card-item">
          <div className="d-flex-centered">
            {selectedResponsible && (
              <Checkbox
                label={""}
                onClick={() => onCheck(c)}
                isChecked={apartment_responsibilities?.includes(c.id)}
              />
            )}
            <p
              className={complexEditingRecord?.id === c.id ? "editing" : ""}
              style={{ marginTop: "10px" }}
            >
              {`${c.complex_type.ge} | სართ. ${c.floor} | № ${c.number}  | ${c.area}㎡`}
            </p>
          </div>

          <div className="edit-icons">
            {!isLease &&
              (c.is_rented ? (
                <LeaseIconFilled onClick={() => onKeyClick(c)} />
              ) : (
                <LeaseIcon onClick={() => onKeyClick(c)} />
              ))}
            {!isLease && (
              <img
                src={
                  complexEditingRecord?.id === c.id
                    ? edit_icon_filled
                    : edit_icon
                }
                alt="edit"
                className="edit-icon"
                onClick={() => complexEdit(c)}
              />
            )}
            {!isLease && (
              <img
                src={bin}
                alt="bin"
                className="bin"
                onClick={() => setDeletingRecord({ name: "complex", obj: c })}
              />
            )}
          </div>
        </div>
      ))}
    </>
  );
}
