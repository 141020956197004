import "./Modal.css"
import close from "../../assets/closeButton.svg"
import PropTypes from 'prop-types';

const Modal = ({message, agreement, setShowModal}) => {
    return ( 
        <div className="modal-container">
              <div className="dark-background" onClick={()=>{setShowModal(false)}}></div>
              <div className="modal-message-container">
                  <img src={close} onClick={()=>{setShowModal(false)}} className="closeButton" alt="close_icon"/>
                  <div className="modal-message">
                    {message}
                  </div>
                  <button className="agree-button" onClick={()=>{agreement()}}>დიახ</button>
              </div>
        </div>
     );
}

Modal.propTypes = {
  message: PropTypes.string,
  agreement: PropTypes.func,
  setShowModal: PropTypes.func,
}
 
export default Modal;