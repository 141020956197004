import React from "react";

export default function Apartment({ apartment }) {
  return (
    <div className="rent-apartment-container">
      <label>ფართის მონაცემი</label>
      <div className="rent-apartment">
        <p style={{ marginTop: "40px" }}>
          {`${apartment.complex_type.ge} | სართ. ${apartment.floor} | № ${apartment.number}  | ${apartment.area}㎡`}
        </p>
      </div>
    </div>
  );
}
