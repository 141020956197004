import { GET_RATINGS } from "../action-creators/types";

const initialState={
    ratings:[]
}
const reducer = (state=initialState, action) => {
    switch(action.type){
        case GET_RATINGS:
            return {
                ...state, 
                ratings:action.payload
            }
        default:
            return state;
    }
}
 
export default reducer;