import React from "react";
import { dateToString, phoneToString } from "../../../../libs/Functions";
import User from "./User";
import Checkbox from "../../../Checkbox/Checkbox";

export default function RentInfo({ card, editingRecord }) {
  return (
    <div className="rent-info-container">
      <label style={{ marginLeft: 45 }}>ფართის დამქირავებელი</label>
      <div style={{ marginLeft: 60, marginTop: 40, marginBottom: 40 }}>
        {card.users.length > 0 && <User self={card.users[0]} />}
        {card.organizations?.map((org, i) => (
          <span key={i} className="rent-user">{`${org.name} | ${
            org.identity_num
          } | ${org.representative.name} ${org.representative.surname} | ${
            org.representative.pers_id
          } | ${phoneToString(
            org.representative.country,
            org.representative.phone
          )}`}</span>
        ))}
      </div>

      {!editingRecord && (
        <label style={{ marginLeft: 45 }}>ქირავნობის პერიოდი</label>
      )}
      {!editingRecord && (
        <div
          className="rental-info-item"
          style={{ marginLeft: 40, marginTop: 40 }}
        >
          <p>{`${dateToString(card.from_date)} - ${dateToString(
            card.to_date
          )}`}</p>
          <Checkbox
            label="ფართის მომსახურების გადასახადი"
            isChecked={card.generate_apartment_fee_invoice}
          />
          <Checkbox
            label="კომუნალური მომსახურების გადასახადი"
            isChecked={card.generate_communal_fee_invoice}
          />
        </div>
      )}
    </div>
  );
}
