import { useEffect } from 'react';

export default function useFocusOut(ref, callback) {
  useEffect(() => {
    if (typeof (callback) !== "function") {
      return;
    }
    function handleFocusOut(event) {
      if (!ref.current.contains(event.relatedTarget)) {
        callback();
      }
    }

    document.addEventListener('focusout', handleFocusOut);

    return () => {
      document.removeEventListener('focusout', handleFocusOut);
    };
  }, [ref, callback]);
}
