import { useMutation } from "@tanstack/react-query";
import { store } from "../state/store";
import API from "../libs/API";
import { setMessage } from "../state/action-creators";

const user = store.getState().user.user;

export default function useCancelRentalMutation() {
  return useMutation({
    mutationFn: ({ apartmentId, callBack }) => {
      return API.delete(user, `/apartment/${apartmentId}/rental`).then(
        (res) => {
          callBack();
          return res;
        }
      );
    },
    onSuccess: (data) => {
      console.log(data);
      store.dispatch(setMessage("წარმატებით გაუქმდა"));
    },
  });
}
