import './TutorialsForm.css'
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addVideo } from '../../../state/action-creators/tutorialsActions';
const TutorialsForm = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const [titleError, setTitleError]=useState(false);
    const [urlError, setUrlError]=useState(false);
    const [tutorialsData, setTutorialsData]=useState({
        title:"",
        url:"",
    });
    
    
    const resetTutorialsForm=()=>{
        setTutorialsData({
            title:"",
            url:""
        })
    }
    const onChangeTutorial=(e)=>{
        setTutorialsData((prevState)=>({
             ...prevState,
             [e.target.name]:e.target.value
        }))
    }
    // const {addVideo}=bindActionCreators(actionCreators, dispatch);

    const onAddVideo=(e)=>{
        e.preventDefault()
        if(tutorialsData.title.length===0){
            setTitleError(true)
        }else{
            setTitleError(false)
        }
        if(tutorialsData.url.length===0){
            setUrlError(true)
        }else{
            setUrlError(false)
        }
        if(tutorialsData.url.length!==0 &&tutorialsData.title.length!==0){
            dispatch(
                addVideo(user, {
                    title: tutorialsData.title,
                    url: tutorialsData.url
                }))
                resetTutorialsForm()
        }
        
    }
    
    return ( 
        <form className="tutorials-form" onSubmit={onAddVideo}>
            <input type="text" name="title" value={tutorialsData.title}
            className={titleError? "tutorial-input tutorial-error": "tutorial-input"} 
            onChange={onChangeTutorial} placeholder='ვიდეო ინსტრუქციის სახელწოდება' 
            />
            <input type="text" name="url" value={tutorialsData.url}
            className={urlError? "tutorial-input  tutorial-error": "tutorial-input"}
            onChange={onChangeTutorial} placeholder='YouTube URL ლინკი' />
            <button className="save_button">შენახვა</button>
        </form>
     );
}
 
export default TutorialsForm;