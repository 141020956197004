import React from "react";

export default function NotificationsHeader({ tab }) {
  return (
    <div className="owners-table-header">
      <div
        className={`notifications-cards-header${
          tab === "received" ? "-recieved" : ""
        } owners-header`}
      >
        <div className="card-rows header-cell">გზავნილის მონაცემი</div>
        <div className="card-rows header-cell">
          {tab === "received" ? "გზავნილის ავტორი" : "გზავნილის ადრესატი"}
        </div>
        <div
          className={`header-cell ${tab !== "received" ? "card-rows" : ""} `}
        >
          გზავნილის ტექსტი
        </div>
        {tab !== "received" && <div className={`header-cell`}>დანართები</div>}
      </div>
    </div>
  );
}
