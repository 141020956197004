import React, { useRef, useState } from "react";
import InputField from "../../../InputField/InputField.js";
import TextArea from "../../../Textarea/TextArea.js";
import upload_icon from "../../../../assets/upload-icon.png";
import calendar_icon from "../../../../assets/calendar-icon.png";
import bin_icon from "../../../../assets/bin.png";
import DatetimeInputModal from "./DatetimeInputModal.js";
import useToggle from "../../../../hooks/useToggle.js";
import { dateToString, localDateString } from "../../../../libs/Functions.js";
import { useDispatch } from "react-redux";
import { deleteFile } from "../../../../state/action-creators/notificationActions.js";

function MessageForm({
  onChange,
  formData,
  messageDate,
  setMessageDate,
  messageTime,
  setMessageTime,
  handleUpload,
  files,
  setFiles,
  editingData,
}) {
  const [showCalendar, toggleShowCalendar] = useToggle(false);
  const { message_title, message_text } = formData;

  const [dateReady, toggleDateReady] = useToggle(
    messageDate !== "" ? true : false
  );

  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const onUploadClick = () => {
    inputRef.current.click();
  };

  const removeFile = (name, id) => {
    if (editingData) {
      dispatch(deleteFile(editingData.id, id));
    }
    setFiles(files.filter((file) => file.name !== name));
  };

  return (
    <div className="message-form">
      <DatetimeInputModal
        isOpen={showCalendar}
        toggleIsOpen={toggleShowCalendar}
        value={messageDate}
        onChange={setMessageDate}
        messageTime={messageTime}
        setMessageTime={setMessageTime}
        toggleDateReady={toggleDateReady}
      />
      <label>შეტყობინება</label>
      <div className="message-form-inputs">
        <InputField
          placeholder={"შეტყობინების სათაური"}
          name={"message_title"}
          onChange={onChange}
          value={message_title}
          symbolLimit={150}
        />
        <TextArea
          placeholder={"შეტყობინების ტექსტი"}
          height={300}
          name={"message_text"}
          value={message_text}
          onChange={onChange}
          symbolsLimit={3000}
        />
        <div className="message-inputs-row">
          <div
            className="input message-date-input"
            onClick={toggleShowCalendar}
          >
            <p>
              {dateReady && messageDate !== ""
                ? dateToString(messageDate) + " " + messageTime.name
                : "გაგზავნის თარიღი და დრო"}
            </p>
            <img src={calendar_icon} alt="upload" />
          </div>
          <div className="message-files">
            <div className="message-file-input">
              <div className="file-input-warnings">
                <p>ერთი ფაილის მოცულობა მაქსიმუმ 12მბ</p>
                <p>ფაილების ჯამური მოცულობა მაქსიმუმ 50მბ</p>
                <p>ფაილის ფორმატი PDF, JPG</p>
              </div>
              <img src={upload_icon} alt="upload" onClick={onUploadClick} />
            </div>
            <div className="message-form-files">
              {files.map((file, i) => (
                <div className="message-form-file" key={i}>
                  <p>{file?.name}</p>
                  <img
                    src={bin_icon}
                    alt="bin"
                    onClick={() => removeFile(file.name, file?.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <input
          type="file"
          style={{ visibility: "hidden" }}
          ref={inputRef}
          onChange={handleUpload}
        />
      </div>
    </div>
  );
}

export default MessageForm;
