import { useSelector } from "react-redux";
import { decode } from "js-base64";
import excel from "../../assets/excel.png";
import API from "../../libs/API";
import "./DownloadButton.css";
import { useState } from "react";
import PropTypes from "prop-types";

const DownloadButton = ({
  link,
  params,
  only_payments,
  width,
  icon,
  shouldDecode,
}) => {
  const user = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);

  const handleDownload = async (response) => {
    const blob = await response.blob();

    // filename comes as an encoded string in base64
    const encoded = response.headers
      .get("Content-Disposition")
      .split("filename=")[1];

    //then we can decode by following function
    const decoded = shouldDecode === undefined ? decode(encoded) : encoded;

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = decoded;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    setLoading(false);
  };

  const onClick = () => {
    setLoading(true);
    API.getBlob(
      user,
      link,
      params,
      (res) => handleDownload(res),
      (err) => console.log(err),
      only_payments
    );
  };

  return (
    <>
      {loading ? (
        <div className="loader-black download-button"></div>
      ) : (
        <img
          style={width ? { width: width } : {}}
          src={icon ? icon : excel}
          onClick={onClick}
          className="download-button"
          alt="excel-icon"
        />
      )}
    </>
  );
};

DownloadButton.propTypes = {
  link: PropTypes.string,
  params: PropTypes.any,
  only_payments: PropTypes.oneOf([true, false, undefined]),
};

export default DownloadButton;
