import API from "../../libs/API";
import { store } from "../../state/store.js";
import {
  APPROVE_INVOICES,
  CANCEL_INVOICE,
  CLEAR_INVOICES,
  CLOSE_INVOICES,
  GET_INVOICES,
  REMOVE_POPUP_MESSAGE,
  RESET_INVOICE_STATE,
  SAVE_INVOICE,
  SET_INVOICES_LOADING,
  SET_INVOICES_PARAMS,
  SET_INVOICES_UPDATING,
  SET_POPUP_MESSAGE,
  TOGGLE_CLOSING_CANDIDATE,
  TOGGLE_EVERY_CLOSING_CANDIDATE,
  UPDATE_INVOICES,
} from "./types";
const user = store.getState().user.user;

export const getInvoices = (params) => {
  return (dispatch) => {
    dispatch({ type: SET_INVOICES_LOADING, payload: true });
    dispatch({ type: SET_INVOICES_PARAMS, payload: params });
    API.get(user, "/admin/invoices/filter", params, (res) =>
      dispatch({ type: GET_INVOICES, payload: res })
    );
  };
};

export const toggleEveryClosingCandidate = (value) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_EVERY_CLOSING_CANDIDATE, payload: value });
  };
};

export const toggleClosingCandidate = (id) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_CLOSING_CANDIDATE, payload: id });
  };
};

export const updateInvoices = (params) => {
  return (dispatch) => {
    dispatch({ type: SET_INVOICES_UPDATING, payload: true });
    API.get(user, "/admin/invoices/filter", params, (res) =>
      dispatch({ type: UPDATE_INVOICES, payload: res })
    );
  };
};

export const clearInvoices = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_INVOICES });
  };
};

export const resetInvoiceState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_INVOICE_STATE });
  };
};

export const saveInvoice = (id, list) => {
  return (dispatch) => {
    API.put(
      user,
      `/admin/invoice/${id}`,
      { updated_communal_fees: list },
      (res) => dispatch({ type: SAVE_INVOICE, payload: res.invoice })
    );
  };
};

export const cancelInvoice = (id, cancellation_reason) => {
  return (dispatch) => {
    API.delete(
      user,
      `/admin/invoice/${id}`,
      { cancellation_reason },
      (res) => {
        dispatch({ type: CANCEL_INVOICE, payload: id });
        dispatch({ type: SET_POPUP_MESSAGE, payload: "ინვოისი გაუქმდა" });
        setTimeout(() => dispatch({ type: REMOVE_POPUP_MESSAGE }), 2000);
      },
      (err) => {
        console.error(err);
      }
    );
  };
};

export const approveInvoices = (list) => {
  return (dispatch) => {
    dispatch({ type: APPROVE_INVOICES, payload: list });
    API.post(user, "/admin/invoices/approve", { invoices_data: list });
  };
};

export const closeInvoice = (data) => {
  return (dispatch) => {
    API.post(
      user,
      "/admin/invoices/close",
      data,
      (res) => {
        dispatch({ type: CLOSE_INVOICES, payload: data });
        dispatch({
          type: SET_POPUP_MESSAGE,
          payload: res.message ? res.message.ge : "წარმატებით განხორციელდა",
        });
        setTimeout(() => dispatch({ type: REMOVE_POPUP_MESSAGE }), 2000);
      },
      (err) => {
        console.log(err);
      }
    );
  };
};
