import { REMOVE_POPUP_MESSAGE, SET_POPUP_MESSAGE } from "./types";

export const setMessage = (msg) => {
  return (dispatch) => {
    dispatch({
      type: SET_POPUP_MESSAGE,
      payload: msg,
    });
    setTimeout(() => dispatch({ type: REMOVE_POPUP_MESSAGE }), 2000);
  };
};

export const removeMessage = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_POPUP_MESSAGE,
    });
  };
};
