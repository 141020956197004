import React, { useEffect, useState } from 'react'
import './form.css';
import Dropdown from '../../Dropdown/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import InputField from '../../InputField/InputField';

export default function ApartmentForm(props) {
    const { selected, setSelected, types, editingRecord, setEditingRecord, toggleComplexAdding } = props;
    const [formData, setFormData] = useState({
        floor: '',
        number: '',
        area: '',
    });
    const [error, setError] = useState(false);

    const dispatch = useDispatch()
    const { addComplex, updateComplex } = bindActionCreators(actionCreators, dispatch)

    const user = useSelector(state => state.user.user)
    const property = useSelector(state => state.currentProperty)

    useEffect(() => {
        if(editingRecord) {
            setFormData({
                floor: editingRecord.floor,
                number: editingRecord.number,
                area: editingRecord.area,
            });
            const type = editingRecord.complex_type.ge
            setSelected({name: type, id:type==="ბიზნეს ბლოკი"?2:1});
        }
    }, [editingRecord])

    const resetForm = () => {
        setSelected(null);
        setFormData({
            floor: '',
            number: '',
            area: '',
        })
    }

    const onChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }))
    }

    const onPut = () => {
        const { floor, number, area } = formData;
        const type = selected;

        if (editingRecord) {

            updateComplex(user, 
            {
                id: editingRecord.id,
                floor, number, area,
                complex_type: type.id,
            });
            setEditingRecord(null);
            toggleComplexAdding();
            return;
        }

        if(!type || floor==='' || area==='') {
            setError(true);
            return
        }
        setError(false);
        addComplex(user, {
            card_id: property.card_id ? property.card_id:null,
            apartment: {...formData, complex_type: type}
        });
        resetForm();
    }

    useEffect(() => {
        if (!editingRecord) {
            resetForm();
        }
    }, [editingRecord])

    return (
        <>
        <div className='area-form'>
            <div className='apartment-type'>
                <Dropdown
                    isError={error}
                    title="კომპლექსის ტიპი"
                    data={types}
                    selected={selected}
                    setSelected={setSelected}
                />
                <div className='apartment-data-form'>
                    <div className='input-outer'>
                        {formData.floor && <div className='input-title'>სართული</div>}
                        <InputField 
                        type="text" name='floor' onChange={onChange}
                        className={`${error && 'input-error'} input`} value={formData.floor}
                        placeholder='სართული'/>
                    </div>
                    <div className='input-outer'>
                        {formData.number && <div className='input-title'>ნომერი</div>}
                        <InputField 
                        type="text" name='number' onChange={onChange}
                        className={`${error && 'input-error'} input`} value={formData.number}
                        placeholder='ნომერი'/>
                    </div>
                    <div className='input-outer'>
                        <InputField type="number" name='area' onChange={onChange}
                        className={`${error && 'input-error'} input`} value={formData.area}
                        placeholder='ფართობი კვ.მ.'/>
                    </div>
                </div>
            </div>
        </div>
        <div className='put-button' onClick={onPut}>ჩასმა</div>
        </>
    )    
}