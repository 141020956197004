import React from 'react';
import { dateToString } from '../../../libs/Functions';
import phoneIcon from '../../../assets/phoneIcon.png';
import edit_icon from '../../../assets/edit_icon.png';
import filled_edit_icon from '../../../assets/filled_edit_icon.png';
import bin from '../../../assets/bin.png';

export default function PropertyUsersList({ 
    users, 
    isLease, 
    userEditingRecord, 
    setDeletingRecord,
    userEdit,
    selectedResponsible,
    setSelectedResponsible,
}) {

  const onSelect = (user) => {
    if (!user.is_responsible) {
        return;
    }
    if (selectedResponsible !== null) {
        setSelectedResponsible(null);
        return;
    }
    setSelectedResponsible(user);
  }

  const myUserEdit = (e, u) => {
    e.stopPropagation();
    userEdit(u);
  }

  const onDeleteClick = (e, u) => {
    e.stopPropagation();
    setDeletingRecord({name: "owner", obj: u});
  }
  
  return (
    <>
    {  users.map((u, i) => 
        <div key={i} className='card-item owner-card-item'
            style={{backgroundColor: u.id === selectedResponsible?.id ? '#F0F0F7' : '', cursor: 'pointer'}} 
            onClick={() => onSelect(u)}
        >

            <div className='d-flex-centered' >

                {u.is_verified && 
                <img 
                    src={phoneIcon} 
                    onClick={(event)=>{event.stopPropagation(); dateToString(u.verification_date)}}  
                    className="form-phone-icon" 
                    alt="phone icon" 
                    style={{left: '0', top: '5px'}}
                />}
                <p className={userEditingRecord?.id===u.id ? 'editing':''}>
                    {`${u.name} ${u.surname} | ${u.pers_id} | ${u.country.code} (${u.country.phone_prefix}) ${u.phone}`}
                </p>
            </div>
            <div className='edit-icons'>
                {!isLease && <img src={userEditingRecord?.id===u.id ? filled_edit_icon:edit_icon} alt='edit' className='edit-icon' onClick={(e) => myUserEdit(e, u)}/>}
                {!isLease && <img src={bin} alt='bin' className='bin' onClick={(e) => onDeleteClick(e, u)}/>}
            </div>    
        </div>
    )}
    </>
  )
}
