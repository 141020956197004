import React from "react";
import Subcategory from "./Subcategory";
import useSubcategories from "../../../../../hooks/useSubcategories";
import useWaivedFees from "../../../../../hooks/useWaivedFees";

export default function SubcategoryList({
  parentId,
  selectedSubcategory,
  setSelectedSubcategory,
  selectedApartment,
  onClick,
}) {
  const { data, isFetching } = useSubcategories(parentId);

  // waived fees
  const waivedFeesQuery = useWaivedFees(selectedApartment);

  if (isFetching) {
    return "Loading...";
  }

  return (
    <div className="subcategory-list">
      {data?.categories.map((category, i) => (
        <Subcategory
          self={category}
          key={i}
          selectedSubcategory={selectedSubcategory}
          setSelectedSubcategory={setSelectedSubcategory}
          onClick={onClick}
          waivedFeesQuery={waivedFeesQuery}
        />
      ))}
    </div>
  );
}
