import { ADD_VIDEO, DELETE_VIDEO, GET_VIDEOS, TUTORIAL_ERROR, UPDATE_VIDEO } from "./types";
import API from '../../libs/API.js';

export const getTutorials = (user) => {
    return (dispatch) => {
        API.get(user, `/video_instructions`, null, 
        (res) => dispatch({type: GET_VIDEOS, payload: res.video_instructions}),
        (err) => console.log(err),
        )
    }
}
export const addVideo = (user, video)=>{
    return (dispatch) =>{
        const data={
            title:video.title,
            url:video.url,
        }
        API.post(user,'/video_instructions', data,
        (res)=>dispatch({type: ADD_VIDEO, payload: res.video_instruction}),
        (err)=>VideoError(dispatch, err)
        )
    }
}

export const deleteVideo =(user, tutorial_id)=>{
    const data={
        "video_instruction_id": tutorial_id
      }
    return (dispatch)=>{
        API.delete(user, '/video_instructions', data,
        ()=>dispatch({type: DELETE_VIDEO, payload: tutorial_id}),
        (err)=>VideoError(dispatch,err))
    }
}

export const updateTutorial =(user, tutorial_id)=>{
    const data={
        "video_instruction_id": tutorial_id
      }
    return (dispatch)=>{
        API.put(user, '/video_instructions', data,
        ()=>dispatch({type:UPDATE_VIDEO, payload: tutorial_id}),
        (err)=>VideoError(dispatch, err))
    }
}
// ნატო აქ წავშალე addVideoFinish არ გჭირდებოდა ქვემოთ addVideo-ში გავაკეთე აქ რისი 
// გაკეთებაც გინდოდა

const VideoError = (dispatch, error)=>{
    dispatch ({
        type:TUTORIAL_ERROR,
        payload:error,
    })
}

