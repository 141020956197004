import API from "../../libs/API"
import { store } from '../../state/store.js';
import { APPROVE_REQUEST, ASSIGN_EXECUTOR, CLEAR_PROPERTIES, CLEAR_REQUESTS, COMPLETE_REQUEST, GET_REQUESTS, REJECT_REQUEST, RESET_REQUEST_STATE, SET_INVOICE_REQUESTS_UPDATING, SET_REQUESTS_LOADING, SET_REQUEST_PARAMS, UPDATE_REQUESTS } from "./types"
const user = store.getState().user.user;

export const getRequests = (user, params) => {
    return (dispatch) => {
        dispatch({type: SET_REQUESTS_LOADING, payload: true});
        API.get(user, `/service/requests/filter`, params, 
        (res) => dispatch({type: GET_REQUESTS, payload: res}));
    }
}

export const updateRequests = (user, params) => {
    return (dispatch) => {
        dispatch({type: SET_INVOICE_REQUESTS_UPDATING});
        API.get(user, `/service/requests/filter`, params, 
        (res) => dispatch({type: UPDATE_REQUESTS, payload: res.requests}));
    }
}

export const assignExecutor = (request_id, executor_id) => {
    return (dispatch) => {
        API.post(user, `/admin/request/${request_id}/executor/${executor_id}`, null,
        () => dispatch({type: ASSIGN_EXECUTOR, payload: request_id}));
    }
}

export const setRequestsParams = (params) => {
    return (dispatch) => {
        dispatch({type: SET_REQUEST_PARAMS, payload: params});
    }
}

export const completeRequest = (id) => {
    return (dispatch) => {
        dispatch({type: COMPLETE_REQUEST, payload: id});
        API.post(user, `/admin/request/${id}/complete`, null);
    }
}

export const approveRequest = (id) => {
    return (dispatch) => {
        API.post(user, `/admin/request/${id}/approve`, null, () => dispatch({type: APPROVE_REQUEST, payload: id}));
    }
}

export const rejectRequest = (id, reason) => {
    console.log(reason);
    return (dispatch) => {
        API.post(user, `/admin/request/${id}/reject`, {rejection_reason: reason}, () => dispatch({type: REJECT_REQUEST, payload: id}), null);
    }
}

export const clearRequests = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_REQUESTS});
    }
}

export const resetRequestState = () => {
    return (dispatch) => {
        dispatch({type: RESET_REQUEST_STATE});
    }
}

export const clearProperties = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_PROPERTIES});
    }
}