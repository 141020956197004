import React, { useEffect, useRef, useState } from "react";
import CommentInput from "./CommentInput";
import API from "../../../../libs/API";
import { useDispatch, useSelector } from "react-redux";
import Comment from "./Comment";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { resetSeens } from "../../../../state/action-creators/notificationActions";

function CommentSection({ self }) {
  const [comment, setComment] = useState("");
  const sectionRef = useRef(null);
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const queryClient = useQueryClient();

  const commentsQuery = useQuery({
    queryKey: ["comments", self.room_id],
    queryFn: () =>
      API.get(user, `/room/${self.room_id}`).then((res) => res.comments),
  });

  const newCommentMutation = useMutation({
    mutationFn: (comment) =>
      API.post(user, `/room/${self.room_id}/comment`, { text: comment }),
    onSuccess: () => {
      queryClient.invalidateQueries(["comments", self.room_id]);
    },
  });

  const comments = commentsQuery.data;

  const writeComment = () => {
    if (comment === "") {
      return;
    }
    newCommentMutation.mutate(comment);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    writeComment();
    setComment("");
  };

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (!self.room_id) {
      return;
    }
    return () => {
      setComment("");
    };
  }, []);

  useEffect(() => {
    sectionRef.current?.scrollTo(0, sectionRef.current.scrollHeight);
    queryClient.invalidateQueries(["unseen_comments_count"]);
    dispatch(resetSeens(self.id));
  }, [comments]);

  // if loading
  if (commentsQuery.isPending) {
    return <div></div>;
  }

  return (
    <div className="comment-section">
      <div className="comments-container" ref={sectionRef}>
        {comments.map((com, i) => (
          <Comment comment={com} isMine={com.author_id === user.id} key={i} />
        ))}
      </div>
      <CommentInput
        placeholder={"კომენტარი"}
        className={"comment-input-container"}
        onSubmit={onSubmit}
        value={comment}
        name={"comment"}
        onChange={handleChange}
        symbolsLimit={250}
      />
    </div>
  );
}

export default CommentSection;
